<div class="g-hero">
    <div class="g-hero-text">
        <h1>Geislinger Analytics Platform</h1>
    </div>
    <div class="g-hero-img"></div>
</div>
<div class="g-head">
    <form [formGroup]="passwordResetForm" *ngIf="!success && token">
        <h2>Set a new password</h2>
        <div>
            <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput
                        type="password"
                        autocomplete="new-password"
                        formControlName="password"
                        required>
                <mat-error *ngIf="passwordResetForm.get('newPassword')?.hasError('requirementsNotFulfilled') && !passwordResetForm.get('newPassword')?.hasError('required')">
                    <span title="
- at least 8 characters long,
- contains at least 1 number,
- contains at least 1 upper-case character,
- contains at least 1 lower-case character,
- contains at least 1 special character,
- contains no whitespaces"
                    >
                        The password does not meet the <b>requirements<sup>*</sup></b>
                    </span>
                </mat-error>
            </mat-form-field>
            Requirements: At least 8 characters
        </div>

        <div>
            <mat-form-field>
                <mat-label>Confirm password</mat-label>
                <input matInput
                        type="password"
                        autocomplete="new-password"
                        formControlName="confirmPassword"
                        required>
                <mat-error *ngIf="passwordResetForm.get('confirmPassword')?.hasError('required')">
                    Please confirm password
                </mat-error>
                <mat-error *ngIf="passwordResetForm.get('confirmPassword')?.hasError('mustMatch') && !passwordResetForm.get('confirmPassword')?.hasError('required')">
                    Passwords do not match
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <button class="g-button g-form-main-button" [ngClass]="!passwordResetForm.valid ? 'g-disabled':''" [disabled]="!passwordResetForm.valid" (click)="sendPasswordReset()">
                Submit
            </button>
        </div>
    </form>

    <mat-toolbar *ngIf="success">
        <h2 routerLink="/login">Your have successfully set a new password. You are now getting redirected to the login page.</h2>
    </mat-toolbar>

    <div *ngIf="error">
        <mat-toolbar color="warn">
            <h2>ERROR: {{error}}</h2>
        </mat-toolbar>
    </div>
</div>
