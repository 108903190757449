<div class="g-hero">
    <div class="g-hero-text">
        <h1>Geislinger Analytics Platform</h1>
    </div>
    <div class="g-hero-img sujet"></div>
</div>
<div class="g-head g-form">
    <h3 class="g-headline">Login</h3>
    <form [formGroup]="form">
        <div>
            <mat-icon>person</mat-icon>
            <mat-form-field class="full-width-input">
                <mat-label>Username</mat-label>
                <input autocomplete="username"
                        formControlName="username"
                        matInput
                        required type="text">
                <mat-error>
                    Please provide a valid username
                </mat-error>
            </mat-form-field>
        </div>
        <div>
            <mat-icon>password</mat-icon>
            <mat-form-field class="full-width-input">
                <mat-label>Password</mat-label>
                <input autocomplete="current-password"
                        formControlName="password"
                        matInput
                        required type="password">
                <mat-error>
                    Please provide a valid password
                </mat-error>
            </mat-form-field>
        </div>
        <button (click)="login()" [disabled]="!form.valid" [ngClass]="!form.valid ? 'g-disabled':''" class="g-button g-form-main-button">
            Login
        </button>
        <div class="g-form-login-help">
            <div class="g-form-registration-link"><a routerLink="/registration">Register</a></div>
            <div class="g-form-pw-forgotten" routerLink="/forgot-password">Forgot password?</div>
        </div>
        <div *ngIf="error" class="g-status warn"><b>Login failed: {{error}}</b></div>
        <div *ngIf="logOutMessage" class="g-status warn">Logged out because: {{logOutMessage}}</div>
    </form>
</div>
