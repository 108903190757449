import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {FileMeta} from '../models/file-meta';

@Injectable({
  providedIn: 'root'
})
export class FileStatusService {

  private host = environment.apiUrl + '/v1/status/';

  constructor(private http: HttpClient) {
  }

  getFileStatus(gmsId: string, filenames: string[]): Observable<FileMeta[]> {
    const endpoint = this.host + gmsId;
    return this.http.post<FileMeta[]>(endpoint, filenames);
  }
}
