<mat-spinner *ngIf="!main.activeItem"></mat-spinner>
<div *ngIf="main.activeItem">
    <div *ngIf="main.activeItem" [ngClass]="main.activeItem.status" class="g-head g-hero">
        <div class="g-hero-text">
            <div class="text">
                <div class="logo"><img alt="Geislinger Logo" src="assets/geislinger-logo.svg"></div>
                <h3>{{main.activeItem.designation}}</h3>
                <h5 class="damper-id"><b>{{main.activeItem.projectInfo}}</b></h5>
            </div>
        </div>
        <div class="g-hero-headline"><h1>Reports</h1></div>
    </div>

    <!--TABLE-->
    <table *ngIf="reports"
           [dataSource]="dataSource"
           class="mat-elevation-z8" mat-table>

        <!-- Date column -->
        <ng-container matColumnDef="period">
            <th *matHeaderCellDef mat-header-cell>Period</th>
            <td *matCellDef="let report" mat-cell>{{ dateUtils.formatAsDateOnly(report.fromDate, ".") }} - {{ dateUtils.formatAsDateOnly(report.toDate, ".") }}</td>
        </ng-container>

        <!-- ENGINE Column (1) -->
        <ng-container matColumnDef="engine1" *ngIf="containsReportWithEngine(1)">
            <th *matHeaderCellDef mat-header-cell>{{getDesignationColumnTitleForEngine(1)}}</th>
            <td *matCellDef="let report" mat-cell>{{ numberUtils.formatAsPercentage(report.engine1?.value, 1) }}</td>
        </ng-container>

        <!-- ENGINE Column (2) -->
        <ng-container matColumnDef="engine2" *ngIf="containsReportWithEngine(2)">
            <th *matHeaderCellDef mat-header-cell>{{getDesignationColumnTitleForEngine(2)}}</th>
            <td *matCellDef="let report" mat-cell>{{ numberUtils.formatAsPercentage(report.engine2?.value, 1) }}</td>
        </ng-container>

        <!-- ENGINE Column (3) -->
        <ng-container matColumnDef="engine3" *ngIf="containsReportWithEngine(3)">
            <th *matHeaderCellDef mat-header-cell>{{getDesignationColumnTitleForEngine(3)}}</th>
            <td *matCellDef="let report" mat-cell>{{ numberUtils.formatAsPercentage(report.engine3?.value, 1) }}</td>
        </ng-container>

        <!-- ENGINE Column (4) -->
        <ng-container matColumnDef="engine4" *ngIf="containsReportWithEngine(4)">
            <th *matHeaderCellDef mat-header-cell>{{getDesignationColumnTitleForEngine(4)}}</th>
            <td *matCellDef="let report" mat-cell>{{ numberUtils.formatAsPercentage(report.engine4?.value, 1) }}</td>
        </ng-container>

        <!-- report Url Column -->
        <!-- Download Column -->
        <ng-container matColumnDef="download">
            <th *matHeaderCellDef mat-header-cell>Download Report</th>
            <td *matCellDef="let report"
                mat-cell>
                <button mat-icon-button (click)="downloadReport(report)">
                    <mat-icon>download</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- report Size Column -->
        <ng-container matColumnDef="details" *ngIf="showDetails">
            <th *matHeaderCellDef mat-header-cell>Detail View</th>
            <td *matCellDef="let report"
                mat-cell>
                <button mat-icon-button (click)="goToDetails(report)">
                    <mat-icon>open_in_new</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columnsToDisplay;" mat-row>
        </tr>

        <ng-container>
            <tr *matNoDataRow class="mat-row">
                <td class="mat-cell" colspan="6">None</td>
            </tr>
        </ng-container>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" aria-label="Select page of reports" showFirstLastButtons>
    </mat-paginator>
</div>
