<div class="g-hero">
    <div class="g-hero-text">
        <h1>Geislinger Analytics Platform</h1>
    </div>
    <div class="g-hero-img sujet"></div>
</div>
<div class="g-head g-form">
    <div>
        <h3 class="g-headline">Request access to GMS</h3>
    </div>
    <mat-spinner *ngIf="requestLoading"></mat-spinner>
    <div *ngIf="!showRequestAccessForm && !requestLoading">
        <button type="button" class="g-button" (click)="setShowRequestAccessFormToTrue()">Send another access-request
        </button>
    </div>
    <div class="application-form" *ngIf="showRequestAccessForm && !requestLoading">
        <a class="manual-link"
           (click)="window.open('../../../assets/pdf/' + gmsRequestManual)"
           [href]="'../../../assets/pdf/' + gmsRequestManual" target="_blank">
            GMS Request Manual
        </a>
        <form [formGroup]="requestAccessForm">
            <div>
                <mat-form-field class="full-width-input">
                    <mat-label>GMS</mat-label>
                    <input formControlName="gmsId"
                            matInput
                            type="text"
                            required>
                </mat-form-field>
                <mat-icon class="tooltip-icon" [matTooltip]="tooltips['gmsId']" matTooltipPosition="above">
                    info
                </mat-icon>
            </div>
            <div class="application-form-field" (click)="toggleApplicationType()">
                <mat-label class="outside-label">Application</mat-label>
                <mat-radio-group formControlName="isVesselOrMarineApplication">
                    <mat-radio-button [value]="true" checked>
                        Vessel / Marine
                    </mat-radio-button>
                    <mat-radio-button [value]="false">
                        Other
                    </mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="isVesselOrMarineApplication" class="request-inner-form">
                <div>
                    <mat-form-field class="full-width-input">
                        <mat-label>Hull No.</mat-label>
                        <input formControlName="hullNumber"
                                matInput
                                type="text">
                    </mat-form-field>
                    <mat-icon class="tooltip-icon"
                              [matTooltip]="tooltips['hullNumber']" matTooltipPosition="above">
                        info
                    </mat-icon>
                </div>
                <div>
                    <mat-form-field class="full-width-input">
                        <mat-label>IMO No.</mat-label>
                        <input formControlName="imoNumber"
                                matInput
                                type="text">
                    </mat-form-field>
                    <mat-icon class="tooltip-icon" [matTooltip]="tooltips['imoNumber']" matTooltipPosition="above">
                        info
                    </mat-icon>
                </div>
                <div>
                    <mat-form-field class="full-width-input">
                        <mat-label>Vessel Name</mat-label>
                        <input formControlName="shipName"
                                matInput
                                type="text">
                    </mat-form-field>
                    <mat-icon class="tooltip-icon"
                              [matTooltip]="tooltips['vesselName']" matTooltipPosition="above">
                        info
                    </mat-icon>
                </div>
                <div>
                    <mat-form-field class="full-width-input">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="type" [panelWidth]="null">
                            <mat-option *ngFor="let type of vesselOrMarineApplicationTypeOptions"
                                        [value]="type">{{type}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="!isVesselOrMarineApplication" class="request-inner-form">
                <div>
                    <mat-form-field class="full-width-input">
                        <mat-label>Type</mat-label>
                        <mat-select formControlName="type" [panelWidth]="null">
                            <mat-option *ngFor="let type of otherApplicationTypeOptions"
                                        [value]="type">{{type}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="full-width-input">
                        <mat-label class="outside-label">Application Description</mat-label>
                        <textarea
                                class="application-description"
                                formControlName="applicationDescription"
                                matInput
                                type="text"
                                rows="5">
                    </textarea>
                    </mat-form-field>
                    <mat-icon class="tooltip-icon" [matTooltip]="tooltips['applicationDescription']" matTooltipPosition="above">
                        info
                    </mat-icon>
                </div>
            </div>
            <div>
                <mat-form-field class="full-width-input">
                    <mat-label>Reference</mat-label>
                    <input formControlName="geislingerReference"
                            matInput
                            type="text">
                </mat-form-field>
                <mat-icon class="tooltip-icon" [matTooltip]="tooltips['geislingerReference']" matTooltipPosition="above">
                    info
                </mat-icon>
            </div>
            <div>
                <mat-form-field class="full-width-input">
                    <mat-label>Product</mat-label>
                    <input formControlName="geislingerProduct"
                            matInput
                            type="text">
                </mat-form-field>
                <mat-icon class="tooltip-icon" [matTooltip]="tooltips['geislingerProduct']" matTooltipPosition="above">
                    info
                </mat-icon>
            </div>
            <button (click)="requestAccess()" [disabled]="!requestAccessForm.valid"
                    [ngClass]="!requestAccessForm.valid ? 'g-disabled' : ''"
                    class="g-button g-form-main-button submit-button"
                    type="submit">
                Submit
            </button>
        </form>
    </div>
</div>

<div class="g-head" *ngIf="requestSent">
    <div *ngIf="requestError === null">
        <h3>Access requested successfully</h3>
        <p>
            Thank you for your request. Our team has been informed and will promptly handle it.
        </p>
    </div>
    <div *ngIf="requestError !== null">
        <h3>An error has occurred:</h3>
        <p>{{requestError}}</p>
    </div>
</div>
