<div class="g-head g-hero">
    <div class="g-hero-text">
        <div class="text">
            <div class="logo"><img src="assets/geislinger-logo.svg"></div>
        </div>
    </div>
    <div class="g-hero-headline"><h1>All Modules</h1></div>
</div>
<div class="main-container">
    <mat-spinner *ngIf="isLoadingDevices"></mat-spinner>
    <div [hidden]="isLoadingDevices">
        <div class="g-all-devices-search">
            <mat-form-field>
                <mat-label>{{idOrNameSearchLabel}}</mat-label>
                <input matInput (keyup)="applySearchFilter($event)">
            </mat-form-field>
            <mat-form-field *ngIf="isAdmin">
                <mat-label>{{accountManagerSearchLabel}}</mat-label>
                <input matInput (keyup)="applySearchFilter($event)">
            </mat-form-field>
            <mat-form-field *ngIf="isAdmin || isGDSPartner">
                <mat-label>{{userSearchLabel}}</mat-label>
                <input matInput (keyup)="applySearchFilter($event)">
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{statusSelectLabel}}</mat-label>
                <mat-select (selectionChange)="applyStatusFilter($event)">
                    <mat-option [value]="">
                        --
                    </mat-option>
                    <mat-option *ngFor="let status of possibleStatuses" [value]="status">
                        {{status}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{connectionSelectLabel}}</mat-label>
                <mat-select (selectionChange)="applyConnectionFilter($event)">
                    <mat-option [value]="">
                        --
                    </mat-option>
                    <mat-option *ngFor="let connection of possibleConnections" [value]="connection">
                        {{connection.toUpperCase()}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <mat-table [dataSource]="devicesDataSource" matSort #devicesTbSort="matSort">

            <ng-container matColumnDef="shipIcon">
                <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
                <mat-cell *matCellDef="let item">
                        <ng-container *ngIf="item.status!==GmsStatus.ALARM; else alarm">
                            <app-icon class="symbol" [name]="item.shipIcon" (click)="navigate(item.gmsId, 'detail')"></app-icon>
                        </ng-container>
                        <ng-template #alarm>
                            <app-icon [name]="'ALARM'" (click)="navigate(item.gmsId, 'detail')"></app-icon>
                        </ng-template>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="id">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Id</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <button class="gms-id-button" mat-button (click)="navigate(item.gmsId, 'detail')">
                        {{item.gmsId}}
                    </button>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="name">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Name</mat-header-cell>
                <mat-cell *matCellDef="let item">{{item.gmsName}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="accountManager">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Account Manager</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    {{getListOfStringsAsString(item.accountManagerUsernames)}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="user">
                <mat-header-cell *matHeaderCellDef mat-sort-header>User <mat-icon class="g-sub-user-tooltip" matTooltip="Sub-Users">info</mat-icon></mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <span>
                        {{getListOfStringsAsString(item.customerManagerUsernames)}}
                        <mat-icon *ngIf="item.subUserUsernames?.length > 0"
                                  class="g-sub-user-tooltip"
                                  [matTooltip]="getListOfStringsAsString(item.subUserUsernames)">info</mat-icon>
                    </span>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="lastUpdate">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Last Update</mat-header-cell>
                <mat-cell *matCellDef="let item">{{getDaysSinceLastUpdate(item.lastUpdate)}}
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="maintenance">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Maintenance</mat-header-cell>
                <mat-cell *matCellDef="let item">{{(item.maintenance * 100).toFixed(0)}} %
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                <mat-cell class="status" [ngClass]="item.status" *matCellDef="let item">
                    <div matTooltip="Status: {{item.status}}"
                         matTooltipPosition="above">
                        <button mat-icon-button (click)="navigate(item.gmsId, 'status')">
                            <app-status-icon class="symbol" [input]="item.status"></app-status-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="damperHealth">
                <mat-header-cell *matHeaderCellDef>Damper Health</mat-header-cell>
                <mat-cell class="damper-health" [ngClass]="mapDamperHealthToStatus(item.damperHealth)"
                          *matCellDef="let item">
                    <div matTooltip="{{item.damperHealth <= 100 && item.damperHealth >= 0 ? 'Damper Health: ' + item.damperHealth : 'No reports for this device'}}"
                         matTooltipPosition="above">
                        <button mat-icon-button (click)="navigate(item.gmsId, 'reports')">
                            <app-status-icon *ngIf="item.damperHealth <= 100 && item.damperHealth >= 0"
                                                    class="symbol"
                                                    [input]="mapDamperHealthToStatus(item.damperHealth)"></app-status-icon>
                            <mat-icon *ngIf="item.damperHealth > 100 || item.damperHealth < 0">not_interested</mat-icon>
                        </button>
                    </div>

                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="connection">
                <mat-header-cell *matHeaderCellDef>Connection</mat-header-cell>
                <mat-cell class="connection"
                          [ngClass]="mainService.getConnectionDescription(item.connectionQuality, item.pushType)"
                          *matCellDef="let item">
                    <div matTooltip="Connection: {{mainService.getConnectionDescription(item.connectionQuality, item.pushType).toUpperCase()}}"
                         matTooltipPosition="above">
                        <button mat-icon-button (click)="navigate(item.gmsId, 'connection')">
                            <app-connection-icon class="symbol"
                                                 [input]="mainService.getConnectionDescription(item.connectionQuality, item.pushType)"></app-connection-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="messages">
                <mat-header-cell *matHeaderCellDef>Messages</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div class="symbol" matTooltip="{{item.messagesCount}} new messages"
                         matTooltipPosition="above">
                        <button mat-icon-button (click)="navigate(item.gmsId, 'messages')">
                            <ng-container *ngIf="item.messagesCount > 0; else noNew">
                                <app-icon [name]="'new_message'"></app-icon>
                                <span class="unread">{{item.messagesCount}}</span>
                            </ng-container>
                            <ng-template #noNew matTooltip="No new messages" matTooltipPosition="above">
                                <app-icon [name]="'no_message'"></app-icon>
                            </ng-template>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="documents">
                <mat-header-cell *matHeaderCellDef>Documents</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div class="symbol" matTooltip="{{item.documentsCount}} new documents"
                         matTooltipPosition="above">
                        <button mat-icon-button (click)="navigate(item.gmsId, 'documents')">
                            <app-icon [name]="'folder'"></app-icon>
                            <span class="unread" *ngIf="item.documentsCount > 0">{{item.documentsCount}}</span>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="favorite">
                <mat-header-cell *matHeaderCellDef mat-sort-header>Favorite</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div class="favorite-star">
                        <button mat-icon-button (click)="changeFavorite(item)">
                            <mat-icon [ngClass]="{'active-favorite': item.isFavorite}">star</mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>

            <mat-header-row *matHeaderRowDef="displayedDeviceColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedDeviceColumns;"></mat-row>
        </mat-table>
        <mat-paginator #devicesTbPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50, 100]"
                       showFirstLastButtons></mat-paginator>
        <!-- <mat-paginator #allDevicesTbPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator> -->
    </div>
</div>
