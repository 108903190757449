<div *ngIf="main.activeItem">
<div class="g-head g-hero" [ngClass]="main.activeItem.status">
    <div class="g-hero-text">
        <div class="text">
            <div class="logo"><img src="assets/geislinger-logo.svg" alt="logo"></div>
            <h3>{{main.activeItem.designation}}</h3>
            <h5 class="damper-id"><b>{{main.activeItem.projectInfo}}</b></h5>
        </div>
    </div>
    <div class="g-hero-headline"><h1>Connection</h1></div>
<!--    <app-connection-icon class="icon" [input]="main.getConnectionDescription(main.activeItem.connectionQuality, main.activeItem.pushType)"></app-connection-icon>-->

<!--                <div class="text">-->
<!--                    <h4 class="text">{{main.getConnectionDescription(main.activeItem.connectionQuality, main.activeItem.pushType)}}</h4>-->
<!--                </div>-->
</div>
    <div class="g-container">
        <div class="g-card">
            <div class="text">
                <h3 class="name">Connection Quality</h3>
            </div>
            <app-highchart *ngIf="chart && gmsId" [options]="chart" style="min-width: 400px; min-height: 400px"></app-highchart>
            <mat-spinner *ngIf="!chart && chartIsLoading"></mat-spinner>
        </div>
        <div class="g-columns"> <!--half-width:  [class.two-columns]="imo"-->
            <div class="g-card vessel-finder-box" *ngIf="imo">
                <app-vessel-finder [imo]="imo"></app-vessel-finder>
            </div>
        </div>
    </div>

</div>
