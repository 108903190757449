import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Message} from '../models/gms/message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private host = environment.apiUrl + '/v1/message/';

  constructor(private httpClient: HttpClient) { }

  getAll(gmsId: string): Observable<Message[]> {
    const url = this.host + gmsId + `/get-all`;
    return this.httpClient.get<Message[]>(url);
  }

  markAsRead(id: number): Observable<any> {
    const url = this.host + `mark-as-read`;
    return this.httpClient.post<any>(url, id);
  }

  delete(id: number): Observable<any> {
    const url = this.host + `delete/` + id;
    return this.httpClient.delete<any>(url);
  }

  create(messages: Message[]): Observable<any> {
    const url = this.host + `create`;
    return this.httpClient.post<any>(url, messages);
  }
}
