import {Component, OnInit, ViewChild} from "@angular/core";
import {AllDevicesTableItem} from "../../models/gms/all-devices-table-item";
import {MatTableDataSource} from "@angular/material/table";
import {GmsContextService} from "../../services/gms-context.service";
import {AuthService} from "../../auth/auth.service";
import {MainService} from "../../services/main.service";
import {Role} from "../../models/user/role.enum";
import {Router} from "@angular/router";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {GmsStatus} from "../../models/gms/gms-status.enum";
import {GmsConnection} from "../../models/gms/gms-connection-enum";
import {MatSelectChange} from "@angular/material/select";
import {UserService} from "../../services/user.service";

@Component({
    selector: 'app-all-devices',
    templateUrl: './all-devices.component.html',
    styleUrls: ['./all-devices.component.css']
})
export class AllDevicesComponent implements OnInit {
    @ViewChild("devicesTbSort") devicesTbSort: MatSort;
    @ViewChild("devicesTbPaginator") devicesTbPaginator: MatPaginator;

    isLoadingDevices = true;

    isAdmin = false;
    isGDSPartner = false;
    isCustomerManager = false;

    devicesList: AllDevicesTableItem[] = [];
    devicesDataSource: MatTableDataSource<AllDevicesTableItem>;
    displayedDevices: AllDevicesTableItem[] = [];
    displayedDeviceColumns: string[] = ['shipIcon', 'id', 'name', 'lastUpdate', 'maintenance', 'status', 'damperHealth', 'connection', 'messages', 'documents', 'favorite'];

    idOrNameSearchLabel = 'ID / Name';
    accountManagerSearchLabel = 'Account Manager';
    userSearchLabel = 'User';
    statusSelectLabel = 'Status';
    connectionSelectLabel = 'Connection';

    possibleStatuses = Object.values(GmsStatus);
    possibleConnections = Object.values(GmsConnection);

    filterValues: { [filter: string]: string; } = {};

    constructor(public gmsContextService: GmsContextService,
                public authService: AuthService,
                public mainService: MainService,
                public router: Router,
                public userService: UserService) {
    }

    ngOnInit() {
        switch (this.authService.getRole()) {
            case Role.ADMIN:
                this.isAdmin = true;
                this.displayedDeviceColumns.splice(2, 0, 'accountManager', 'user');
                break;
            case Role.GDS_PARTNER:
                this.isGDSPartner = true;
                this.displayedDeviceColumns.splice(2, 0, 'user');
                break;
            case Role.CUSTOMER_MANAGER:
                this.isCustomerManager = true;
                break;
        }

        this.reloadAllDevicesList();
    }

    reloadAllDevicesList() {
        this.isLoadingDevices = true;

        this.gmsContextService.getAllTableItems().subscribe(allDevices => {
            this.devicesList = allDevices;
            this.displayedDevices = this.devicesList;

            this.reloadAllDevicesDataSource();

            this.isLoadingDevices = false;
        });
    }

    reloadAllDevicesDataSource() {
        this.devicesDataSource = new MatTableDataSource<AllDevicesTableItem>(this.displayedDevices);

        this.devicesDataSource.filter = JSON.stringify(this.filterValues);
        this.devicesDataSource.filterPredicate = (item: AllDevicesTableItem, filter: string) => {
            let searchString = JSON.parse(filter);

            return (item.gmsId.toLowerCase().includes(searchString[this.idOrNameSearchLabel] ?? "")
                    || item.gmsName.toLowerCase().includes(searchString[this.idOrNameSearchLabel] ?? ""))
                && this.getListOfStringsAsString(item.accountManagerUsernames).toLowerCase().includes(searchString[this.accountManagerSearchLabel] ?? "")
                && (this.getListOfStringsAsString(item.customerManagerUsernames).toLowerCase().includes(searchString[this.userSearchLabel] ?? "")
                    || this.getListOfStringsAsString(item.subUserUsernames).toLowerCase().includes(searchString[this.userSearchLabel] ?? ""))
                && item.status.toLowerCase().includes(searchString[this.statusSelectLabel] ?? "")
                && this.mainService.getConnectionDescription(item.connectionQuality, item.pushType).toLowerCase().includes(searchString[this.connectionSelectLabel] ?? "");
        };

        this.devicesDataSource.sort = this.devicesTbSort;
        this.devicesDataSource.paginator = this.devicesTbPaginator;

        this.devicesDataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'id':
                    return item.gmsId;
                case 'accountManager':
                    return this.getListOfStringsAsString(item.accountManagerUsernames);
                case 'user':
                    return this.getListOfStringsAsString(item.subUserUsernames);
                case 'name':
                    return item.gmsName;
                case 'favorite':
                    return item.isFavorite;
                default:
                    return item[property];
            }
        };
    }

    applySearchFilter($event: Event) {
        const inputField = ($event.target as HTMLInputElement);
        const filterValue = inputField.value.trim().toLowerCase();

        switch (inputField.labels?.item(0)?.innerText) {
            case this.idOrNameSearchLabel:
                this.filterValues[this.idOrNameSearchLabel] = filterValue;
                break;
            case this.accountManagerSearchLabel:
                this.filterValues[this.accountManagerSearchLabel] = filterValue;
                break;
            case this.userSearchLabel:
                this.filterValues[this.userSearchLabel] = filterValue;
                break;
        }

        this.reloadAllDevicesDataSource();
    }

    applyStatusFilter($event: MatSelectChange) {
        this.filterValues[this.statusSelectLabel] = $event.value?.toLowerCase();

        this.reloadAllDevicesDataSource();
    }

    applyConnectionFilter($event: MatSelectChange) {
        this.filterValues[this.connectionSelectLabel] = $event.value?.toLowerCase();

        this.reloadAllDevicesDataSource();
    }

    getListOfStringsAsString(strings: string[]): string {
        return strings.length > 0 ? strings.reduce((previousValue, currentValue) => previousValue + ', ' + currentValue) : "";
    }

    getDaysSinceLastUpdate(lastUpdate: number): string {
        let lastUpdateDate = new Date(lastUpdate);
        let today = new Date();

        let differenceInDays = (today.getTime() - lastUpdateDate.getTime()) / (1000 * 3600 * 24);

        if (differenceInDays > 10000) {
            differenceInDays = undefined;
        }
        return differenceInDays?.toFixed(0);
    }

    changeFavorite(device: AllDevicesTableItem) {
        if (device.isFavorite) {
            this.userService.removeGmsFavorite(device.gmsId).subscribe(() => {
                device.isFavorite = false
                this.mainService.initGmsList();
            });
        } else {
            this.userService.addGmsFavorite(device.gmsId).subscribe(() => {
                device.isFavorite = true
                this.mainService.initGmsList();
            });
        }
    }

    navigate(id: string, page: string) {
        this.mainService.setActiveById(id);
        this.router.navigate(['/' + id + '/' + (page === 'status' ? '0/' : '') + page]);
    }

    mapDamperHealthToStatus(damperHealth: number){
        switch (true){
            case damperHealth > 75:
                return GmsStatus.GOOD;
            case damperHealth > 50 && damperHealth <= 75:
                return GmsStatus.WARNING;
            case damperHealth <= 50:
                return GmsStatus.ALARM;
        }
    }

    protected readonly GmsStatus = GmsStatus;
}