import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm: UntypedFormGroup;
  error = null;
  success = null;

  constructor(private fb: UntypedFormBuilder, private userService: UserService) { }

  ngOnInit(): void {
    this.forgotPasswordForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]]
    });
  }

  requestPasswordReset() {
    if (this.forgotPasswordForm.valid) {
      this.userService.sendPasswordResetLink(
          this.forgotPasswordForm.get('username').value)
          .subscribe(
              () => {
                this.error = null;
                this.success = true;
              },
              error => {
                this.success = false;
                this.error = error.error;
              });
    }
  }

}
