<div class="g-head g-hero">
    <div class="g-hero-text">
        <div class="text">
            <div class="logo">
                <img src="assets/geislinger-logo.svg">
            </div>
        </div>
    </div>
    <div class="g-hero-headline">
        <h1>GMS Access Request</h1>
    </div>
</div>
<div *ngIf="gmsAccessRequest" class="main-container">
    <div *ngIf="!(loadingGmsAccessRequest || loadingGms)">
        <div *ngIf="gmsAccessRequest.status !== gmsAccessRequestStatus.Pending">
            <h3>This access-request has been {{gmsAccessRequest.status === gmsAccessRequestStatus.Approved ? 'approved' : 'declined'}}</h3>
        </div>
        <form [formGroup]="handleGmsAccessRequestForm">
            <div class="g-access-request-data">
                <div class="g-access-request-data-row">
                    <p class="g-access-request-headline-col">Email: </p>
                    <p class="g-access-request-data-col">{{gmsAccessRequest.username}}</p>
                </div>
                <div class="g-access-request-data-row">
                    <p class="g-access-request-headline-col">Application Type: </p>
                    <p class="g-access-request-data-col">{{gmsAccessRequest.isVesselOrMarineApplication ? 'Vessel / Marine' : 'Other'}}</p>
                </div>
                <div *ngIf="gmsAccessRequest.isVesselOrMarineApplication">
                    <div class="g-access-request-data-row">
                        <p class="g-access-request-headline-col">Hull No.: </p>
                        <p class="g-access-request-data-col">{{gmsAccessRequest.hullNumber}}</p>
                    </div>
                    <div class="g-access-request-data-row">
                        <p class="g-access-request-headline-col">IMO No.: </p>
                        <p class="g-access-request-data-col">{{gmsAccessRequest.imoNumber}}</p>
                    </div>
                    <div class="g-access-request-data-row">
                        <p class="g-access-request-headline-col">Vessel Name: </p>
                        <p class="g-access-request-data-col">{{gmsAccessRequest.shipName}}</p>
                    </div>
                </div>
                <div *ngIf="!gmsAccessRequest.isVesselOrMarineApplication">
                    <div class="g-access-request-data-row">
                        <p class="g-access-request-headline-col">Application Description: </p>
                        <p class="g-access-request-data-col">{{gmsAccessRequest.applicationDescription}}</p>
                    </div>
                </div>
                <div class="g-access-request-data-row">
                    <p class="g-access-request-headline-col">Type: </p>
                    <p class="g-access-request-data-col">{{gmsAccessRequest.type}}</p>
                </div>
                <div class="g-access-request-data-row">
                    <p class="g-access-request-headline-col">Reference No.: </p>
                    <p class="g-access-request-data-col">{{gmsAccessRequest.geislingerReference}}</p>
                </div>
                <div class="g-access-request-data-row">
                    <p class="g-access-request-headline-col">Product: </p>
                    <p class="g-access-request-data-col">{{gmsAccessRequest.geislingerProduct}}</p>
                </div>
                <div class="g-access-request-data-row">
                    <p class="g-access-request-headline-col">GMS: </p>
                    <p class="g-access-request-data-col">{{gmsTitle}} <mat-icon *ngIf="!gmsExists" class="g-warning-icon" title="Unknown GMS ID">warning</mat-icon></p>
                </div>
                <div class="g-access-request-data-row">
                    <p class="g-access-request-headline-col">Email Comment:</p>
                    <mat-form-field class="full-width-input" *ngIf="gmsAccessRequest.status === gmsAccessRequestStatus.Pending">
                        <p class="g-access-request-data-col">
                            <textarea
                                    class="email-comment"
                                    formControlName="emailComment"
                                    matInput
                                    type="text"
                                    rows="5">
                            </textarea>
                        </p>
                    </mat-form-field>
                    <p class="g-access-request-data-col" *ngIf="gmsAccessRequest.status !== gmsAccessRequestStatus.Pending">
                        <span>{{gmsAccessRequest.emailComment}}</span>
                    </p>
                </div>
                <button class="g-button g-form-main-button" type="submit"
                        *ngIf="gmsAccessRequest.status === gmsAccessRequestStatus.Pending"
                        [ngClass]="!gmsExists ? 'g-disabled' : ''"
                        (click)="openAcceptOrDeclineDialog(gmsAccessRequestStatus.Approved)"
                        [disabled]="!gmsExists"
                >
                    Approve
                </button>
                <button class="g-button g-form-main-button" type="submit"
                        *ngIf="gmsAccessRequest.status === gmsAccessRequestStatus.Pending"
                        (click)="openAcceptOrDeclineDialog(gmsAccessRequestStatus.Declined)"
                >
                    Decline
                </button>
            </div>
        </form>
    </div>
</div>
<mat-spinner *ngIf="loadingGmsAccessRequest || loadingGms"></mat-spinner>
<div *ngIf="!gmsAccessRequest" class="main-container">
    <h3>{{errorMessage}}</h3>
</div>
