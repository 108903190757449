import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FileMeta, IUploadedFile, Status} from '../../models/file-meta';
import {UploadService} from '../../services/upload.service';
import {FileStatusService} from '../../services/file-status.service';
import {from, Observable, Observer, of} from 'rxjs';
import {catchError, concatMap} from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {MainService} from '../../services/main.service';
import {GmsInfoDirective} from "../_shared/gms-info/gms-info.directive";

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent extends GmsInfoDirective implements OnInit {

    displayedColumns: string[] = ['gms', 'file', 'status', 'size', 'datetime'];
    fileMetas: FileMeta[] = [];
    currentFileUploadIndex = -1;

    @ViewChild('selectedFiles')
    selectedFileVar: ElementRef;

    constructor(private uploadService: UploadService,
                private fileStatusService: FileStatusService,
                private route: ActivatedRoute,
                public main: MainService,
                public router: Router) {
        super(main, router);
    }

    ngOnInit(): void {
        this.init();
    }

    init() {
        super.ngOnInit();

        const itemId = this.route.snapshot.paramMap.get('id');

        if(itemId) {
            this.gmsId = itemId;

            this.main.setActiveById(itemId);
        }
    }

    onFilesSelected(event) {
        if (!event.target.files) {
            return;
        }

        const selectedFiles = Array.from<File>(event.target.files);
        const filenames = selectedFiles.map(file => file.name);

        this.fileStatusService.getFileStatus(this.gmsId, filenames)
            .subscribe(fileMetas => {
                    this.fileMetas = fileMetas;
                    this.selectedFileVar.nativeElement.value = ''; // reset filename selector (input html tag)

                    from(selectedFiles)
                        .pipe(
                            concatMap((file: File) =>
                                this.validateFile(file, fileMetas)
                                    .pipe(catchError((error: IUploadedFile) => of(error)))
                                    .pipe(concatMap((uploadedFile: IUploadedFile) =>
                                        this.uploadFile(uploadedFile))))
                        ).subscribe(event => {
                            if (event.type === HttpEventType.UploadProgress) {
                                console.log('progress: ' + Math.round(100 * event.loaded / event.total));

                                // console.log('index: ' + this.currentFileUploadIndex);
                                if (this.currentFileUploadIndex >= 0) {
                                    this.fileMetas[this.currentFileUploadIndex].isUploading = true;
                                    this.fileMetas[this.currentFileUploadIndex].uploadProgress = Math.round(100 * event.loaded / event.total);
                                    if (this.fileMetas[this.currentFileUploadIndex].uploadProgress === 100) {
                                        this.fileMetas[this.currentFileUploadIndex].isUploading = false;
                                    }
                                }
                            }
                            if (event.type === HttpEventType.Response) {
                                // Could not get the following line to work (entry is not getting updated), therefore work around see below:
                                // this.fileMetas[fileMetasIndex] = event.body;

                                if (event.status === 200) {
                                    const returnedFileMeta: FileMeta = event.body;
                                    const idx = this.fileMetas.findIndex(fm => fm.filename === returnedFileMeta.filename);
                                    if (idx >= 0) {
                                        this.fileMetas[idx].status = event.body.status;
                                        this.fileMetas[idx].message = event.body.message;
                                        this.fileMetas[idx].size = event.body.size;
                                        this.fileMetas[idx].uploadDate = event.body.uploadDate;
                                    }

                                    // console.log('Upload successful of file ' + returnedFileMeta.filename);
                                }
                            }
                        },
                        error => {
                            if (this.currentFileUploadIndex >= 0) {
                                this.fileMetas[this.currentFileUploadIndex].isUploading = false;
                                this.fileMetas[this.currentFileUploadIndex].uploadError = true;
                                this.fileMetas[this.currentFileUploadIndex].status = Status.ERROR;
                                if (!this.fileMetas[this.currentFileUploadIndex].message) {
                                    this.fileMetas[this.currentFileUploadIndex].message = 'Error during upload.';
                                }
                                console.error('Error during uploading file with error: ' + error.error);
                            }

                        });
                }
            );
    }

    validateFile(file: File, fileMetas: FileMeta[]):
        Observable<IUploadedFile> {
        const fileMeta = fileMetas.find(fm => fm.filename === file.name);

        this.currentFileUploadIndex = this.fileMetas.findIndex(fm => fm.filename === file.name);
        // console.log('validateFile: determined index: ' + this.currentFileUploadIndex);

        return new Observable((observer: Observer<IUploadedFile>) => {
            if (!fileMeta) {
                observer.error({fileMeta: {status: Status.ERROR}, error: 'fileMeta is null'});
            }
            this.validateSize(file, observer);
            this.validateStatus(file, fileMeta, observer);
            observer.next({file, fileMeta});
            observer.complete();
        });
    }

    validateStatus(file: File, fileMeta: FileMeta, observer: Observer<IUploadedFile>): void {
        if (fileMeta.status === Status.ALREADY_UPLOADED) {
            observer.error({fileMeta, error: 'File already uploaded'});
        }
        if (fileMeta.status === Status.ERR_FILENAME) {
            observer.error({fileMeta, error: 'Filename is invalid'});
        }
    }

    validateSize(file: File, observer: Observer<IUploadedFile>): void {
        const {name, size} = file;
        if (!this.isValidSize(size)) {
            const errorMessage = 'File exceeds max file size of 20MB';
            observer.error({fileMeta: {status: Status.ERR_FILESIZE, message: errorMessage}, error: errorMessage});
        }
    }

    isValidSize(size: number): boolean {
        const toKByte = size / 1024;
        return toKByte >= 0 && toKByte <= 20480;
    }

    private uploadFile(uploadedFile: IUploadedFile): Observable<any> {
        if (uploadedFile.file && !uploadedFile.error) {
            return this.uploadService.uploadFile(this.gmsId, uploadedFile.file);
        }

        return of('error during upload call');
    }
}
