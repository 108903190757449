import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoginComponent } from './components/login/login.component';
import { AdminComponent } from './components/admin/admin.component';
import { OverviewComponent } from './components/overview/overview.component';
import { DetailComponent } from './components/detail/detail.component';
import { StatusComponent } from './components/status/status.component';
import { MessagesComponent } from './components/messages/messages.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { ConnectionComponent } from './components/connection/connection.component';
import { DocumentsComponent } from './components/documents/documents.component';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { ErrorComponent } from './components/forbidden/error.component';
import { TotpComponent } from './components/totp/totp.component';
import { NavisLoginComponent } from './components/navis-login/navis-login.component';
import { AlertArchiveComponent } from './components/alert-archive/alert-archive.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { ConfirmRegistrationComponent } from './components/confirm-registration/confirm-registration.component';
import { RequestGmsAccessComponent } from './components/request-gms-access/request-gms-access.component';
import { HandleGmsAccessRequestComponent } from './components/handle-gms-access-request/handle-gms-access-request.component';
import { AllDevicesComponent } from './components/all-devices/all-devices.component';
import { DashboardSettingsComponent } from './components/dashboard-settings/dashboard-settings.component';
import { GmsPageRouteEnum } from './components/_shared/gms-info/gms-page-route.enum';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportDetailsComponent } from './components/report-details/report-details.component';
import { isGmsListInitializedGuard } from 'src/app/guards/gms-list-initialized.guard';
import { isAuthenticatedGuard } from 'src/app/guards/authenticated.guard';
import { isRegistrationAllowedGuard } from 'src/app/guards/registration-allowed.guard';
import { isAuthenticatedAsAdminGuard } from 'src/app/guards/admin-authenticated.guard';

const routes: Routes = [
  { path: '', redirectTo: '/overview', pathMatch: 'full' },
  { path: 'overview', component: OverviewComponent, canActivate: [isAuthenticatedGuard] },
  { path: `:id/${GmsPageRouteEnum.Detail}`, component: DetailComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:id/:engineIndex/${GmsPageRouteEnum.Status}`, component: StatusComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:gmsId/${GmsPageRouteEnum.Messages}`, component: MessagesComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:gmsId/${GmsPageRouteEnum.Messages}/:type`, component: MessagesComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:gmsId/${GmsPageRouteEnum.Messages}/:type/:id`, component: MessagesComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:gmsId/${GmsPageRouteEnum.Documents}`, component: DocumentsComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:gmsId/${GmsPageRouteEnum.Documents}/:id`, component: DocumentsComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:gmsId/${GmsPageRouteEnum.AlertArchive}`, component: AlertArchiveComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:gmsId/${GmsPageRouteEnum.Reports}`, component: ReportsComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:gmsId/${GmsPageRouteEnum.Reports}/:reportId`, component: ReportsComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  {
    path: `:gmsId/${GmsPageRouteEnum.ReportDetails}/:reportId`,
    component: ReportDetailsComponent,
    canActivate: [isAuthenticatedAsAdminGuard, isGmsListInitializedGuard],
  },
  { path: `:id/${GmsPageRouteEnum.FileUpload}`, component: FileUploadComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `:id/${GmsPageRouteEnum.Connection}`, component: ConnectionComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: `${GmsPageRouteEnum.FileUpload}`, component: FileUploadComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'certificates', component: CertificatesComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'registration', component: RegistrationComponent, canActivate: [isRegistrationAllowedGuard] },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'password-reset/:token', component: PasswordResetComponent },
  { path: 'login', component: LoginComponent },
  { path: 'totp', component: TotpComponent },
  { path: 'navis-login', component: NavisLoginComponent },
  { path: 'error', component: ErrorComponent },
  { path: 'user/:id/edit', component: UserEditComponent, canActivate: [isAuthenticatedGuard, isGmsListInitializedGuard] },
  { path: 'confirm-registration/:token', component: ConfirmRegistrationComponent },
  { path: 'gms-access/request', component: RequestGmsAccessComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'gms-access/handle/:token', component: HandleGmsAccessRequestComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'all-devices', component: AllDevicesComponent, canActivate: [isAuthenticatedGuard] },
  { path: 'dashboard-settings', component: DashboardSettingsComponent, canActivate: [isAuthenticatedGuard] },
  { path: '**', redirectTo: '/error', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
