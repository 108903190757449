<div class="g-hero">
    <div class="g-hero-text">
        <h1>Geislinger Analytics Platform</h1>
    </div>
    <div class="g-hero-img sujet"></div>
</div>
<div class="g-head">
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div *ngIf="!loading">
        <div *ngIf="success">
            <h3>Confirmation successful!</h3>
            <p>You can now log in with your username & password</p>
            <p><a routerLink="/login">Click here to log in</a></p>
        </div>
        <div *ngIf="!success">
            <h3>Something went wrong:</h3>
            <p>{{userLoadingError.toString()}}</p>
            <div class="g-form-login-link"><a routerLink="/login">back to login</a></div>
        </div>
    </div>
</div>
