import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../models/user/user';
import {environment} from '../../environments/environment';
import {PersonalData} from "../models/user/personal-data";
import {GmsFavorite} from "../models/gms/gms-favorite";

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private host = environment.apiUrl + '/v1/user/';

    constructor(private httpClient: HttpClient) {
    }

    getAll(): Observable<User[]> {
        const url = this.host + `get-all`;
        return this.httpClient.get<User[]>(url);
    }

    get(username: string): Observable<User> {
        const url = this.host + `get?username=${encodeURIComponent(username)}`;
        return this.httpClient.get<User>(url)
    }

    getAllAdminsAndPartners(): Observable<User[]> {
        const url = this.host + `admins-and-partners`;
        return this.httpClient.get<User[]>(url);
    }

    getAllAdminsAndPartnersUsernames(): Observable<string[]> {
        const url = this.host + `admins-and-partners/usernames`;
        return this.httpClient.get<string[]>(url);
    }

    getAllRegularUsers(): Observable<User[]> {
        const url = this.host + `regular-users`;
        return this.httpClient.get<User[]>(url);
    }

    getSubUsers(username?: string): Observable<User[]> {
        let url = this.host + `get-sub-users`;

        if (username) {
            url += `?username=${encodeURIComponent(username)}`
        }

        return this.httpClient.get<User[]>(url);
    }

    updateActive(username: string, active: boolean): Observable<any> {
        const url = this.host + `update/active?username=${encodeURIComponent(username)}`;
        return this.httpClient.patch(url, active);
    }

    updateUsingCompactDashboardView(usingCompactDashboardView: boolean): Observable<any> {
        const url = this.host + `update/using-compact-dashboard-view`;
        return this.httpClient.patch(url, usingCompactDashboardView);
    }

    updateUsingLightMode(usingLightMode: boolean): Observable<any> {
        const url = this.host + `update/using-light-mode`;
        return this.httpClient.patch(url, usingLightMode);
    }

    updateGmsFavorites(gmsFavorites: GmsFavorite[]): Observable<any> {
        const url = this.host + `update/gms-favorites`;
        return this.httpClient.patch(url, gmsFavorites);
    }

    addGmsFavorite(gmsId: string): Observable<any> {
        const url = this.host + `update/gms-favorites/add`;
        return this.httpClient.patch(url, gmsId);
    }

    removeGmsFavorite(gmsId: string): Observable<any> {
        const url = this.host + `update/gms-favorites/remove`;
        return this.httpClient.patch(url, gmsId);
    }

    changePassword(oldPassword: string, newPassword: string): any {
        const endpoint = environment.apiUrl + '/v1/password/change/';
        return this.httpClient.post<any>(endpoint, btoa(oldPassword + ':' + newPassword));
    }

    updateAdministrativeData(user: User): Observable<User> {
        const url = this.host + `update/administrative-data`;
        return this.httpClient.put<User>(url, user);
    }

    updatePersonalData(personalData: PersonalData): Observable<any> {
        const url = this.host + `update/personal-data`;
        return this.httpClient.patch(url, personalData);
    }

    sendPasswordResetLink(username: string): any {
        const endpoint = environment.apiUrl + '/v1/password/reset/send-link';
        return this.httpClient.post<any>(endpoint, username);
    }

    resetPassword(token: string, password: string): any {
        const endpoint = environment.apiUrl + '/v1/password/reset/' + token;

        const loginOptions = {
            headers: new HttpHeaders().set('Authorization', 'Basic ' + btoa('' + ':' + password)),
            responseType: 'text' as 'json'
        };

        return this.httpClient.post<any>(endpoint, null, loginOptions);
    }

    checkPasswordResetToken(token: string): any {
        const endpoint = environment.apiUrl + '/v1/password/reset/check-token/' + token;

        return this.httpClient.get<any>(endpoint);
    }

    getNotificationsForUser(): Observable<Set<string>> {
        const url = this.host + `notifications/get-all`;
        return this.httpClient.get<Set<string>>(url);
    }

    updateNotificationsForUser(notifications: Set<string>): any {
        const url = this.host + `notifications/update`;
        return this.httpClient.post<any>(url, Array.from(notifications));
    }

    upsert(user): Observable<User[]> {
        const url = this.host + `update`;
        return this.httpClient.put<User[]>(url, user);
    }

    get2FASettingsForUser(): any {
        const url = this.host + `2fa-settings`;
        return this.httpClient.get<any>(url);
    }

    update2FASettingsForUser(using2FA: boolean): any {
        const url = this.host + `2fa-settings`;
        return this.httpClient.post<any>(url, using2FA);
    }

    confirm2FASettingsForUser(totp: string): any {
        const url = this.host + `2fa-settings-confirmation`;
        return this.httpClient.post<any>(url, totp);
    }
}
