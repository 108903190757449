import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";

import {AuthService} from "../../auth/auth.service";
import {TOKEN} from "../_shared/constants/local-storage-constants";

@Component({
    selector: 'app-totp',
    templateUrl: './totp.component.html',
    styleUrls: ['./totp.component.css']
})
export class TotpComponent implements OnInit {
    totpForm: UntypedFormGroup;
    error = null;

    constructor(private fb: UntypedFormBuilder, private authService: AuthService, private router: Router) {
    }

    ngOnInit(): void {
        this.totpForm = this.fb.group({
            totp: ['', [Validators.required]]
        });
    }

    sendTotp() {
        if (this.totpForm.valid) {
            const totp = this.totpForm.get('totp').value;

            this.authService.loginWithTotp(totp).subscribe(
                data => {
                    localStorage.setItem(TOKEN, data);
                    this.router.navigate(['']);
                },
                err => {
                    console.log(err);
                    this.error = err;
                }
            )
        }
    }

}
