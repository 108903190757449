import {Component} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {OtherApplicationTypes, VesselOrMarineApplicationTypes} from "./application-types"
import {GmsAccessService} from "../../services/gms-access.service";
import {GmsAccessRequestStatusEnum} from "../handle-gms-access-request/gmsAccessRequestStatus.enum";
import {environment} from "../../../environments/environment";

@Component({
    selector: 'app-request-gms-access',
    templateUrl: './request-gms-access.component.html',
    styleUrls: ['./request-gms-access.component.css'],
})
export class RequestGmsAccessComponent {
    requestAccessForm: UntypedFormGroup
    requestLoading = false;
    requestSent = false;
    requestError: string = null;
    showRequestAccessForm: boolean = true;
    protected readonly window = window;

    isVesselOrMarineApplication: boolean = true;
    vesselOrMarineApplicationTypeOptions = VesselOrMarineApplicationTypes;
    otherApplicationTypeOptions = OtherApplicationTypes;

    gmsRequestManual = environment.gmsRequestManual;

    tooltips = {
        ['gmsId']: "GMS ID – for more information see GMS Request Manual",
        ['hullNumber']: "Hull number of vessel",
        ['imoNumber']: "IMO number of vessel",
        ['vesselName']: "Name of vessel to be used in the Geislinger Analytics Platform",
        ['geislingerReference']: "Geislinger reference – for more information see GMS Request Manual",
        ['geislingerProduct']: "Installed Geislinger products – for more information see GMS Request Manual",
        ['applicationDescription']: "Designation of applications to be used in the Geislinger Analytics Platform",
    }

    constructor(fb: UntypedFormBuilder,
                private gmsAccessService: GmsAccessService) {
        this.requestAccessForm = fb.group({
            gmsId: ['', [Validators.required]],
            isVesselOrMarineApplication: true,
            hullNumber: '',
            imoNumber: '',
            shipName: '',
            type: '',
            applicationDescription: '',
            geislingerReference: '',
            geislingerProduct: ''
        });
    }

    requestAccess() {
        this.requestLoading = true;
        this.gmsAccessService.requestAccess({
            token: '', // Set in backend
            username: '', // Set in backend
            gmsId: this.requestAccessForm.get('gmsId').value,
            isVesselOrMarineApplication: this.requestAccessForm.get('isVesselOrMarineApplication').value,
            hullNumber: this.requestAccessForm.get('hullNumber').value,
            imoNumber: this.requestAccessForm.get('imoNumber').value,
            shipName: this.requestAccessForm.get('shipName').value,
            type: this.requestAccessForm.get('type').value,
            applicationDescription: this.requestAccessForm.get('applicationDescription').value,
            geislingerReference: this.requestAccessForm.get('geislingerReference').value,
            geislingerProduct: this.requestAccessForm.get('geislingerProduct').value,
            emailComment: '', // Not required yet
            status: GmsAccessRequestStatusEnum.Pending,
        })
            .subscribe((resp) => {
                this.requestLoading = false;
                this.requestSent = true;

                if (resp.sendMailError) {
                    this.requestError = "The request was submitted successfully, but there was an issue informing the team. Please contact your account-manager. ";
                } else {
                    this.requestError = null;
                }
                this.showRequestAccessForm = false;
        }, error => {
                this.requestLoading = false;
                this.requestSent = true;
                this.requestError = "The request could not be submitted - please contact the support";
                this.showRequestAccessForm = false;
        });

        this.requestAccessForm.reset();
        this.requestAccessForm.patchValue({ isVesselOrMarineApplication: true });
    }

    toggleApplicationType() {
        this.isVesselOrMarineApplication = !this.isVesselOrMarineApplication;
        this.requestAccessForm.patchValue({
            isVesselOrMarineApplication: this.isVesselOrMarineApplication
        });
    }

    setShowRequestAccessFormToTrue() {
        this.showRequestAccessForm = true;
    }
}