<div class="ship-data">
    <div class="g-icon-text-horizontal">
        <div class="icon">
            <app-icon [name]="ship.iconInfo.toLowerCase()"></app-icon>
        </div>
        <div class="text">
            <h4 class="name">{{ship.name | slice:0:33}}</h4>
            <div class="call-sign">{{ship.gmsId}}</div>
        </div>
    </div>
</div>
<div class="damper" (click)="navigate(ship.gmsId, 'detail')">
    <div class="alarm-note" *ngIf="ship.status===GmsStatus.ALARM">
        <app-icon [name]="'alarm'"></app-icon>
    </div>
    <div class="g-number last-connection" *ngIf="ship.lastUpdated">
        <div class="g-number-integer">{{main.timeUntil(ship.lastUpdated)[0]}}</div>
        <div class="g-number-text">
            <div class="g-number-description">last update</div>
            <span class="g-number-unit">{{main.timeUntil(ship.lastUpdated)[1]}} </span>ago
        </div>
    </div>
    <div class="g-number last-connection" *ngIf="!ship.lastUpdated">
        <div class="g-number-text">
            <div class="g-number-description">last update</div>
            <span class="g-number-unit">No data</span>
        </div>

    </div>
    <div class="damper-table">
        <div class="damper-row">
            <div class="g-card damper-card">
                <div class="g-icon-text-horizontal">
                    <div class="icon"
                         *ngIf="ship.statusPerEngine[0].druidDatasource.includes('damper-analysis') || ship.statusPerEngine[0].druidDatasource.includes('coupling-analysis')">
                        <app-icon [name]="'damper'"></app-icon>
                    </div>
                    <div class="text">
                        <div class="info">{{ship.statusPerEngine[0].installedProducts}}</div>
                        <div class="maintenance"
                             *ngIf="ship.statusPerEngine[0].druidDatasource.includes('damper-analysis') || ship.statusPerEngine[0].druidDatasource.includes('coupling-analysis')">
                            <div class="maintenance-bar"
                                 [ngStyle]="{'width' : ship.statusPerEngine[0].maintenanceBar * 100 + '%'}"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="info damper-card engine-icons">
                <div class="g-card status" [ngClass]="ship.status" (click)="navigate(ship.gmsId, 'status')">
                    <app-status-icon class="symbol" [input]="ship.status"
                                     matTooltip="Status: {{ship.status}}" matTooltipPosition="above"></app-status-icon>
                </div>
                <div class="g-card connection"
                     [ngClass]="main.getConnectionDescription(ship.connectionQuality, ship.pushType)"
                     (click)="ship.pushType === 'manualPush' ? navigate(ship.gmsId, 'upload') : navigate(ship.gmsId, 'connection')">
                    <app-connection-icon class="symbol"
                                         [input]="main.getConnectionDescription(ship.connectionQuality, ship.pushType)"
                                         matTooltip="Connection: {{main.getConnectionDescription(ship.connectionQuality, ship.pushType)}}"
                                         matTooltipPosition="above"></app-connection-icon>
                </div>
                <div class="g-card notifications" [ngClass]="ship.status"
                     (click)="navigate(ship.gmsId, 'messages')">
                    <div class="symbol" matTooltip="{{ship.unreadMessagesCount}} new messages"
                         matTooltipPosition="above">
                        <ng-container *ngIf="ship.unreadMessagesCount > 0; else noNew">
                            <app-icon [name]="'new_message'"></app-icon>
                            <span class="unread">{{ship.unreadMessagesCount}}</span>
                        </ng-container>
                        <ng-template #noNew matTooltip="No new messages" matTooltipPosition="above">
                            <app-icon [name]="'no_message'"></app-icon>
                        </ng-template>
                    </div>
                </div>
                <div class="g-card notifications" [ngClass]="ship.status"
                     (click)="navigate(ship.gmsId, 'documents')">
                    <div class="symbol" matTooltip="{{ship.unreadDocumentsCount}} new documents"
                         matTooltipPosition="above">
                        <app-icon [name]="'folder'"></app-icon>
                        <span class="unread" *ngIf="ship.unreadDocumentsCount > 0">{{ship.unreadDocumentsCount}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div *ngFor="let engine of ship.statusPerEngine.slice(1, ship.statusPerEngine.length)" class="damper-row">
            <div class=" g-card damper-card g-icon-text-horizontal">
                <div class="icon"
                     *ngIf="engine.druidDatasource.includes('damper-analysis') || engine.druidDatasource.includes('coupling-analysis')">
                    <app-icon [name]="'damper'"></app-icon>
                </div>
                <div class="text">
                    <div class="info">{{engine.installedProducts}}</div>
                    <div class="maintenance"
                         *ngIf="engine.druidDatasource.includes('damper-analysis') || engine.druidDatasource.includes('coupling-analysis')">
                        <div class="maintenance-bar" [ngStyle]="{'width' : engine.maintenanceBar * 100 + '%'}"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>