import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {MessageTemplate} from '../models/gms/message-template';

@Injectable({
  providedIn: 'root'
})
export class MessageTemplateService {
  private host = environment.apiUrl + '/v1/message-template/';

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<MessageTemplate[]> {
    const url = this.host + `get-all`;
    return this.httpClient.get<MessageTemplate[]>(url);
  }

  upsert(messageTemplate: MessageTemplate): Observable<MessageTemplate[]> {
    const url = this.host + `upsert`;
    return this.httpClient.post<MessageTemplate[]>(url, messageTemplate);
  }
}
