import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { TOKEN } from 'src/app/components/_shared/constants/local-storage-constants';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthenticatedGuardService {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    if (this.authService.loggedIn()) {
      if (this.authService.isAwaiting2FA()) {
        return this.router.parseUrl('/totp');
      }
      return this.authService.isAdminOrPartner();
    } else {
      localStorage.removeItem(TOKEN);
      return this.router.parseUrl('/login');
    }
  }
}

export const isAuthenticatedAsAdminGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(AdminAuthenticatedGuardService).canActivate();
};
