import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/header/header.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { NgxFilesizeModule } from 'ngx-filesize';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthModule } from './auth/auth.module';
import { ApiInterceptor } from './auth/api.interceptor';
import { AdminComponent } from './components/admin/admin.component';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { NavComponent } from './components/nav/nav.component';
import { DetailComponent } from './components/detail/detail.component';
import { IconComponent } from './components/icon/icon.component';
import { OverviewComponent } from './components/overview/overview.component';
import { HighchartComponent } from './components/highchart/highchart.component';
import { StatusIconComponent } from './components/status-icon.component';
import { ConnectionIconComponent } from './components/connection-icon.component';
import { StatusComponent } from './components/status/status.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { SettingsComponent } from './components/settings/settings.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { ConnectionComponent } from './components/connection/connection.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DocumentsComponent } from './components/documents/documents.component';
import { VesselFinderComponent } from './components/vessel-finder/vessel-finder.component';
import { MatSortModule } from '@angular/material/sort';
import { MatRadioModule } from '@angular/material/radio';
import { CertificatesComponent } from './components/certificates/certificates.component';
import { AuthConfigModule } from './auth/auth-config.module';
import { ErrorComponent } from './components/forbidden/error.component';
import { TotpComponent } from './components/totp/totp.component';
import { NavisLoginComponent } from './components/navis-login/navis-login.component';
import { AlertArchiveComponent } from './components/alert-archive/alert-archive.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UserEditComponent } from './components/user-edit/user-edit.component';
import { ConfirmRegistrationComponent } from './components/confirm-registration/confirm-registration.component';
import { RequestGmsAccessComponent } from './components/request-gms-access/request-gms-access.component';
import { HandleGmsAccessRequestComponent } from './components/handle-gms-access-request/handle-gms-access-request.component';
import { AllDevicesComponent } from './components/all-devices/all-devices.component';
import { DashboardSettingsComponent } from './components/dashboard-settings/dashboard-settings.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ShipClassicComponent } from './components/overview/ship-classic/ship-classic.component';
import { ShipCompactComponent } from './components/overview/ship-compact/ship-compact.component';
import { GmsInfoDirective } from './components/_shared/gms-info/gms-info.directive';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportDetailsComponent } from './components/report-details/report-details.component';

@NgModule({
  declarations: [
    AppComponent,
    FileUploadComponent,
    LoginComponent,
    HeaderComponent,
    AdminComponent,
    NavComponent,
    DetailComponent,
    IconComponent,
    OverviewComponent,
    HighchartComponent,
    StatusIconComponent,
    ConnectionIconComponent,
    StatusComponent,
    MessagesComponent,
    ConfirmationDialogComponent,
    SettingsComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    PasswordResetComponent,
    ConnectionComponent,
    DocumentsComponent,
    VesselFinderComponent,
    CertificatesComponent,
    ErrorComponent,
    TotpComponent,
    AlertArchiveComponent,
    ReportsComponent,
    ReportDetailsComponent,
    NavisLoginComponent,
    UserEditComponent,
    ConfirmRegistrationComponent,
    RequestGmsAccessComponent,
    HandleGmsAccessRequestComponent,
    AllDevicesComponent,
    DashboardSettingsComponent,
    ShipClassicComponent,
    ShipCompactComponent,
    GmsInfoDirective,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatProgressBarModule,
    NgxFilesizeModule,
    MatInputModule,
    FormsModule,
    AuthModule,
    MatCardModule,
    MatSelectModule,
    MatTooltipModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatTabsModule,
    ReactiveFormsModule,
    ClipboardModule,
    MatDialogModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatSortModule,
    MatRadioModule,
    AuthConfigModule,
    MatPaginatorModule,
    DragDropModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
