<div *ngIf="main.activeItem">
<div class="g-head g-hero" [ngClass]="main.activeItem.status">
    <div class="g-hero-text">
        <div class="text">
            <div class="logo"><img src="assets/geislinger-logo.svg" alt="logo"></div>
            <h3>{{main.activeItem.designation}}</h3>
            <h5 class="damper-id"><b>{{main.activeItem.projectInfo}}</b></h5>
        </div>
    </div>
    <div class="g-hero-headline"><h1>Upload</h1></div>
</div>

<div class="g-card">
    <h5>Select the files to upload</h5>
        <button type="button" class="g-button" (click)="selectedFiles.click()">Choose Files</button>
        <input hidden #selectedFiles type="file" multiple (click)="$event.stopPropagation()" (change)="onFilesSelected($event)" [disabled]="!gmsId">
</div>

<div *ngIf="fileMetas">
    <table mat-table [dataSource]="fileMetas" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <ng-container matColumnDef="gms">
            <th mat-header-cell *matHeaderCellDef> GMS</th>
            <td mat-cell *matCellDef="let element"> {{element.gmsId}} </td>
        </ng-container>

        <ng-container matColumnDef="file">
            <th mat-header-cell *matHeaderCellDef> Filename</th>
            <td mat-cell *matCellDef="let element"> {{element.filename}}
                <div *ngIf="element.isUploading">
                    <mat-progress-bar mode="determinate" [value]="element.uploadProgress"></mat-progress-bar>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status</th>
            <td mat-cell *matCellDef="let element">
                <div matTooltip="{{element.message}}" matTooltipClass="tooltip">
                <mat-icon *ngIf="element.status === 'DONE'">cloud_done</mat-icon>
                <mat-icon *ngIf="element.status === 'ALREADY_UPLOADED'">block</mat-icon>
                <mat-icon *ngIf="element.status.startsWith('ERR')">error</mat-icon>
                    <div *ngIf="!element.isUploading && element.status === 'UNKNOWN'">WAITING ...</div>
                <div *ngIf="!element.isUploading && element.status !== 'UNKNOWN'">{{element.status}}</div>
                </div>
                <div *ngIf="element.isUploading">
                    UPLOADING... {{element.uploadProgress}} %
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="size">
            <th mat-header-cell *matHeaderCellDef> File Size</th>
            <td mat-cell *matCellDef="let element"> {{element.size < 1 ? '-' : element.size | filesize}} </td>
        </ng-container>

        <ng-container matColumnDef="datetime">
            <th mat-header-cell *matHeaderCellDef> Upload Date</th>
            <td mat-cell
                *matCellDef="let element"> {{element.uploadDate ? (element.uploadDate | date:"yyyy-MM-dd HH:mm") : '-'}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
</div>
