import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Gms} from '../models/gms/gms';
import {GmsNonClassified} from "../models/gms/gms-non-classified";

@Injectable({
  providedIn: 'root'
})
export class GmsService {

  private host = environment.apiUrl + '/v1/gms/';

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<Gms[]> {
    const url = this.host + `get-all`;
    return this.httpClient.get<Gms[]>(url);
  }

  getAllNonClassified(): Observable<GmsNonClassified[]> {
    const url = this.host + `get-all-non-classified`;
    return this.httpClient.get<GmsNonClassified[]>(url);
  }

  get(id: string): Observable<Gms> {
    const url = this.host + `get/` + id;
    return this.httpClient.get<Gms>(url);
  }

  getNonClassified(id: string): Observable<GmsNonClassified> {
    const url = this.host + `get-non-classified/` + id;
    return this.httpClient.get<GmsNonClassified>(url);
  }

  upsert(gmsProperties: any): Observable<any> {
    const url = this.host + `upsert/`;
    return this.httpClient.post<any>(url, gmsProperties);
  }

  delete(gmsId: string): Observable<any> {
    const url = this.host + `delete/${gmsId}`;
    return this.httpClient.delete(url);
  }
}
