import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {checkPasswordRequirements, mustMatch} from "../../utils/password-utils";

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  passwordResetForm: UntypedFormGroup;
  token = null;
  error = null;
  success = null;

  constructor(private fb: UntypedFormBuilder,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
    this.passwordResetForm = fb.group({
          password: ['', [Validators.required, checkPasswordRequirements()]],
          confirmPassword: ['', [Validators.required, checkPasswordRequirements()]]
        },
        {
            validators: [mustMatch('password', 'confirmPassword', true)]
        }
    );
  }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      if (params.has('token')) {
        this.userService.checkPasswordResetToken(params.get('token')).subscribe(valid => {
              if (valid) {
                this.token = params.get('token');
                this.error = null;
              } else {
                this.token = null;
                this.error = 'Invalid token or link. Please request a new one.';
              }
            },
            error => {
              this.error = error.error;
            });
      } else {
        this.error = 'No token provided. Looks like this is an invalid link';
        console.log(this.error);
      }

    });
  }

  sendPasswordReset() {
    if (this.passwordResetForm.valid && this.token) {
      this.userService.resetPassword(this.token,
          this.passwordResetForm.get('password').value)
          .subscribe(
              () => {
                this.success = true;
                this.error = null;

                setTimeout(() => {
                  this.router.navigate(['login']);
                }, 5000);
              },
              error => {
                this.error = error.error;
              });

      this.passwordResetForm.reset();
    }
  }

}
