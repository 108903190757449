import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {GmsContext} from '../models/gms/gms-context';
import {AllDevicesTableItem} from "../models/gms/all-devices-table-item";

@Injectable({
  providedIn: 'root'
})
export class GmsContextService {

  private host = environment.apiUrl + '/v1/gms-context/';

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<GmsContext[]> {
    const url = this.host + `get-all`;
    return this.httpClient.get<GmsContext[]>(url);
  }

  getAllTableItems(): Observable<AllDevicesTableItem[]> {
    const url = this.host + `get-all/table-items`;
    return this.httpClient.get<AllDevicesTableItem[]>(url);
  }

  getFavorites(): Observable<GmsContext[]> {
    const url = this.host + `get-favorites`;
    return this.httpClient.get<GmsContext[]>(url);
  }

  get(gmsId: string): Observable<GmsContext> {
    const url = this.host + `get/` + gmsId;
    return this.httpClient.get<GmsContext>(url);
  }

}
