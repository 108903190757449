import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { Gms } from '../models/gms/gms';
import { Role } from '../models/user/role.enum';
import { LoginResponse } from '../models/user/login-response';
import { Observable } from 'rxjs';
import { COMPACT_VIEW, LIGHT_MODE, TOKEN } from '../components/_shared/constants/local-storage-constants';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private httpClient: HttpClient, private jwtHelperService: JwtHelperService) {}

  get authHeader(): string {
    return 'Bearer ' + localStorage.getItem(TOKEN);
  }

  login(username: string, password: string): Observable<LoginResponse> {
    const endpoint = environment.apiUrl + '/authenticate';

    const loginOptions = {
      headers: new HttpHeaders().set('Authorization', 'Basic ' + btoa(username + ':' + password)),
    };

    return this.httpClient.get<LoginResponse>(endpoint, loginOptions);
  }

  loginWithNavisToken(navisToken: string): any {
    const endpoint = environment.apiUrl + '/authenticate';

    const loginOptions = {
      headers: new HttpHeaders().set('Authorization', 'Bearer ' + navisToken),
      responseType: 'text' as 'json',
    };

    return this.httpClient.get<any>(endpoint, loginOptions);
  }

  authenticateRequest(req: HttpRequest<any>): HttpRequest<any> {
    // HttpHeader object immutable - copy values
    const headerSettings: { [name: string]: string | string[] } = {};

    for (const key of req.headers.keys()) {
      headerSettings[key] = req.headers.getAll(key);
    }

    if (!headerSettings.Authorization) {
      headerSettings.Authorization = this.authHeader;
    }

    const newHeader = new HttpHeaders(headerSettings);
    return req.clone({ headers: newHeader });
  }

  loggedIn() {
    const token: string = this.jwtHelperService.tokenGetter();
    if (!token || token === 'undefined') {
      return false;
    }

    return !this.jwtHelperService.isTokenExpired(token);
  }

  isAdmin(): boolean {
    const token = this.jwtHelperService.tokenGetter();

    if (token) {
      return this.jwtHelperService.decodeToken(token)?.role === Role.ADMIN;
    }

    return false;
  }

  isAdminOrPartner(): boolean {
    const token = this.jwtHelperService.tokenGetter();

    const role = this.jwtHelperService.decodeToken(token)?.role;

    if (token) {
      return role === Role.ADMIN || role === Role.GDS_PARTNER;
    }

    return false;
  }

  isAwaiting2FA(): boolean {
    const token = this.jwtHelperService.tokenGetter();

    if (token) {
      return this.jwtHelperService.decodeToken(token)?.role === Role.AWAITING_2FA;
    }

    return false;
  }

  loginWithTotp(totp: string): any {
    const endpoint = environment.apiUrl + '/totp-authentication';

    return this.httpClient.post<any>(endpoint, totp, { responseType: 'text' as 'json' });
  }

  getRole(): Role {
    const token = this.jwtHelperService.tokenGetter();

    if (token) {
      return this.jwtHelperService.decodeToken(token)?.role;
    }
    return undefined;
  }

  getMonthsForRole() {
    const role = this.getRole();
    if (role) {
      switch (role) {
        case 'LITE':
          return 2;
        case 'BASIS':
        case 'CUSTOMER_MANAGER':
          return 12;
        case 'PLUS':
        case 'ADMIN':
        case 'GDS_PARTNER':
        case 'DELIVERY':
          return 60;
      }
    }
    console.error(`No months configuration for role '${role}' defined.`);
    return 0;
  }

  getEarliestDateAllowedForRole(): Date {
    const currentDate = new Date();
    return new Date(currentDate.getFullYear(), currentDate.getMonth() - this.getMonthsForRole(), currentDate.getDate());
  }

  getGmsIdsForUser(): string[] {
    const token = this.jwtHelperService.tokenGetter();

    if (token) {
      return this.jwtHelperService.decodeToken(token)?.gmsIds;
    }

    return null;
  }

  getUsername(): string {
    const token = this.jwtHelperService.tokenGetter();

    if (token) {
      return this.jwtHelperService.decodeToken(token)?.sub;
    }

    return null;
  }

  getUsingCompactView(): boolean {
    const token = this.jwtHelperService.tokenGetter();

    if (token) {
      return this.jwtHelperService.decodeToken(token)?.compactView;
    }
    return false;
  }

  getUsingLightMode(): boolean {
    const token = this.jwtHelperService.tokenGetter();

    if (token) {
      return this.jwtHelperService.decodeToken(token)?.lightMode;
    }
    return false;
  }

  logout() {
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(LIGHT_MODE);
    localStorage.removeItem(COMPACT_VIEW);
  }
}
