import { NgModule } from '@angular/core';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';
import { TOKEN } from 'src/app/components/_shared/constants/local-storage-constants';

export function tokenGetter() {
  return localStorage.getItem(TOKEN);
}

@NgModule({
  imports: [
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
  ],
  providers: [AuthService, JwtHelperService],
})
export class AuthModule {}
