import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationAllowedGuardService {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate() {
    if (!this.authService.loggedIn()) {
      return true;
    } else {
      return this.router.parseUrl('');
    }
  }
}

export const isRegistrationAllowedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(RegistrationAllowedGuardService).canActivate();
};
