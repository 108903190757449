export enum GmsPageRouteEnum {
    AlertArchive = 'alerts',
    Connection = 'connection',
    Detail = 'detail',
    Documents = 'documents',
    FileUpload = 'upload',
    Messages = 'messages',
    Status = 'status',
    Reports = 'reports',
    ReportDetails = 'report-details'
}