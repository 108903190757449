import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Report} from "../models/gms/report";
import {ReportDetails} from "../models/gms/report-details";

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    private host = environment.apiUrl + '/v1/report/';

    constructor(private httpClient: HttpClient) { }

    getAll(gmsId: string): Observable<Report[]> {
        const url = this.host + gmsId + `/get-all/table-items`;
        return this.httpClient.get<Report[]>(url);
    }

    download(gmsId: string, filename: string): Observable<any> {
        const url = this.host + gmsId + `/download/` + filename;
        return this.httpClient.get<Observable<any>>(url, {responseType: 'blob' as 'json'});
    }

    getDetails(gmsId: string, reportId: string): Observable<ReportDetails> {
        const url = this.host + gmsId + `/details/` + reportId;
        return this.httpClient.get<ReportDetails>(url);
    }

    downloadDetails(gmsId: string, filename: string): Observable<any> {
        const url = this.host + gmsId + `/details/download/` + filename;
        return this.httpClient.get<Observable<any>>(url, {responseType: 'blob' as 'json'});
    }
}
