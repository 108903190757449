import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot } from '@angular/router';
import { MainService } from 'src/app/services/main.service';
import { skipWhile } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GmsListInitializedGuardService {
  constructor(private mainService: MainService) {}

  canActivate() {
    return this.mainService.gmsListInitialized$.pipe(skipWhile((isGmsListInitialized) => isGmsListInitialized === false));
  }
}

export const isGmsListInitializedGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(GmsListInitializedGuardService).canActivate();
};
