<mat-spinner *ngIf="!main.activeItem"></mat-spinner>
<div *ngIf="main.activeItem">
    <div *ngIf="main.activeItem" [ngClass]="main.activeItem.status" class="g-head g-hero">
        <div class="g-hero-text">
            <div class="text">
                <div class="logo"><img alt="Geislinger Logo" src="assets/geislinger-logo.svg"></div>
                <h3>{{main.activeItem.designation}}</h3>
                <h5 class="damper-id"><b>{{main.activeItem.projectInfo}}</b></h5>
            </div>
        </div>
        <div class="g-hero-headline"><h1>Alert Archives</h1></div>
    </div>

    <div class="columns">
        <!--    This is a copy of the date picker in the status component:-->
        <div *ngIf="gmsId" class="datepicker-box">
            <mat-form-field appearance="fill" class="slide-toggle-text">
                <mat-label>Enter a date range</mat-label>
                <mat-date-range-input [formGroup]="chartDateRange" [max]="chartDateMax" [min]="chartDateMin"
                                      [rangePicker]="picker">
                    <input #dateRangeStart formControlName="start" matStartDate placeholder="Start date">
                    <input #dateRangeEnd (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)"
                           formControlName="end"
                           matEndDate
                           placeholder="End date">
                </mat-date-range-input>
                <mat-datepicker-toggle [for]="picker" matSuffix></mat-datepicker-toggle>
                <mat-date-range-picker #picker></mat-date-range-picker>
                <mat-error *ngIf="chartDateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date
                </mat-error>
                <mat-error *ngIf="chartDateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date
                </mat-error>
            </mat-form-field>
            <div class="date-ranges">

                <span (click)="lastWeek()" class="datepicker-button">last<br>week</span>
                <span (click)="lastMonth()" class="datepicker-button">last<br>month</span>
                <span (click)="last6Months()" class="datepicker-button">last<br>six months</span>
            </div>
        </div>
    </div>


    <!--TABLE-->
    <table *ngIf="dataSource"
           [dataSource]="dataSource"
           class="mat-elevation-z8" mat-table>

        <!-- Time colummn-->
        <ng-container matColumnDef="time">
            <th *matHeaderCellDef mat-header-cell>Time</th>
            <td *matCellDef="let archive" mat-cell>{{ archive.timestamp | date :'medium' }}</td>
        </ng-container>

        <!-- API Version Column -->
        <ng-container matColumnDef="apiVersion">
            <th *matHeaderCellDef mat-header-cell>API Version</th>
            <td *matCellDef="let archive" mat-cell>{{ archive.api }}</td>
        </ng-container>

        <!-- Build Time Column -->
        <ng-container matColumnDef="buildtime">
            <th *matHeaderCellDef mat-header-cell>API Buildtime</th>
            <td *matCellDef="let archive" mat-cell>{{ archive.buildtime }}</td>
        </ng-container>

        <!-- Attachments Column -->
        <ng-container matColumnDef="attachments">
            <th *matHeaderCellDef mat-header-cell>Attachments</th>
            <td *matCellDef="let archive" mat-cell>
                <ul *ngIf="archive.attachments">
                    <li *ngFor="let attachment of archive.attachments">{{ attachment }}</li>
                </ul>
        </ng-container>

        <!-- Archive Size Column -->
        <ng-container matColumnDef="archiveSize">
            <th *matHeaderCellDef mat-header-cell>Archive Size</th>
            <td *matCellDef="let archive"
                mat-cell>
                {{archive.archiveSize | filesize }}</td> <!--({{row.archiveSize}})-->
        </ng-container>

        <!-- Archive Url Column -->
        <!-- Download Column -->
        <ng-container matColumnDef="archiveUrl">
            <th *matHeaderCellDef mat-header-cell>Download</th>
            <td *matCellDef="let archive"
                mat-cell>
                <button mat-icon-button (click)="openUrlInNewWindow(archive.archiveUrl)">
                    <mat-icon>download</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
        <tr *matRowDef="let row; columns: columnsToDisplay;" mat-row>
        </tr>

        <ng-container>
            <tr *matNoDataRow class="mat-row">
                <td class="mat-cell" colspan="6">None</td>
            </tr>
        </ng-container>
    </table>
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" aria-label="Select page of alert archives" showFirstLastButtons>
    </mat-paginator>
</div>
