<div class="g-head g-hero">
    <div class="g-hero-text">
        <div class="text">
            <div class="logo"><img src="assets/geislinger-logo.svg"></div>
        </div>
    </div>
    <div class="g-hero-headline"><h1>Certificates</h1></div>
</div>

<div class="g-head g-form">
    <h3 class="g-headline">Create a new certificate</h3>
    <form [formGroup]="createCertForm">
        <mat-form-field>
            <mat-label>CP (GMS ID)</mat-label>
            <input matInput
                    type="text"
                    formControlName="gmsId"
                    required>
            <mat-error>
                Please provide a valid CP (= GMS ID) value. Must exactly have 8 digits.
            </mat-error>
        </mat-form-field>

        <button class="g-button g-form-main-button" [ngClass]="!createCertForm.valid ? 'g-disabled':''"
                [disabled]="!createCertForm.valid" (click)="createCert()">
            Submit
        </button>
        <div class="g-status warn error" *ngIf="createCertError">ERROR: {{createCertError}}</div>
    </form>
</div>
<div class="main-container">
    <h3 class="g-sub-headline">Existing certificates</h3>
    <mat-form-field>
        <mat-label>Search</mat-label>
        <input matInput (keyup)="applyFilterOnGmsCertList($event)" #filterInput>
    </mat-form-field>
    <mat-table [dataSource]="certsDataSource">
        <ng-container matColumnDef="certId">
            <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let cert">{{cert.gmsId}}-{{cert.counter | number:'2.0'}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="gmsId">
            <mat-header-cell *matHeaderCellDef>CP</mat-header-cell>
            <mat-cell *matCellDef="let cert">{{cert.gmsId}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let cert">{{cert.gmsName}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
            <mat-cell *matCellDef="let cert">{{cert.status}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="download">
            <mat-header-cell *matHeaderCellDef>Download</mat-header-cell>
            <mat-cell *matCellDef="let cert">
                <button *ngIf="cert.status === 'ACTIVE'" mat-icon-button (click)="downloadCertFiles(cert, 'key')">
                    <mat-icon>file_download</mat-icon>
                </button>
                <button *ngIf="cert.status !== 'ACTIVE'" mat-icon-button disabled>
                    <mat-icon>file_download_off</mat-icon>
                </button>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="topics">
            <mat-header-cell *matHeaderCellDef>Topics</mat-header-cell>
            <mat-cell *matCellDef="let cert">{{cert.topics}}
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updatedOn">
            <mat-header-cell *matHeaderCellDef>Updated On</mat-header-cell>
            <mat-cell *matCellDef="let cert"> {{cert.updatedOn | date:"yyyy-MM-dd HH:mm"}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="updatedBy">
            <mat-header-cell *matHeaderCellDef>Updated By</mat-header-cell>
            <mat-cell *matCellDef="let cert"> {{cert.updatedBy}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="disableOrDelete">
            <mat-header-cell *matHeaderCellDef>Disable/Delete</mat-header-cell>
            <mat-cell *matCellDef="let cert">
            <ng-container *ngIf="auth.isAdmin()">
                <button *ngIf="cert.status === 'ACTIVE'"
                        mat-raised-button
                        color="accent"
                        (click)="openDisableCertDialog(cert)">
                    Disable
                </button>
                <button *ngIf="cert.status !== 'ACTIVE'"
                        mat-raised-button
                        matTooltip="This operation removes the certificate from this list."
                        (click)="openDeleteCertDialog(cert)">
                    Delete
                </button>
            </ng-container>
                <ng-container *ngIf="!auth.isAdmin()">
                    <div matTooltip="Only ADMINs can disable or delete certificates.">
                    <button *ngIf="cert.status === 'ACTIVE'"
                            mat-raised-button
                            color="accent"
                            disabled
                            (click)="openDisableCertDialog(cert)">
                        Disable
                    </button>
                    <button *ngIf="cert.status !== 'ACTIVE'"
                            mat-raised-button
                            disabled
                            (click)="openDeleteCertDialog(cert)">
                        Delete
                    </button>
                    </div>
                </ng-container>
            </mat-cell>
        </ng-container>

        <!-- Row shown when there is no matching data. -->
        <ng-template>
        <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">There are no certificates yet</td>
        </tr>
        </ng-template>

        <mat-header-row *matHeaderRowDef="certColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: certColumns;"></mat-row>
    </mat-table>
    <div class="g-padding-content"></div>
</div>
