import { Component, Input } from '@angular/core';
import { MainService } from '../../../services/main.service';
import { Router } from '@angular/router';
import { GmsContext } from '../../../models/gms/gms-context';
import { GmsStatus } from '../../../models/gms/gms-status.enum';

@Component({
  selector: 'app-ship-classic',
  templateUrl: './ship-classic.component.html',
  styleUrls: ['../overview.component.css', './ship-classic.component.css'],
})
export class ShipClassicComponent {
  @Input() ship: GmsContext;
  @Input() isUsingLightMode = false;

  constructor(public main: MainService, public router: Router) {}

  navigate(id: string, page: string) {
    this.main.setActiveById(id);
    this.router.navigate([
      '/' + id + '/' + (page === 'status' ? '0/' : '') + page,
    ]);
  }

  getEngineMaintenanceBarWidthPercentage(maintenanceBarValue: number): string {
    maintenanceBarValue = maintenanceBarValue * 100;
    if (maintenanceBarValue >= 0 && maintenanceBarValue <= 100) {
      return `${maintenanceBarValue}%`;
    }
    return maintenanceBarValue > 100 ? '100%' : '0%';
  }

  protected readonly GmsStatus = GmsStatus;
}
