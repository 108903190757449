import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";


export function mustMatch(controlName: string, matchingControlName: string, match: boolean): ValidationErrors | null {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.get(controlName);
        const matchingControl = formGroup.get(matchingControlName);

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            return;
        }

        if ((control.value && matchingControl.value && control.value === matchingControl.value) !== match) {
            matchingControl.setErrors({mustMatch: true});
        }
    };
}
export function checkPasswordRequirements(): ValidatorFn {
    const passwordRegEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^(a-zA-Z0-9)]).{8,}");
    return control => {
        return passwordRegEx.test(control.value) && !control.value.includes(' ') ? null : {requirementsNotFulfilled: true};
    }
}