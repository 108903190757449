import {Component, Input} from '@angular/core';
import {GmsContext} from "../../../models/gms/gms-context";
import {MainService} from "../../../services/main.service";
import {Router} from "@angular/router";
import {GmsStatus} from "../../../models/gms/gms-status.enum";

@Component({
    selector: 'app-ship-compact',
    templateUrl: './ship-compact.component.html',
    styleUrls: ['../overview.component.css', './ship-compact.component.css']
})
export class ShipCompactComponent {

    @Input() ship: GmsContext;

    constructor(public main: MainService,
                public router: Router) {
    }

    navigate(id: string, page: string) {
        this.main.setActiveById(id);
        this.router.navigate(['/' + id + '/' + (page === 'status' ? '0/' : '') + page]);
    }

    protected readonly GmsStatus = GmsStatus;
}
