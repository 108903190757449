import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {MainService} from '../../services/main.service';
import {Role} from '../../models/user/role.enum';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
    public expandItems = false;

    constructor(public router: Router,
                public authService: AuthService,
                public main: MainService) {
    }

    ngOnInit() {
        this.main.initGmsList();
    }

    getCurrentUsername(): string {
        return this.authService.getUsername();
    }

    isAdmin(): boolean {
        return this.authService.isAdmin();
    }

    isAdminOrPartner(): boolean {
        return this.authService.isAdminOrPartner();
    }

    showAdminPanel(): boolean {
        return this.authService.getRole() === Role.ADMIN ||
            this.authService.getRole() === Role.GDS_PARTNER ||
            this.authService.getRole() === Role.CUSTOMER_MANAGER;
    }

    showCertificatePanel(): boolean {
        return this.authService.getRole() === Role.ADMIN ||
            this.authService.getRole() === Role.DELIVERY;
    }

    showGmsLoadingSpinner(): boolean {
        return !(this.main.gmsList?.length
            || this.main.favoriteGmsList?.length
            || !(this.main.loadingGms || this.main.loadingFavorites));
    }

    navigate(gmsId: string, page: string, engineIndex?: number) {
        this.main.setActiveById(gmsId, engineIndex);
        if (engineIndex !== undefined && engineIndex >= 0 && engineIndex <= 4) {
            this.router.navigate(['/' + gmsId + '/' + engineIndex + '/' + page]);
        } else {
            this.router.navigate(['/' + gmsId + '/' + page]);
        }
    }

  logout() {
    this.main.setLightMode(false);
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
