<div class="logo" routerLink="/"><img src="assets/geislinger-logo.svg" alt="logo"></div>
<div class="logged-in-as">
    Logged in as: <br>
    <b>{{getCurrentUsername()}}</b>
</div>
<div class="logout">
    <mat-icon class="settings-link" routerLink="/settings" matTooltip="Settings">settings
    </mat-icon>

    <ng-container *ngIf="showAdminPanel()">
        <mat-icon class="admin-panel-link" routerLink="/admin" matTooltip="Admin Panel">
            admin_panel_settings
        </mat-icon>
    </ng-container>
    <ng-container *ngIf="showCertificatePanel()">
        <mat-icon class="certificates-panel-link" [ngClass]="{'after-admin-panel': showAdminPanel()}"
                  routerLink="/certificates" matTooltip="Certificate Panel">
            collections_bookmark
        </mat-icon>
    </ng-container>

    <span class="text-icon" *ngIf="authService.loggedIn()" (click)="logout()"><mat-icon>person_off</mat-icon> logout</span>
</div>
<div>
    <mat-spinner *ngIf="showGmsLoadingSpinner()"></mat-spinner>
    <ng-container *ngIf="!showGmsLoadingSpinner()">
        <h6 routerLink="/overview">Fleet</h6>

        <div>
            <a routerLink="/all-devices">
                <button type="button" class="g-button nav-button nav-icon-button mat-small">
                    <mat-icon class="all-devices-icon">search_icon</mat-icon>
                    All Devices
                </button>
            </a>
        </div>
        <div *ngIf="!isAdminOrPartner()">
            <a routerLink="/gms-access/request">
                <button type="button" class="g-button nav-button mat-small">
                    Request GMS Access
                </button>
            </a>
        </div>

        <span *ngFor="let ship of (main.favoriteGmsList.length > 0
        ? main.temporaryFavorite
            ? [main.temporaryFavorite].concat(main.favoriteGmsList)
            : main.favoriteGmsList
        : main.gmsList)"
              [ngClass]="ship.status" [class.activeShip]="ship.gmsId === main.activeItem?.gmsId">
            <div class="menu-item" (click)="navigate(ship.gmsId, 'detail'); expandItems = false">
                <div class="icon">
                    <ng-container *ngIf="ship.status!=='ALARM'; else alarm"><app-icon
                            [name]="ship.iconInfo.toLowerCase()"></app-icon></ng-container>
                    <ng-template #alarm><app-icon [name]="'ALARM'"></app-icon></ng-template>
                </div>
                <div class="text"><div class="name">{{ship.name}}</div>
                    <div class="call-sign">{{ship.gmsId}}</div>
                </div>
            </div>
            <ng-container *ngIf="ship.gmsId === main.activeItem?.gmsId">
                <div class="multi-items-box" *ngIf="ship.statusPerEngine.length > 1; else singleShip">
                    <div class="menu-item child expandable" (click)="expandItems = !expandItems">
                        <span class="expand-icons"><mat-icon *ngIf="!expandItems">expand_more</mat-icon><mat-icon
                                *ngIf="expandItems">expand_less</mat-icon></span>
                        Powertrain
                    </div>
                    <div class="multi-items" [class.expanded-items]="expandItems">
                        <div *ngFor="let engine of ship.statusPerEngine"
                             class="menu-item child" (click)="navigate(ship.gmsId, 'status', engine.engineIndex)">
                        {{engine.installedProducts}}
                    </div>
                    </div>
                </div>
                <ng-template #singleShip>
                    <div class="menu-item child"
                         (click)="navigate(ship.gmsId, 'status', ship.statusPerEngine[0].engineIndex)">
                        {{ship.statusPerEngine[0].installedProducts}}
                    </div>
                </ng-template>

                <div class="menu-item child" *ngIf="ship.pushType === 'cloudPush'"
                     (click)="navigate(ship.gmsId, 'connection')">Connection</div>
                        <div class="menu-item child" *ngIf="ship.pushType === 'manualPush'"
                             (click)="navigate(ship.gmsId, 'upload')">Upload</div>
                <div class="menu-item child" (click)="navigate(ship.gmsId, 'messages')">
                    <span class="count"
                          *ngIf="main.activeItem.unreadMessagesCount>0">{{main.activeItem.unreadMessagesCount}}
                    </span>
                    Messages
                </div>
                <div class="menu-item child" (click)="navigate(ship.gmsId, 'documents')">
                    <span class="count"
                          *ngIf="main.activeItem.unreadDocumentsCount>0">{{main.activeItem.unreadDocumentsCount}}
                    </span>
                    Documents
                </div>
                <div *ngIf="isAdmin()">
                    <div (click)="navigate(ship.gmsId, 'alerts')" class="menu-item child">
                        Alerts
                    </div>
                </div>
                <div class="menu-item child" (click)="navigate(ship.gmsId, 'reports')">
                    Reports
                </div>
            </ng-container>
        </span>

        <!--    <div class="menu-item" style="position: absolute;bottom:5em" routerLink="/upload">-->
        <!--        <span class="text-icon"><mat-icon>upload</mat-icon> File Upload</span>-->
        <!--    </div>-->

    </ng-container>
</div>
