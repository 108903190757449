import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import {AlertArchive} from "../models/gms/alert-archive";

@Injectable({
    providedIn: 'root'
})
export class AlertArchiveService {
    private host = environment.apiUrl + '/v1/alert-archive/';
    constructor(private httpClient: HttpClient) { }

    public getAlertArchives(gmsId: string, start: Date, end:Date) {
        const url = this.host + gmsId + '/get-all';

        const params = new HttpParams()
            .append('start', start.toISOString())
            .append('end', end.toISOString());

        return this.httpClient.get<AlertArchive[]>(url, {params});
    }
}
