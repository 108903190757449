import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {Document} from '../models/gms/document';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private host = environment.apiUrl + '/v1/document/';

  constructor(private httpClient: HttpClient) { }

  getAll(gmsId: string): Observable<Document[]> {
    const url = this.host + gmsId + `/get-all`;
    return this.httpClient.get<Document[]>(url);
  }

  download(gmsId: string, filename: string): Observable<any> {
    const url = this.host + gmsId + `/download/` + filename;
    return this.httpClient.get<Observable<any>>(url, {responseType: 'blob' as 'json'});
  }

  upload(gmsId: string, file: File, title: string): Observable<Document[]> {
    const endpoint = this.host + gmsId + '/upload?title=' + title;

    const fd = new FormData();
    fd.append('file', file, file.name);

    return this.httpClient.post<Document[]>(endpoint, fd);
  }

  delete(gmsId: string, filename: string): Observable<any> {
    const url = this.host + gmsId + `/delete/` + filename;
    return this.httpClient.delete<any>(url);
  }
}
