import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AlertArchive} from "../../models/gms/alert-archive";
import {AlertArchiveService} from "../../services/alert-archive.service";
import {ActivatedRoute, Router} from "@angular/router";
import {MainService} from "../../services/main.service";
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from "@angular/material/paginator";
import {UntypedFormBuilder, UntypedFormGroup} from "@angular/forms";
import {GmsInfoDirective} from "../_shared/gms-info/gms-info.directive";

@Component({
    selector: 'app-alert-archive',
    templateUrl: './alert-archive.component.html',
    styleUrls: ['./alert-archive.component.css']
})
export class AlertArchiveComponent extends GmsInfoDirective implements OnInit {
    alertArchives: AlertArchive[] = [];
    columnsToDisplay = ['time', 'apiVersion', 'buildtime', 'attachments', 'archiveSize', 'archiveUrl'];
    dataSource: MatTableDataSource<AlertArchive>;
    chartDateRange: UntypedFormGroup;
    chartDateMin: Date;
    chartDateMax: Date;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private route: ActivatedRoute,
                private alertArchiveService: AlertArchiveService,
                public main: MainService,
                private fb: UntypedFormBuilder,
                public router: Router) {
        super(main, router);
    }

    ngOnInit(): void {
        super.ngOnInit();

        const currentDate = new Date();
        this.chartDateMin = new Date(currentDate.getFullYear(), currentDate.getMonth() - 6, currentDate.getDate());
        this.chartDateMax = currentDate;

        this.chartDateRange = this.fb.group({
            start: [undefined, []],
            end: [undefined, []],
        });

        let gmsId = this.route.snapshot.paramMap.get('gmsId');
        this.gmsId = gmsId;

        this.main.setActiveById(gmsId);

        this.lastWeek();
    }

    openUrlInNewWindow(url) {
        window.open(url, '_blank');
    }

    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        if (dateRangeStart.value && dateRangeEnd.value) {
            const startDateWithoutTime = new Date(dateRangeStart.value);
            const endDateWithoutTime = new Date(dateRangeEnd.value);
            const startDate = new Date(Date.UTC(startDateWithoutTime.getFullYear(), startDateWithoutTime.getMonth(),
                startDateWithoutTime.getDate(),
                0, 0, 0));
            const endDate = new Date(Date.UTC(endDateWithoutTime.getFullYear(), endDateWithoutTime.getMonth(), endDateWithoutTime.getDate(),
                23, 59, 59));

            this.loadAndShowArchives(startDate, endDate);
        }
    }

    loadAndShowArchives(startDate: Date, endDate: Date) {
        this.alertArchiveService.getAlertArchives(this.gmsId, startDate, endDate)
            .subscribe(alertArchives => {
                this.alertArchives = alertArchives;
                this.dataSource = new MatTableDataSource(this.alertArchives);
                this.dataSource.paginator = this.paginator;
            });
    }

    lastWeek() {
        const endDate = new Date();
        const startDate = new Date(endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate() - 7,
            endDate.getHours(),
            endDate.getMinutes(),
            endDate.getSeconds());

        this.chartDateRange.patchValue({start: startDate, end: endDate});
        this.loadAndShowArchives(startDate, endDate);
    }


    lastMonth() {
        const endDate = new Date();
        const startDate = new Date(endDate.getFullYear(),
            endDate.getMonth() - 1,
            endDate.getDate(),
            endDate.getHours(),
            endDate.getMinutes(),
            endDate.getSeconds());

        this.chartDateRange.patchValue({start: startDate, end: endDate});
        this.loadAndShowArchives(startDate, endDate);
    }

    last6Months() {
        const endDate = new Date();
        const startDate = new Date(endDate.getFullYear(),
            endDate.getMonth() - 6,
            endDate.getDate(),
            endDate.getHours(),
            endDate.getMinutes(),
            endDate.getSeconds());

        this.chartDateRange.patchValue({start: startDate, end: endDate});
        this.loadAndShowArchives(startDate, endDate);
    }
}
