import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {GmsCert} from '../models/gms/gms-cert';

@Injectable({
  providedIn: 'root'
})
export class GmsCertService {

  private host = environment.apiUrl + '/v1/gms-cert/';

  constructor(private httpClient: HttpClient) { }

  getAll(): Observable<GmsCert[]> {
    const url = this.host + `get-all`;
    return this.httpClient.get<GmsCert[]>(url);
  }

  generateCertificate(gmsId: string): Observable<GmsCert[]> {
    const url = this.host + `generate/` + gmsId;
    return this.httpClient.post<GmsCert[]>(url, null);
  }

  revokeCertificate(gmsId: string, counter: number): Observable<GmsCert[]> {
    const url = this.host + `revoke/` + gmsId + '/' + counter;
    return this.httpClient.post<GmsCert[]>(url, null);
  }

  deleteCertificate(gmsId: string, counter: number): Observable<GmsCert[]> {
    const url = this.host + `delete/` + gmsId + '/' + counter;
    return this.httpClient.post<GmsCert[]>(url, null);
  }

  // type is either "key" (then the key is returned) or something else (then the certificate as .pem is returned)
  download(gmsId: string, counter: number, type: string): Observable<any> {
    const url = this.host + `download/` + gmsId + '/' + counter + '/' + type;
    return this.httpClient.get<Observable<any>>(url, {responseType: 'blob' as 'json'});
  }

}
