<div>
  <div *ngIf="main.activeItem" [ngClass]="main.activeItem.status" class="g-head g-hero">
    <div class="g-hero-text">
      <div class="text">
        <div class="logo"><img alt="Geislinger Logo" src="assets/geislinger-logo.svg" /></div>
        <h3>{{ main.activeItem.designation }}</h3>
        <h5 class="damper-id">
          <b>{{ main.activeItem.projectInfo }}</b>
        </h5>
      </div>
    </div>
    <div class="g-hero-headline"><h1>Report Details</h1></div>
  </div>

  <mat-spinner *ngIf="isLoadingData"></mat-spinner>

  <div *ngIf="reportDetails" class="g-container">
    <div class="date-container">
      <div class="g-card">
        <mat-icon>calendar_month</mat-icon>
        <div class="date-text">
          <h6>Publish Date</h6>
          <h4>{{ dateUtils.formatAsDateOnly(reportDetails.publishedDate, ' / ') }}</h4>
        </div>
        <div class="date-text">
          <h6>Period</h6>
          <h4>{{ dateUtils.formatAsDateOnly(reportDetails.fromDate, ' / ') }} - {{ dateUtils.formatAsDateOnly(reportDetails.toDate, ' / ') }}</h4>
        </div>
      </div>
    </div>

    <div class="engine-container" *ngFor="let engineReport of reportDetails.engineReports; let i = index">
      <div class="engine-info-container">
        <div class="g-card followed-by-table">
          <h4>{{ engineReport.designation }}</h4>
          <div class="engine-info">
            <div class="engine-info-line">
              <span class="damper-health" [ngClass]="getStatusByPercentageValue(engineReport.damperHealth)">
                <app-status-icon class="symbol" [input]="getStatusByPercentageValue(engineReport.damperHealth)"></app-status-icon>
              </span>
              <span class="engine-info-text">Health</span>
              <span class="engine-info-value">{{ numberUtils.formatAsPercentage(engineReport.damperHealth, 1) }}</span>
            </div>
            <div class="engine-info-line">
              <ng-container>
                <div class="status" [ngClass]="getStatusByPercentageValue(engineReport.confidence)">
                  <app-status-icon class="symbol" [input]="getStatusByPercentageValue(engineReport.confidence)"></app-status-icon>
                </div>
              </ng-container>

              <span class="engine-info-text">Confidence</span>
              <span class="engine-info-value">{{ numberUtils.formatAsPercentage(engineReport.confidence, 1) }}</span>
            </div>
            <div class="details-download">
              <a href="#" (click)="download(engineReport)"> <mat-icon class="download">download</mat-icon>Download Details </a>
            </div>
          </div>
        </div>
      </div>

      <!-- dynamic table -->
      <mat-table *ngIf="engineReport.data" [dataSource]="engineReport.data">
        <ng-container *ngFor="let column of engineReport.columns; let i = index" matColumnDef="{{ column }}">
          <mat-header-cell *matHeaderCellDef>{{ column }}</mat-header-cell>
          <mat-cell *matCellDef="let data">{{ data[i] }}</mat-cell>
        </ng-container>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data</td>
        </tr>

        <mat-header-row *matHeaderRowDef="engineReport.columns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: engineReport.columns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
<div *ngIf="error">
  <div *ngIf="error === 'NotFound'" class="g-head g-hero">Es existieren keine Details zu diesem Report.</div>
  <div *ngIf="error === 'Unknown'" class="g-head g-hero">Fehler bei der Abfrage der Details zu diesem Report.</div>
</div>
