import { Component, OnInit } from '@angular/core';
import { MainService } from '../../services/main.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-overview2',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css'],
})
export class OverviewComponent implements OnInit {
  isUsingCompactView = false;
  isUsingLightMode = false;

  constructor(public main: MainService, public router: Router, public authService: AuthService) {}

  ngOnInit() {
    this.main.lightMode$.subscribe((lightMode) => (this.isUsingLightMode = lightMode));
    this.isUsingCompactView = this.main.compactView;
  }

  showGmsLoadingSpinner(): boolean {
    return !(this.main.gmsList?.length || this.main.favoriteGmsList?.length || !(this.main.loadingGms || this.main.loadingFavorites));
  }
}
