<div class="g-hero">
    <div class="g-hero-text">
        <h1>Geislinger Analytics Platform</h1>
    </div>
    <div class="g-hero-img sujet"></div>
</div>
<div class="g-head">
    <form [formGroup]="totpForm" style="margin-bottom: var(--padding)">
        <h2 style="margin-bottom: var(--padding)">Two Factor Authentication</h2>
        Please enter the one-time password from your authenticator.
        <div style="margin: var(--padding-half) 0">
            <mat-form-field class="full-width-input">
                <mat-label>One-time password</mat-label>
                <input formControlName="totp"
                        matInput
                        required type="text">
                <mat-error>
                    Please provide a valid one-time password
                </mat-error>
            </mat-form-field>
        </div>

        <div *ngIf="error !== null" class="g-status warn"><b>One-time password failed.</b></div>

        <div>
            <button (click)="sendTotp()" class="g-button g-form-main-button">
                Submit
            </button>
        </div>
    </form>
</div>
