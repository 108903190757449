import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable} from 'rxjs';
import {ChartData} from '../models/gms/chart-data';
import {AppStatusData} from '../models/gms/app-status-data';
import {ConnectionStatusData} from '../models/gms/connection-status-data';

@Injectable({
  providedIn: 'root'
})
export class GmsChartService {

  private host = environment.apiUrl + '/v1/gms-chart/';

  constructor(private httpClient: HttpClient) { }

  getStatusChart(gmsId: string, engineIndex: number, start: Date, end: Date): Observable<ChartData> {
    const url = this.host + `status/` + gmsId + `/` + engineIndex;
    const params = new HttpParams().set('start', start.toISOString()).set('end', end.toISOString());
    return this.httpClient.get<ChartData>(url, {params});
  }

  getAppStatus(gmsId: string, engineIndex: number, start: Date, end: Date): Observable<AppStatusData[]> {
    const url = this.host + `app-status/` + gmsId + `/` + engineIndex;
    const params = new HttpParams().set('start', start.toISOString()).set('end', end.toISOString());
    return this.httpClient.get<AppStatusData[]>(url, {params});
  }

  getConnectionStatus(gmsId: string, start: Date, end: Date): Observable<ConnectionStatusData> {
    const url = this.host + `connection-status/` + gmsId;
    const params = new HttpParams().set('start', start.toISOString()).set('end', end.toISOString());
    return this.httpClient.get<ConnectionStatusData>(url, {params});
  }

  downloadStatusChartAsCsv(gmsId: string, engineIndex: number, start: Date, end: Date) {
    const url = this.host + `status/download/` + gmsId + `/` + engineIndex;
    const params = new HttpParams().set('start', start.toISOString()).set('end', end.toISOString());
    return this.httpClient.get(url, {params, responseType: 'blob'});
  }
}
