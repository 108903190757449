<div *ngIf="main.activeItem">
    <div class="g-hero">
        <div class="g-hero-text">
            <div class="g-hero-text-detail">
                <div class="g-icon-text-horizontal">
                    <div class="icon">
                        <app-icon [name]="main.activeItem.iconInfo.toLowerCase()"></app-icon>
                    </div>
                    <div class="text">
                        <h4 class="name">{{main.activeItem.name | slice:0:33}}</h4>
                        <div class="call-sign">{{main.activeItem.gmsId}}</div>
                    </div>
                </div>
                <div class="g-hero-text-headline">
                    <div class="text">
                        <div class="logo"><img src="assets/geislinger-logo.svg"></div>
                        <h1>{{main.activeItem.designation}}</h1>
                        <h5 class="damper-id"><b>{{main.activeItem.projectInfo}}</b></h5>
                    </div>
                </div>
            </div>
        </div>
        <div class="g-hero-img"></div>
    </div>
</div>

<div>
    <ng-container *ngIf="main.activeItem">
        <ng-container *ngFor="let engine of main.activeItem.statusPerEngine" [class.end-of-life]="engine.maintenanceBar>=0.75">
    <div class="g-head" *ngIf="engine.druidDatasource.includes('damper-analysis') || engine.druidDatasource.includes('coupling-analysis')">
        <div class="maintenance-container">
            <div>
                <app-icon [name]="'damper'"></app-icon>
                <div>{{engine.installedProducts}}</div>
            </div>
            <div class="g-number text-below right-to-left in-operation">
                <div class="g-number-text">
                    <div class="g-number-integer">{{engine.hoursInOperation | number:'.0-0'}}</div>
                    <div class="g-number-unit">hours</div>
                </div>
                <div class="g-number-description">in operation</div>
            </div>
            <div class="maintenance">
                <div class="maintenance-bar" [ngStyle]="{'width' : engine.maintenanceBar * 100 + '%'}"></div>
            </div>
            <div class="g-number text-below timeUntilNextMaint" *ngIf="engine.nextMaintenance">
                <div class="g-number-text">
                    <div class="g-number-integer">{{main.timeUntil(engine.nextMaintenance, 'future')[0]}}</div>
                    <div class="g-number-unit">{{main.timeUntil(engine.nextMaintenance, 'future')[1]}}</div>
                </div>
                <div class="g-number-description">next maintenance</div>
            </div>
        </div>
    </div>
        </ng-container>
    </ng-container>

    <div class="g-container">
        <div class="g-columns">
            <ng-container *ngIf="main.activeItem">
                <div *ngFor="let engine of main.activeItem.statusPerEngine" class="g-column-on-top">
                    <div class="g-card chart status" [ngClass]="engine.status">
                        <div class="g-icon-text-horizontal big"
                             (click)="navigate(main.activeItem.gmsId, 'status', engine.engineIndex)">
                            <app-status-icon class="icon" [input]="engine.status"></app-status-icon>
                            <div class="text">
                                <h3 class="name">{{engine.installedProducts}}</h3>
                                <h4 class="text" *ngIf="statusChart[engine.engineIndex] || statusChartIsLoading[engine.engineIndex]">{{engine.statusDetail}}</h4>
                            </div>
                        </div>
                        <app-highchart *ngIf="statusChart[engine.engineIndex]" [options]="statusChart[engine.engineIndex]"
                                       style="width: 300px; min-height: 100px"></app-highchart>
                        <div class="g-number g-number-text g-number-description"
                             *ngIf="!statusChart[engine.engineIndex] && !statusChartIsLoading[engine.engineIndex]">
                            No status data available.
                        </div>
                        <mat-spinner *ngIf="!statusChart[engine.engineIndex] && statusChartIsLoading[engine.engineIndex]"></mat-spinner>
                    </div>
                </div>
                <div class="g-card chart connection"
                     [ngClass]="main.getConnectionDescription(main.activeItem.connectionQuality, main.activeItem.pushType)">
                    <div class="g-icon-text-horizontal big" (click)="navigate(main.activeItem.gmsId, 'connection')">
                        <app-connection-icon class="icon"
                                             [input]="main.getConnectionDescription(main.activeItem.connectionQuality, main.activeItem.pushType)"></app-connection-icon>
                        <div class="text">
                            <h3 class="name">Connection</h3>
                            <h4 class="text">{{main.getConnectionDescription(main.activeItem.connectionQuality, main.activeItem.pushType)}}</h4>
                        </div>
                    </div>
                    <ng-container *ngIf="main.activeItem.pushType !== 'manualPush'; else lastUpload">
                        <app-highchart *ngIf="connectionChart" class="sparkline" [options]="connectionChart"
                                       style="width: 300px; height: 100px"></app-highchart>
                        <div class="g-number g-number-text g-number-description"
                             *ngIf="!connectionChart && !connectionChartIsLoading">
                            No connection data available within the last two weeks.
                        </div>
                        <mat-spinner *ngIf="!connectionChart && connectionChartIsLoading"></mat-spinner>
                    </ng-container>
                    <ng-template #lastUpload>
                        <div class="g-number" *ngIf="main.activeItem.lastUpdated">
                            <div class="g-number-integer">{{main.timeUntil(main.activeItem.lastUpdated)[0]}}</div>
                            <div class="g-number-text">
                                <div class="g-number-description">last upload</div>
                                <div class="g-number-unit">{{main.timeUntil(main.activeItem.lastUpdated)[1]}}ago
                                </div>
                            </div>
                        </div>
                        <div class="g-number g-number-text g-number-description"
                             *ngIf="!main.activeItem.lastUpdated">
                            There is no uploaded data yet.
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </div>
        <div *ngIf="main.activeItem" class="g-columns small-big-collum">
            <div class="small">
                <div class="g-card" *ngIf="main.activeItem.lastConnected">
                    <div class="g-number">
                        <app-icon [name]="'upload'"></app-icon>
                        <div class="g-number-integer">{{main.timeUntil(main.activeItem.lastConnected)[0]}}</div>
                        <div class="g-number-text">
                            <div class="g-number-description">last connection</div>
                            <span class="g-number-unit">{{main.timeUntil(main.activeItem.lastConnected)[1]}} </span>ago
                        </div>
                    </div>
                </div>
                <div class="g-card" *ngIf="main.activeItem.lastUpdated">
                    <div class="g-number">
                        <app-icon [name]="'upload'"></app-icon>
                        <div class="g-number-integer">{{main.timeUntil(main.activeItem.lastUpdated)[0]}}</div>
                        <div class="g-number-text">
                            <div class="g-number-description">last update</div>
                            <span class="g-number-unit">{{main.timeUntil(main.activeItem.lastUpdated)[1]}} </span>ago
                        </div>
                    </div>
                </div>
                <div class="g-card" (click)="navigate(main.activeItem.gmsId, 'messages/alarm')">
                    <div class="g-number">
                        <app-icon [name]="'ALARM'"></app-icon>
                        <div class="g-number-integer"
                             *ngIf="main.activeItem.lastAlarm">{{main.timeUntil(main.activeItem.lastAlarm)[0]}}</div>
                        <div class="g-number-text">
                            <div class="g-number-description">last alarm</div>
                            <ng-container *ngIf="main.activeItem.lastAlarm"><span
                                    class="g-number-unit">{{main.timeUntil(main.activeItem.lastAlarm)[1]}} </span>ago
                            </ng-container>
                            <ng-container *ngIf="!main.activeItem.lastAlarm"><span class="g-number-unit">None</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="g-card" (click)="navigate(main.activeItem.gmsId, 'messages/warning')">
                    <div class="g-number">
                        <app-icon [name]="'WARNING'"></app-icon>
                        <div class="g-number-integer"
                             *ngIf="main.activeItem.lastWarning">{{main.timeUntil(main.activeItem.lastWarning)[0]}}</div>
                        <div class="g-number-text">
                            <div class="g-number-description">last warning</div>
                            <ng-container *ngIf="main.activeItem.lastWarning"><span
                                    class="g-number-unit">{{main.timeUntil(main.activeItem.lastWarning)[1]}} </span>ago
                            </ng-container>
                            <ng-container *ngIf="!main.activeItem.lastWarning"><span class="g-number-unit">None</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="g-card chart big">
                <h3 class="name">Anomaly Detection</h3>
                <app-highchart *ngIf="anomalyChart[0]" [id]="anomalyChart[0].id" class="" [options]="anomalyChart[0]"
                               style="min-width: 300px; min-height: 400px"></app-highchart>
                <mat-spinner *ngIf="!anomalyChart[0] && statusChartIsLoading[0]"></mat-spinner>
                <div class="g-number g-number-text g-number-description" *ngIf="!anomalyChart[0] && !statusChartIsLoading[0]">
                    No data available.
                </div>
            </div>
        </div>
    </div>
</div>
