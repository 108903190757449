<div class="g-hero">
    <div class="g-hero-text">
        <h1>Geislinger Analytics Platform</h1>
    </div>
    <div class="g-hero-img sujet"></div>
</div>
<div class="g-head">
    <form style="margin-bottom: var(--padding)" [formGroup]="forgotPasswordForm">
        <h2 style="margin-bottom: var(--padding)">Password reset</h2>
        Please enter your email address and we will send you a link for resetting your password.
        <div style="margin: var(--padding-half) 0">
            <mat-form-field class="full-width-input">
                <mat-label>Email address</mat-label>
                <input matInput
                  type="text"
                  autocomplete="username"
                  formControlName="username" required>
                <mat-error>
                    Please provide a valid email address
                </mat-error>
            </mat-form-field>
        </div>

        <div>
            <button class="g-button g-form-main-button" [ngClass]="!forgotPasswordForm.valid ? 'g-disabled':''" [disabled]="!forgotPasswordForm.valid" (click)="requestPasswordReset()">
                Submit
            </button>
        </div>
    </form>

    <mat-toolbar *ngIf="success">
        <h2 routerLink="/login">Please check your mail inbox.</h2>
    </mat-toolbar>

    <div *ngIf="error">
        <mat-toolbar color="warn">
            <h2>ERROR: {{error}}</h2>
        </mat-toolbar>
    </div>
</div>
