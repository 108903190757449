export const VesselOrMarineApplicationTypes: string[] = [
    "Commercial Vessel",
    "Ferry",
    "Yacht"
]

export const OtherApplicationTypes: string[] = [
    "Power Generation",
    "Cement Mill",
    "Rail",
    "Testbed"
]