import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {User} from '../../models/user/user';
import {UserService} from '../../services/user.service';
import {GmsAccessGrant} from '../../models/gms/access/gms-access-grant';
import {Role} from '../../models/user/role.enum';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators} from '@angular/forms';
import {GmsService} from '../../services/gms.service';
import {Gms} from '../../models/gms/gms';
import {Clipboard} from '@angular/cdk/clipboard';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MessageTemplate} from '../../models/gms/message-template';
import {MessageTemplateService} from '../../services/message-template.service';
import {MainService} from '../../services/main.service';
import {Message, MessageType} from '../../models/gms/message';
import {Severity} from '../../models/gms/severity.enum';
import {MessageService} from '../../services/message.service';
import {MatTableDataSource} from '@angular/material/table';
import {AuthService} from "../../auth/auth.service";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {GmsNonClassified} from "../../models/gms/gms-non-classified";
import {GmsAccessService} from "../../services/gms-access.service";
import {GmsAccessRequestTableItem} from "../../models/gms/access/gms-access-request-table-item";
import {Router} from "@angular/router";

@Component({
    selector: 'app-admin',
    templateUrl: './admin.component.html',
    styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {

    @ViewChild('userTbSort') userTbSort: MatSort;
    @ViewChild('userTbPaginator') userTbPaginator: MatPaginator;
    @ViewChild('adminTbSort') adminTbSort: MatSort;
    @ViewChild('adminTbPaginator') adminTbPaginator: MatPaginator;

    @ViewChildren('userTbSort') userTbSorts: QueryList<MatSort>;
    @ViewChildren('userTbPaginator') userTbPaginators: QueryList<MatPaginator>;
    @ViewChildren('adminTbSort') adminTbSorts: QueryList<MatSort>;
    @ViewChildren('adminTbPaginator') adminTbPaginators: QueryList<MatPaginator>;

    @ViewChild('gmsRequestTbSort') gmsRequestTbSort: MatSort;
    @ViewChild('gmsRequestTbPaginator') gmsRequestTbPaginator: MatPaginator;

    isAdmin = false;
    isGdsPartner = false;
    isCustomerManager = false;

    grantAccessForm: UntypedFormGroup;
    grantAccessError = null;

    updateGmsPropertiesForm: UntypedFormGroup;
    updateGmsJsonError = null;

    addGmsPropertiesForm: UntypedFormGroup;
    addGmsJsonError = null;

    messageTemplateForm: UntypedFormGroup;
    messageTemplateError = null;
    showMessagePreview = false;

    sendMessageForm: UntypedFormGroup;
    sendMessageError = null;
    sendMessageSuccess = false;

    gmsTabLabel = "gmsTab";
    usersTabLabel = "usersTab";
    adminsAndPartnersTabLabel = "adminsAndPartnersTab";
    gmsRequestsTabLabel = "gmsRequestsTabLabel";
    messageTemplatesTabLabel = "messageTemplatesTab";
    sendMessagesTabLabel = "sendMessagesTab";

    userDataSource: MatTableDataSource<User>;
    users: User[] = [];
    displayedUsers: User[] = [];
    displayedUserColumns = ['username', 'role', 'gmsId', 'registrationDate', 'accountManagerUsername', 'edit'];
    adminsAndPartnersTabActive = false;
    noAccountManager = false;
    showInactive = false;

    gmsDataSource: MatTableDataSource<Gms>;
    gmsList: Gms[] = [];
    gmsListNonClassified: GmsNonClassified[] = [];
    gmsListNonClassifiedForUsers: GmsNonClassified[] = [];
    gmsListNonClassifiedForMessages: GmsNonClassified[] = [];
    displayedGmsListColumns = ['id', 'name', 'update', 'creationDate', 'creationUsername', 'lastUpdateDate', 'lastUpdateUsername', 'delete'];
    selectedGmsToUpdate = undefined;
    addNewGms = false;

    gmsRequestDataSource: MatTableDataSource<GmsAccessRequestTableItem>;
    gmsRequestList: GmsAccessRequestTableItem[] = [];
    displayedGmsRequests: GmsAccessRequestTableItem[] = [];
    displayedGmsRequestListColumns = ['username', 'role', 'accountManagerUsername', 'gms', 'creationDate', 'status', 'edit']
    showApprovedDeclinedRequests = false;

    messageTemplates: MessageTemplate[] = [];
    messageTemplateColumns = ['app', 'type', 'alarm', 'description', 'lastUpdateDate', 'lastUpdateUsername'];
    selectedMessageTemplate = undefined;

    messageTypeOptions = [];
    messageType: MessageType = MessageType.INFO;
    severityOptions = [];
    severity: Severity;

    isLoadingUsers: boolean = false;
    isLoadingGmsData: boolean = false;
    isLoadingMessageTemplates: boolean = false;
    isLoadingGmsAccessRequests: boolean = false;
    isSendingGmsAccessGrant: boolean = false;

    constructor(private userService: UserService,
                private gmsService: GmsService,
                private messageTemplateService: MessageTemplateService,
                private messageService: MessageService,
                private gmsAccessService: GmsAccessService,
                public main: MainService,
                private clipboard: Clipboard,
                private fb: UntypedFormBuilder,
                private dialog: MatDialog,
                private authService: AuthService,
                private router: Router) {
        this.isAdmin = authService.getRole() === Role.ADMIN;
        this.isGdsPartner = authService.getRole() === Role.GDS_PARTNER;
        this.isCustomerManager = authService.getRole() === Role.CUSTOMER_MANAGER;
        this.messageTypeOptions = Object.keys(MessageType);
        this.severityOptions = Object.keys(Severity);
        this.grantAccessForm = this.fb.group({
            username: ['', [Validators.email, Validators.required]],
            gmsList: [[], []],
            sendMail: [true, []],
        });
        this.addGmsPropertiesForm = this.fb.group({
            gmsPropertiesAsJson: ['', [Validators.required, jsonValidator]],
        });
        this.messageTemplateForm = this.fb.group({
            description: ['', [Validators.required, Validators.minLength(3)]],
        });
        this.sendMessageForm = this.fb.group({
            messageType: [MessageType.INFO, [Validators.required]],
            severity: ['', [Validators.required]],
            gmsList: [[], [Validators.required]],
            sender: ['Geislinger', [Validators.required]],
            title: ['', [Validators.required]],
            description: ['', [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.reloadUsers(this.adminsAndPartnersTabActive, this.noAccountManager, this.showInactive);
        this.reloadMessageTemplates();
        this.reloadGmsList();

        if (this.authService.getRole() === Role.ADMIN || this.authService.getRole() === Role.GDS_PARTNER) {
            this.displayedUserColumns.push('deactivate');
        }
    }

    reloadUsers(adminsAndPartnersTabActive: boolean, noAccountManager: boolean, showInactive: boolean) {
        this.isLoadingUsers = true;

        if (adminsAndPartnersTabActive) {
            this.userService.getAllAdminsAndPartners().subscribe((users: User[]) => {
                this.users = users;

                this.displayedUsers = this.users.filter(user => user.active !== showInactive);

                if (noAccountManager) {
                    this.displayedUsers = this.displayedUsers.filter(user => !user.accountManagerUsername);
                }

                this.reloadUserDataSource(adminsAndPartnersTabActive);
                this.isLoadingUsers = false;
            }, () => {
                this.isLoadingUsers = false;
            });
        } else {
            switch (this.authService.getRole()) {
                case Role.ADMIN:
                case Role.GDS_PARTNER:
                    this.userService.getAllRegularUsers().subscribe((users: User[]) => {
                        this.users = users;

                        this.displayedUsers = this.users;

                        if (noAccountManager) {
                            this.displayedUsers = this.displayedUsers.filter(user => !user.accountManagerUsername);
                        }

                        this.displayedUsers = this.displayedUsers.filter(user => user.active !== showInactive);

                        this.reloadUserDataSource(adminsAndPartnersTabActive);
                        this.isLoadingUsers = false;
                    }, () => {
                        this.isLoadingUsers = false;
                    });
                    break;
                case Role.CUSTOMER_MANAGER:
                    this.userService.getSubUsers().subscribe((users: User[]) => {
                        this.users = users;

                        this.displayedUsers = users;

                        if (this.noAccountManager) {
                            this.displayedUsers = this.displayedUsers.filter(user => !user.accountManagerUsername);
                        }

                        this.displayedUsers = this.displayedUsers.filter(user => user.active !== showInactive);

                        this.reloadUserDataSource(adminsAndPartnersTabActive);
                        this.isLoadingUsers = false;
                    }, () => {
                        this.isLoadingUsers = false;
                    });
                    break;
                default:
                    this.isLoadingUsers = false;
                    this.router.navigate(['error']);
                    break;
            }
        }
    }

    reloadUserDataSource(adminsAndPartnersTabActive: boolean) {
        this.userDataSource = new MatTableDataSource(this.displayedUsers
            .sort(user => user.username !== this.authService.getUsername() ? 1 : -1)
        );

        // force case-insensitive sort
        this.userDataSource.sortingDataAccessor = (data: any, sortHeaderId: string): string => {
            if (typeof data[sortHeaderId] === 'string') {
                return data[sortHeaderId].toLocaleLowerCase();
            }

            return data[sortHeaderId];
        };

        if (adminsAndPartnersTabActive) {
            if (this.adminTbSort && this.userTbSort) {
                this.userDataSource.sort = this.adminTbSort;
                this.userDataSource.paginator = this.adminTbPaginator;
            } else {
                this.adminTbSorts.changes.subscribe((comps: QueryList<MatSort>) => {
                    this.userDataSource.sort = comps.first;
                })
                this.adminTbPaginators.changes.subscribe((comps: QueryList<MatPaginator>) => {
                    this.userDataSource.paginator = comps.first;
                })
            }
        } else {
            if (this.userTbSort && this.userTbPaginator) {
                this.userDataSource.sort = this.userTbSort;
                this.userDataSource.paginator = this.userTbPaginator;
            } else {
                this.userTbSorts.changes.subscribe((comps: QueryList<MatSort>) => {
                    this.userDataSource.sort = comps.first;
                })
                this.userTbPaginators.changes.subscribe((comps: QueryList<MatPaginator>) => {
                    this.userDataSource.paginator = comps.first;
                })
            }
        }
    }

    reloadGmsRequestList() {
        this.isLoadingGmsAccessRequests = true;
        this.gmsAccessService.getAllAuthorizedAsTableItems().subscribe(gmsRequestList => {
            this.gmsRequestList = gmsRequestList;

            this.reloadGmsRequestListDataSource();
            this.isLoadingGmsAccessRequests = false;
        }, () => {
            this.isLoadingGmsAccessRequests = false;
        });
    }

    reloadGmsRequestListDataSource() {
        if (this.showApprovedDeclinedRequests) {
            this.displayedGmsRequests = this.gmsRequestList.filter(gmsRequest => gmsRequest.status === "Approved" || gmsRequest.status === "Declined");
        } else {
            this.displayedGmsRequests = this.gmsRequestList.filter(gmsRequest => gmsRequest.status === "Pending");
        }

        this.gmsRequestDataSource = new MatTableDataSource(this.displayedGmsRequests.sort((a, b) => b.creationDate.localeCompare(a.creationDate)));

        this.gmsRequestDataSource.sort = this.gmsRequestTbSort;
        this.gmsRequestDataSource.paginator = this.gmsRequestTbPaginator;

        this.gmsRequestDataSource.sortingDataAccessor = (item, property) => {
            if (property === 'role') {
                return item.userRole;
            } else {
                return item[property];
            }
        }
    }

    reloadGmsList() {
        this.isLoadingGmsData = true;
        if (this.isAdmin) {
            this.gmsService.getAll().subscribe(gms => {
                gms.map(g => {
                    g.name = g.properties.contextInfo.name;
                    g.longTitle = getLongTitle(g.properties, g.id);
                });
                this.gmsList = gms;
                this.gmsDataSource = new MatTableDataSource(gms);
                this.isLoadingGmsData = false;
            }, () => {
                this.isLoadingGmsData = false;
            });
        }
        this.gmsService.getAllNonClassified().subscribe(gms => {
            this.gmsListNonClassified = gms;
            this.gmsListNonClassifiedForUsers = gms;
            this.gmsListNonClassifiedForMessages = gms;
            this.isLoadingGmsData = false;
        }, () => {
            this.isLoadingGmsData = false;
        });
    }

    reloadMessageTemplates() {
        if (!this.isAdmin) return;
        this.isLoadingMessageTemplates = true;
        this.messageTemplateService.getAll().subscribe(res => {
            this.messageTemplates = res.sort((a, b) => (a.app < b.app ? -1 : 1));
            this.isLoadingMessageTemplates = false;
        }, () => {
            this.isLoadingMessageTemplates = false;
        });
    }

    applyFilterOnGmsList(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.gmsDataSource.filter = filterValue.trim().toLowerCase();
    }

    applyFilterOnGmsListWhileSendMessage(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
        this.gmsListNonClassifiedForMessages = this.gmsListNonClassified
            .filter(g => g.longTitle.includes(filterValue) || g.name.includes(filterValue));
    }

    applyFilterOnGmsListOnUsersPage(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
        this.gmsListNonClassifiedForUsers = this.gmsListNonClassified
            .filter(g => g.longTitle.includes(filterValue) || g.name.includes(filterValue));
    }

    applyFilterOnGmsRequestList(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.gmsRequestDataSource.filter = filterValue.trim().toLowerCase();
    }

    applyFilterOnUserList(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.userDataSource.filter = filterValue.trim().toLowerCase();
    }

    toggleNoAccountManager(adminsAndPartnersTabActive: boolean) {
        this.noAccountManager = !this.noAccountManager;

        this.reloadUsers(adminsAndPartnersTabActive, this.noAccountManager, this.showInactive);
    }

    toggleShowInactive(adminsAndPartnersTabActive: boolean) {
        this.showInactive = !this.showInactive;

        let index = this.displayedUserColumns.indexOf(this.showInactive ? 'deactivate' : 'activate');

        if (index !== -1) {
            this.displayedUserColumns.splice(index, 1, this.showInactive ? 'activate' : 'deactivate');
        }

        this.reloadUsers(adminsAndPartnersTabActive, this.noAccountManager, this.showInactive);
    }

    toggleShowApprovedDeclinedRequests() {
        this.showApprovedDeclinedRequests = !this.showApprovedDeclinedRequests;

        this.reloadGmsRequestListDataSource();
    }

    onClickGmsRequestUsername(gmsRequestUsername: string) {
        window.open("#/user/" + gmsRequestUsername + "/edit");
    }

    onSelectedTabChange($event) {
        switch ($event.tab.textLabel) {
            case this.gmsTabLabel:
                this.reloadGmsList();
                break;
            case this.usersTabLabel:
                this.adminsAndPartnersTabActive = false;
                this.reloadUsers(this.adminsAndPartnersTabActive, this.noAccountManager, this.showInactive);
                break;
            case this.adminsAndPartnersTabLabel:
                this.adminsAndPartnersTabActive = true;
                this.reloadUsers(this.adminsAndPartnersTabActive, this.noAccountManager, this.showInactive);
                break;
            case this.gmsRequestsTabLabel:
                this.reloadGmsRequestList();
                break;
            case this.messageTemplatesTabLabel:
                this.reloadMessageTemplates();
                break;
            case this.sendMessagesTabLabel:
                // Nothing to load
                break;
        }
    }

    changeUserActive(username: string, active: boolean) {
        this.userService.updateActive(username, active)
            .subscribe(() => this.reloadUsers(this.adminsAndPartnersTabActive, this.noAccountManager, this.showInactive));
    }

    deleteGms(gmsId: string) {
        this.gmsService.delete(gmsId)
            .subscribe(() => this.reloadGmsList());
    }

    openChangeUserActiveDialog(username: string, operation: "deactivate" | "activate"): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                message: `Are you sure you want to ${operation} the user "` + username + '\"?',
                buttonText: {
                    ok: `Yes, ${operation}`,
                    cancel: 'Cancel'
                }
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.changeUserActive(username, operation === "activate");
            }
        });
    }

    openDeleteGmsDialog(gms): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                message: 'Are you sure you want to delete the GMS \"' + gms.properties.contextInfo.name + '(' + gms.id + ')\"?',
                buttonText: {
                    ok: 'Yes, delete',
                    cancel: 'Cancel'
                }
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.deleteGms(gms.id);
            }
        });
    }

    grantAccessToGms() {
        const gmsAccessGrant = new GmsAccessGrant();
        gmsAccessGrant.grantedToUsername = this.grantAccessForm.get('username').value;
        gmsAccessGrant.gmsIds = this.grantAccessForm.get('gmsList').value;
        gmsAccessGrant.sendMail = this.grantAccessForm.get('sendMail').value;

        if (this.grantAccessForm.valid) {
            this.isSendingGmsAccessGrant = true;
            this.gmsAccessService.grantAccess(gmsAccessGrant).subscribe(
                (resp) => {
                    if (resp.sendMailError) {
                        this.grantAccessError = "Access was successfully granted, but email could not be sent" //resp.sendMailErrorText
                    }
                    this.reloadUsers(this.adminsAndPartnersTabActive, this.noAccountManager, this.showInactive);
                    this.reloadGmsList();
                    this.isSendingGmsAccessGrant = false;
                },
                error => {
                    this.grantAccessError = error.error;
                    this.isSendingGmsAccessGrant = false;
                });

            this.grantAccessForm.reset();
            this.grantAccessForm.patchValue({sendMail: true});
        }
    }

    copyToClipboard(text: string) {
        this.clipboard.copy(text);
    }

    showGmsProperties(gmsId: string) {
        if (this.selectedGmsToUpdate) {
            this.selectedGmsToUpdate = undefined;
        } else {
            this.selectedGmsToUpdate = this.gmsList.filter(gms => gms.id === gmsId)[0];
            this.updateGmsPropertiesForm = this.fb.group({
                gmsPropertiesAsJson: [JSON.stringify(this.selectedGmsToUpdate.properties, undefined, 4),
                    [Validators.required, jsonValidator]],
            });
        }
    }

    showDescription(mt: MessageTemplate) {
        if (this.selectedMessageTemplate && this.selectedMessageTemplate.alarm === mt.alarm) {
            this.selectedMessageTemplate = undefined;
        } else {
            this.selectedMessageTemplate = this.messageTemplates.filter(mt2 => mt2.alarm === mt.alarm)[0];
            this.messageTemplateForm = this.fb.group({
                description: [this.selectedMessageTemplate.description, [Validators.required, Validators.minLength(3)]],
            });
        }
    }

    prettyPrint() {
        const ugly = (document.getElementById('gmsJsonText') as HTMLInputElement).value;
        const obj = JSON.parse(ugly);
        (document.getElementById('gmsJsonText') as HTMLInputElement).value = JSON.stringify(obj, undefined, 4);
    }

    updateGmsProperties() {
        const properties = JSON.parse(this.updateGmsPropertiesForm.get('gmsPropertiesAsJson').value);

        if (this.updateGmsPropertiesForm.valid) {
            this.gmsService.upsert(properties).subscribe(
                () => {
                    this.reloadGmsList();
                    this.selectedGmsToUpdate = undefined;
                },
                error => {
                    this.updateGmsJsonError = error.error;
                });

            this.updateGmsPropertiesForm.reset();
        }
    }

    updateMessageTemplate() {
        if (this.messageTemplateForm.valid) {
            // const description = this.sanitized.bypassSecurityTrustHtml(this.messageTemplateForm.get('description').value).toString();
            const description = this.messageTemplateForm.get('description').value;
            if (description) {
                this.selectedMessageTemplate.description = description;

                this.messageTemplateService.upsert(this.selectedMessageTemplate).subscribe(
                    (res) => {
                        this.messageTemplates = res.sort((a, b) => (a.app < b.app ? -1 : 1));
                        this.selectedMessageTemplate = undefined;
                    },
                    error => {
                        this.messageTemplateError = error.error;
                    });

                this.messageTemplateForm.reset();
                this.showMessagePreview = false;
            }
        }
    }

    addNewGmsProperties() {
        const properties = JSON.parse(this.addGmsPropertiesForm.get('gmsPropertiesAsJson').value);

        if (this.addGmsPropertiesForm.valid) {
            this.gmsService.upsert(properties).subscribe(
                () => {
                    this.reloadGmsList();
                    this.addNewGms = false;
                },
                error => {
                    this.addGmsJsonError = error.error;
                });

            this.addGmsPropertiesForm.reset();
        }
    }

    sendMessage() {
        if (this.sendMessageForm.valid) {
            const messages = [];
            this.sendMessageForm.get('gmsList').value.forEach(gmsId => {
                const message = new Message();
                message.gmsId = gmsId;
                message.sender = this.sendMessageForm.get('sender').value;
                message.messageType = this.sendMessageForm.get('messageType').value;
                message.severity = this.sendMessageForm.get('severity').value;
                message.shortTitle = this.sendMessageForm.get('title').value;
                message.longTitle = this.sendMessageForm.get('title').value;
                message.description = this.sendMessageForm.get('description').value;
                messages.push(message);
            });

            this.messageService.create(messages).subscribe(
                () => {
                    this.sendMessageSuccess = true;
                },
                error => {
                    this.sendMessageError = error.error;
                });

            this.sendMessageForm.reset();
        }
    }

    getGmsOptionsForUser(gmsIdsFromUser: string[]): GmsNonClassified[] {
        // console.log(gmsIdsFromUser);
        if (!gmsIdsFromUser || gmsIdsFromUser.length === 0) {
            return this.gmsListNonClassifiedForUsers;
        }
        // console.log(this.gmsListNonClassifiedForUsers);
        // const gmsList = [...this.gmsListNonClassifiedForUsers];
        const gmsIds = new Set<string>();
        this.gmsListNonClassifiedForUsers.map(gms => {
            if (gms !== undefined) {
                gmsIds.add(gms.id);
            }
        });
        // gmsList = gmsList.filter((gms): gms is Gms => !!gms);
        // console.log(gmsIds);
        // console.log(gmsList.filter((gms): gms is Gms => !!gms));
        gmsIdsFromUser.forEach(gId => {
            if (!gmsIds.has(gId)) {
                gmsIds.add(gId);
            }
        });

        const gmsList = [];
        this.gmsListNonClassified.map(gms => {
            if (gmsIds.has(gms.id)) {
                gmsList.push(gms);
            }
        });
        return gmsList;
    }

    protected readonly window = window;
}

export function jsonValidator(control: AbstractControl): ValidationErrors | null {
    try {
        JSON.parse(control.value);
    } catch (e) {
        return {jsonInvalid: true};
    }

    return null;
}

export function getLongTitle(properties: any, id: string) {
    const geislingerRef = properties.config?.systemInfo?.geislingerReference ||
        properties.customizedConfig?.configsPerEngine[0].systemInfo.geislingerReference;
    const installedProds = properties.config?.systemInfo?.installedProducts ||
        properties.customizedConfig?.configsPerEngine[0].systemInfo.installedProducts;
    const name = properties.contextInfo?.name;
    return name + " | " + geislingerRef + ' | ' + installedProds + ' | ' + id;
}