import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {Observable} from "rxjs";
import {RegistrationRequest, RegistrationResponse} from "../models/user/registration-request";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  private host = environment.apiUrl + '/v1/registration/';
  private termsAndConditionsVersion = environment.termsAndConditions;

  constructor(private httpClient: HttpClient) { }

  register(registrationRequest: RegistrationRequest): Observable<RegistrationResponse> {
    const url = this.host + `register?termsAndConditions=${this.termsAndConditionsVersion}`;
    return this.httpClient.post<RegistrationResponse>(url, registrationRequest, {headers: {Authorization: ""}});
  }

  confirmRegistration(token: string): Observable<void> {
    const url = this.host + `confirm-registration/${token}`;
    return this.httpClient.post<void>(url, {},{headers: {Authorization: ""}})
  }
}