<div class="ship-data">
    <div class="g-icon-text-horizontal">
        <div class="icon">
            <app-icon [name]="ship.iconInfo.toLowerCase()"></app-icon>
        </div>
        <div class="text">
            <h4 class="name">{{ship.name | slice:0:33}}</h4>
            <div class="call-sign">{{ship.gmsId}}</div>
        </div>
    </div>
</div>
<div class="damper" (click)="navigate(ship.gmsId, 'detail')">
    <div class="alarm-note" *ngIf="ship.status===GmsStatus.ALARM">
        <app-icon [name]="'alarm'"></app-icon>
    </div>
    <div class="g-number last-connection" *ngIf="ship.lastUpdated">
        <div class="g-number-integer">{{main.timeUntil(ship.lastUpdated)[0]}}</div>
        <div class="g-number-text">
            <div class="g-number-description">last update</div>
            <span class="g-number-unit">{{main.timeUntil(ship.lastUpdated)[1]}} </span>ago
        </div>
    </div>
    <div class="g-number last-connection" *ngIf="!ship.lastUpdated">
        <div class="g-number-text">
            <div class="g-number-description">last update</div>
            <span class="g-number-unit">No data</span>
        </div>

    </div>
    <div *ngFor="let engine of ship.statusPerEngine" class="g-card damper-card">
        <div class="g-icon-text-horizontal">
            <div class="icon"
                 *ngIf="engine.druidDatasource.includes('damper-analysis') || engine.druidDatasource.includes('coupling-analysis')">
                <app-icon [name]="'damper'"></app-icon>
            </div>
            <div class="text">
                <div class="logo"><img [src]="isUsingLightMode ? 'assets/geislinger-logo-dark.svg' : 'assets/geislinger-logo.svg'"></div>
                <div class="info">{{engine.installedProducts}}</div>
            </div>
        </div>
        <div class="maintenance"
             *ngIf="engine.druidDatasource.includes('damper-analysis') || engine.druidDatasource.includes('coupling-analysis')">
            <div class="maintenance-bar" [ngStyle]="{'width' : getEngineMaintenanceBarWidthPercentage(engine.maintenanceBar)}"></div>
        </div>
    </div>
    <div class="info">
        <div class="g-card status" [ngClass]="ship.status" (click)="navigate(ship.gmsId, 'status')">
            <app-status-icon class="symbol" [input]="ship.status"></app-status-icon>
            <h6>Status</h6>
            <h4>{{ship.status}}</h4>
        </div>
        <div class="g-card connection"
             [ngClass]="main.getConnectionDescription(ship.connectionQuality, ship.pushType)"
             (click)="ship.pushType === 'manualPush' ? navigate(ship.gmsId, 'upload') : navigate(ship.gmsId, 'connection')">
            <app-connection-icon class="symbol"
                                 [input]="main.getConnectionDescription(ship.connectionQuality, ship.pushType)"></app-connection-icon>
            <div class="description">
                <h6>Connection</h6>
                <h4>{{main.getConnectionDescription(ship.connectionQuality, ship.pushType)}}</h4>
                <!--<div class="updated" *ngIf="ship.connection==='no-signal'; else connected">
                    <h4>{{ship.connection}}</h4>
                    since <b>{{main.timeUntil(ship.lastConnected)[0]}}</b>
                    <span style="text-transform: lowercase"> {{main.timeUntil(ship.lastConnected)[1]}}</span>
                </div>
                <ng-template #connected><h4></h4></ng-template>-->
            </div>
        </div>
        <div class="g-card notifications" [ngClass]="ship.status"
             (click)="navigate(ship.gmsId, 'messages')">
            <div class="symbol">
                <ng-container *ngIf="ship.unreadMessagesCount > 0; else noNew">
                    <app-icon [name]="'new_message'"></app-icon>
                    <span class="unread">{{ship.unreadMessagesCount}}</span>
                </ng-container>
                <ng-template #noNew>
                    <app-icon [name]="'no_message'"></app-icon>
                </ng-template>
            </div>
            <div class="description">
                <h6>Messages</h6>
            </div>
        </div>
        <div class="g-card notifications" [ngClass]="ship.status"
             (click)="navigate(ship.gmsId, 'documents')">
            <div class="symbol">
                <app-icon [name]="'folder'"></app-icon>
                <span class="unread" *ngIf="ship.unreadDocumentsCount > 0">{{ship.unreadDocumentsCount}}</span>
            </div>
            <div class="description">
                <h6>Documents</h6>
            </div>
        </div>
    </div>
</div>
