import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Navis Bluetracker opens this application as popup. This breaks OidcSecurityService which expects to be the
// main window. By setting document.defaultView.opener we pass the main window check.
// See https://github.com/damienbod/angular-auth-oidc-client/issues/1654
document.defaultView.opener = null;

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
