<mat-spinner *ngIf="!main.activeItem"></mat-spinner>
<div *ngIf="main.activeItem">
    <div class="g-head g-hero" *ngIf="main.activeItem" [ngClass]="main.activeItem.status">
        <div class="g-hero-text">
            <div class="text">
                <div class="logo"><img src="assets/geislinger-logo.svg"></div>
                <h3>{{main.activeItem.designation}}</h3>
                <h5 class="damper-id"><b>{{main.activeItem.projectInfo}}</b></h5>
            </div>
        </div>
        <div class="g-hero-headline"><h1>Messages</h1></div>
    </div>

    <div class="columns">
        <!--SELECT ALL CHECKBOX-->
        <div class="select-all-checkbox">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="masterToggle($event.checked)"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </div>

        <!--DELETE ALL SELECTED-->
        <span *ngIf="this.selection.selected.length > 0">
            <button mat-icon-button (click)="openDeleteSelectedMessagesDialog(selection.selected.length)">
                <mat-icon>delete</mat-icon>
            </button>
        </span>

        <!--SEARCH INPUT-->
        <div>
            <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
            </mat-form-field>
            <mat-icon>search</mat-icon>
        </div>
    </div>


    <!--TABS FOR MESSAGE TYPE FILTERING-->
    <mat-tab-group (selectedTabChange)="onTabChange($event)" *ngIf="main.activeItem" #tabs [(selectedIndex)]="index" mat-stretch-tabs="false">
        <mat-tab label="ALL">
            <ng-template mat-tab-label>
            <span class="count" *ngIf="countUnreadMessagesForType(undefined)>0"
                  [class.alarm]="countUnreadMessagesForType('ALARM') > 0">
                {{countUnreadMessagesForType(undefined)}}</span>
                ALL MESSAGES
            </ng-template>
        </mat-tab>
        <mat-tab label="ALARMS">
            <ng-template mat-tab-label>
            <span class="alarm">
              <span class="count" *ngIf="countUnreadMessagesForType('ALARM')>0">
                {{countUnreadMessagesForType('ALARM')}}</span>
            ALARMS
            </span>
            </ng-template>
        </mat-tab>
        <mat-tab label="WARNINGS">
            <ng-template mat-tab-label>
            <span class="warn">
            <span class="count" *ngIf="countUnreadMessagesForType('WARNING')>0">
                {{countUnreadMessagesForType('WARNING')}}</span>
            WARNINGS
                </span>
            </ng-template>
        </mat-tab>
        <mat-tab label="INFOS">
            <ng-template mat-tab-label>
            <span class="count" *ngIf="countUnreadMessagesForType('INFO')>0">
                {{countUnreadMessagesForType('INFO')}}</span>
                INFOS
            </ng-template>
        </mat-tab>
    </mat-tab-group>


    <!--TABLE-->
    <table mat-table *ngIf="main.activeItem"
           [dataSource]="dataSource" multiTemplateDataRows
           class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Engine Column -->
        <ng-container matColumnDef="engine">
            <th mat-header-cell *matHeaderCellDef>Engine</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.read, 'message-alarm': row.messageType === 'ALARM', 'message-warn': row.messageType === 'WARNING'}">
                {{row.engineTitle}}</td> <!--({{row.engineIndex}})-->
        </ng-container>

        <!-- Sender Column -->
        <ng-container matColumnDef="sender">
            <th mat-header-cell *matHeaderCellDef>Sender</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.read, 'message-alarm': row.messageType === 'ALARM', 'message-warn': row.messageType === 'WARNING'}">
                <app-icon class="small-icon" [name]="row.messageType"></app-icon>
                    {{row.sender}}
            </td>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="shortTitle">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.read, 'message-alarm': row.messageType === 'ALARM', 'message-warn': row.messageType === 'WARNING'}">{{row.shortTitle}}</td>
        </ng-container>

        <!-- Severity Column -->
        <ng-container matColumnDef="severity">
            <th mat-header-cell *matHeaderCellDef>Severity</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.read, 'message-alarm': row.messageType === 'ALARM', 'message-warn': row.messageType === 'WARNING'}">
                {{row.severity}}</td>
        </ng-container>

        <!-- Time Column -->
        <ng-container matColumnDef="eventDate">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.read, 'message-alarm': row.messageType === 'ALARM', 'message-warn': row.messageType === 'WARNING'}">{{row.eventDate | date : 'y-MM-dd hh:mm:ss a zzzz'}}</td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.read}">
                <button mat-icon-button (click)="delete(row.id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let message" [attr.colspan]="columnsToDisplay.length">
                <div [@detailExpand]="message == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden">
                    <div class="message-body">
                        <div class="message-text">
                            <h3>{{message.longTitle}}</h3>
                            <p [innerHTML]="message.description"></p>

                            <a [href]="createMailToLink(message)" (click)="$event.stopPropagation()" target="_top">
                                <button class="g-button" (click)="debug(message)">CONTACT SUPPORT</button>
                            </a>
                        </div>
                    </div>
                    <div class="g-hero-img"
                         *ngIf="message === expandedElement && message.alarmManualCurves?.title && (message.messageType === 'WARNING' || message.messageType === 'ALARM') ">
                        <app-highchart [id]="message.shortTitle" class=""
                                       [options]="message.chart"
                                       style="min-width: 550px; min-height: 550px"></app-highchart>
                    </div>
                    <!--                    <div class="g-hero-img" *ngIf="message === expandedElement && message.messageType === 'WARNING'">-->
                    <!--                        <app-highchart [id]="main.messageDetailApp3Chart.id" class=""-->
                    <!--                                       [options]="main.messageDetailApp3Chart"-->
                    <!--                                       style="min-width: 550px; min-height: 550px"></app-highchart>-->
                    <!--                    </div>-->
                    <!--                    <div class="g-hero-img" *ngIf="message === expandedElement && message.messageType === 'ALARM'">-->
                    <!--                        <app-highchart [id]="main.messageDetailApp2Chart.id" class=""-->
                    <!--                                       [options]="main.messageDetailApp2Chart"-->
                    <!--                                       style="min-width: 550px; min-height: 550px"></app-highchart>-->
                    <!--                    </div>-->
                </div>
            </td>
        </ng-container>

        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
            [class.expanded-row]="expandedElement === element"
            (click)="expandedElement = (expandedElement === element) ? null : element; element.read = true; markMessageAsRead(element)">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

        <!-- Row shown when there is no matching data. -->
        <ng-container *ngIf="input.value">
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No message matching your filter "{{input.value}}"</td>
            </tr>
        </ng-container>
        <ng-container *ngIf="!input.value">
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">None</td>
            </tr>
        </ng-container>
    </table>
</div>
