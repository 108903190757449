<div class="g-hero">
    <div class="g-hero-text">
        <h1>Geislinger Analytics Platform</h1>
    </div>
    <div class="g-hero-img sujet"></div>
</div>
<div class="g-head g-form">
    <h3 class="g-headline">Navis-Login</h3>
    <mat-spinner *ngIf="error === null"></mat-spinner>
    <div *ngIf="error !== null">
        <div class="g-status warn"><b>Navis-Login failed</b></div>
        <div><a routerLink="/login">Please login with username and password.</a></div>
    </div>
</div>
