import {Severity} from './severity.enum';
import {AlarmManualCurves} from './alarm-manual-curves';

export class Message {
    id: number;
    gmsId: string;
    engineIndex: number;
    engineTitle: string;
    eventDate: Date; // date of the event (e.g. of an alarm -> same timestamp as in Druid)
    creationDate: Date; // exact creation date of the message (for alarms/warnings different to date)
    row: number;
    messageType: MessageType;
    severity: Severity;
    sender: string;
    shortTitle: string;
    longTitle: string;
    description: string;
    read: boolean;
    active: boolean;
    alarmManualCurves: AlarmManualCurves;
    chart: any; // frontend only
}

export enum MessageType {
    ALARM = 'ALARM',
    WARNING = 'WARNING',
    INFO = 'INFO'
}
