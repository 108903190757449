import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {MainService} from '../../services/main.service';
import {ConnectionStatusData} from '../../models/gms/connection-status-data';
import {GmsChartService} from '../../services/gms-chart.service';
import {GmsInfoDirective} from "../_shared/gms-info/gms-info.directive";

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.css']
})
export class ConnectionComponent extends GmsInfoDirective implements OnInit, OnChanges, OnDestroy {
  chart: any;
  chartIsLoading = false;

  imo: string;
  oldImo: string;

  connectionChartSubscription: any;

  constructor( private route: ActivatedRoute,
               public router: Router,
               public main: MainService,
               private gmsChartService: GmsChartService) {
    super(main, router);
    router.events.subscribe(() => {
      if (event instanceof NavigationStart) {
        this.imo = undefined;
        setTimeout(() => {
          this.findImoInProjectInfo(this.main.activeItem.projectInfo);
        }, 10);
      }
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    // console.log('detail onInit');
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log(changes);
    if (changes.id) {
      this.init();
    }
  }

  init() {
    this.gmsId = this.route.snapshot.paramMap.get('id');

      this.main.setActiveById(this.gmsId);
      this.findImoInProjectInfo(this.main.activeItem.projectInfo);
      this.setConnectionChart();
  }

  // Examples for projectInfo: "IMO: 9700380", "IMO: 9440083, Hull: Η8022"
  private findImoInProjectInfo(projectInfo: string): string {
    const fields = projectInfo.split(',');
    for (const field of fields) {
      const fieldLC = field.toLowerCase();
      // console.log(fieldLC);
      if (fieldLC.includes('imo')) {
        const imoFields = fieldLC.split('imo:');
        if (imoFields && imoFields.length > 1) {
          const imo = imoFields[1].trim();
          this.imo = imo;
          return imo;
        }
      }
    }
    return undefined;
  }

  setConnectionChart() {
    if (this.main.activeItem && this.main.activeItem.connectionQuality) {
      this.chartIsLoading = true;

      // GMS-604: start date for connection status is always 2 months before current date
      const currentDate = new Date();
      const startDate = new Date(currentDate.getFullYear(),
          currentDate.getMonth() - 2,
          currentDate.getDate());

      // const startDate = this.authService.getEarliestDateAllowedForRole();

      this.connectionChartSubscription = this.gmsChartService.getConnectionStatus(this.gmsId, startDate, new Date())
          .subscribe(connectionData => {
            this.buildConnectionChart(connectionData);
            this.chartIsLoading = false;
          });
    } else {
      this.chart = undefined;
      this.chartIsLoading = false;
    }
  }

  buildConnectionChart(connectionData: ConnectionStatusData) {

    // Test: Showing actual + expected turn counts separately
    // const series = [];
    // if (connectionData.dailyTurnCounts) {
    //   const dates: Date[] = [];
    //   const expected: number[] = [];
    //   const actual: number[] = [];
    //
    //   for (const [key, value] of new Map(Object.entries(connectionData.dailyTurnCounts))) {
    //     dates.push(new Date(key));
    //     expected.push(value.expected);
    //     actual.push(value.actual);
    //   }
    //
    //   series.push({
    //     type: 'line',
    //     name: 'expected turns',
    //     data: MainService
    //         .combineTimeData(dates, expected)
    //   });
    //
    //   series.push({
    //     type: 'line',
    //     name: 'actual turns',
    //     data: MainService
    //         .combineTimeData(dates, actual)
    //   });
    // }

    const series = [];
    if (connectionData.dailyAliveLogCounts) {
      const dates: Date[] = [];
      const qualities: number[] = [];

      for (const [key, value] of new Map(Object.entries(connectionData.dailyAliveLogCounts))) {
        dates.push(new Date(key));
        qualities.push(Math.round(value.actual / value.expected * 100));
      }

      series.push({
        type: 'column',
        name: 'quality',
        data: MainService
            .combineTimeData(dates, qualities)
      });
    }

    this.chart = {
      id: 'connectionChart',
      chart: {
        styledMode: true,
        zoomType: 'x',
        panning: true,
        panKey: 'shift',
        resetZoomButton: {position: {x: 0, y: -30}},
        updateFlag: false,
        oneToOne: true
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
            crop: false,
            overflow: 'none',
            format: '{y} %'
          }
        }
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d',
        headerFormat: '<span>{point.key}</span><table>',
        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.0f} %</b></td></tr>',
        shared: true,
        useHTML: true
      },
      credits: {enabled: false},
      legend: {enabled: true},
      title: {text: null},
      defs: {
        gradient0: {
          tagName: 'linearGradient',
          id: 'gradient-0', x1: 0, y1: 0, //x2: 0, y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        },
        gradient1: {
          tagName: 'linearGradient',
          id: 'gradient-1',
          x1: 0,
          y1: 0,
          // x2: 0,
          // y2: 1,
          children: [{
            tagName: 'stop',
            offset: 0
          }, {
            tagName: 'stop',
            offset: 1
          }]
        }
      },
      xAxis: [{
        type: 'datetime'
      }],
      yAxis: [{
        max: 100,
        labels: {
          format: '{value}%'
        }
      }],
      series
    };
  }

  ngOnDestroy(): void {
    if (this.connectionChartSubscription) {
      this.connectionChartSubscription.unsubscribe();
    }

    super.ngOnDestroy();
  }
}
