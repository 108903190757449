import {AfterViewInit, Component, OnInit, ViewChild} from "@angular/core";
import {GmsInfoDirective} from "../_shared/gms-info/gms-info.directive";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {ActivatedRoute, Router} from "@angular/router";
import {MainService} from "../../services/main.service";
import {Report} from "../../models/gms/report";
import {Role} from "../../models/user/role.enum";
import {AuthService} from "../../auth/auth.service";
import {ReportService} from "../../services/report.service";
import {GmsPageRouteEnum} from "../_shared/gms-info/gms-page-route.enum";
import {DateUtils} from "../../utils/date-utils";
import {NumberUtils} from "../../utils/number-utils";

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent extends GmsInfoDirective implements OnInit, AfterViewInit {
    reports: Report[] = [];
    allPossibleColumns = ['period', 'engine1', 'engine2', 'engine3', 'engine4', 'download', 'details'];
    columnsToDisplay = [];
    dataSource: MatTableDataSource<Report>;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    showDetails = false;

    dateUtils = DateUtils;
    numberUtils = NumberUtils;

    forceDownloadReportId: string | null;

    constructor(private route: ActivatedRoute,
                public main: MainService,
                public router: Router,
                public authService: AuthService,
                public reportService: ReportService) {
        super(main, router);
        this.showDetails = authService.getRole() === Role.ADMIN || authService.getRole() === Role.GDS_PARTNER;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.gmsId = this.route.snapshot.paramMap?.get('gmsId');
        this.main.setActiveById(this.gmsId);

        this.forceDownloadReportId = this.route.snapshot.paramMap?.get('reportId') ?? null;

        this.reportService.getAll(this.gmsId).subscribe(reports => {
            this.reports = reports;
            this.dataSource = new MatTableDataSource(this.reports);
            this.dataSource.paginator = this.paginator;

            this.columnsToDisplay = this.allPossibleColumns.filter(columnName => !columnName.includes('engine') ||
                (this.containsReportWithEngine(1) && columnName == 'engine1') ||
                (this.containsReportWithEngine(2) && columnName == 'engine2') ||
                (this.containsReportWithEngine(3) && columnName == 'engine3') ||
                (this.containsReportWithEngine(4) && columnName == 'engine4'));

            this.columnsToDisplay = this.showDetails ? this.columnsToDisplay : this.columnsToDisplay.filter(columnName => !columnName.includes('details'));
        });
    }

    ngAfterViewInit() {
        if (this.forceDownloadReportId) {
            this.download(this.gmsId, this.forceDownloadReportId);
        }
    }

    containsReportWithEngine(engine: number) {
        let foundReportWithEngine = null;
        switch (engine) {
            case 1:
                foundReportWithEngine =  this.reports.find(report => report.engine1 != null);
                break;
            case 2:
                foundReportWithEngine =  this.reports.find(report => report.engine2 != null);
                break;
            case 3:
                foundReportWithEngine =  this.reports.find(report => report.engine3 != null);
                break;
            case 4:
                foundReportWithEngine =  this.reports.find(report => report.engine4 != null);
                break;
        }
        return foundReportWithEngine != null;
    }

    getDesignationColumnTitleForEngine(engine: number) {
        switch (engine) {
            case 1:
                return this.reports.find(report => report.engine1 != null)?.engine1.designation ?? "ENGINE #" + engine;
            case 2:
                return this.reports.find(report => report.engine2 != null)?.engine2.designation ?? "ENGINE #" + engine;
            case 3:
                return this.reports.find(report => report.engine2 != null)?.engine3.designation ?? "ENGINE #" + engine;
            case 4:
                return this.reports.find(report => report.engine2 != null)?.engine4.designation ?? "ENGINE #" + engine;
        }
        return "";
    }

    downloadReport(report: Report) {
        this.download(report.gmsId, report.reportId);
    }

    download(gmsId: string, reportId: string) {
        this.reportService.download(gmsId, reportId).subscribe(res => {

            // open the file in a new browser tab
            const blob = new Blob([res], {type: 'application/pdf'});
            const url = window.URL.createObjectURL(blob);
            window.open(url);

        }, err => {
            console.log(err);
        });
    }

    goToDetails(report: Report) {
        this.router.navigateByUrl(`/${report.gmsId}/${GmsPageRouteEnum.ReportDetails}/${report.reportId}`);
    }
}