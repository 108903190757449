import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { toDataURL } from 'qrcode';
import { MainService } from '../../services/main.service';
import { User } from '../../models/user/user';
import { Countries } from '../registration/countries';
import { checkPasswordRequirements, mustMatch } from '../../utils/password-utils';
import { TOKEN } from '../_shared/constants/local-storage-constants';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  user: User;
  editProfileForm: UntypedFormGroup;
  countries = Countries;
  loadingEditProfileForm = true;

  passwordChangeForm: UntypedFormGroup;
  totpConfirmForm: UntypedFormGroup;
  passwordChangeError = null;
  passwordChangeSuccess = null;
  passwordChangeSuccessMessage = 'You have changed your password successfully.';

  totpQrImgSrc = null;
  totpSecret = null;
  isUsing2FA = false;

  notifications: Set<string>;
  notificationsUpdated = false;
  confirm2FAError = false;

  lightMode = false;
  highContrast = false;

  constructor(private fb: UntypedFormBuilder, private userService: UserService, private authService: AuthService, private router: Router, public main: MainService) {
    this.editProfileForm = this.fb.group({
      firstname: '',
      lastname: '',
      company: '',
      position: '',
      addressLine: '',
      postalCode: '',
      city: '',
      country: '',
    });
    this.passwordChangeForm = this.fb.group(
      {
        currentPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required, checkPasswordRequirements()]],
        confirmNewPassword: ['', [Validators.required, checkPasswordRequirements()]],
      },
      {
        validators: [mustMatch('newPassword', 'confirmNewPassword', true), mustMatch('currentPassword', 'newPassword', false)],
      }
    );

    this.totpConfirmForm = this.fb.group({
      totpConfirmation: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.reloadUser();

    this.userService.getNotificationsForUser().subscribe((notifications) => {
      this.notifications = new Set<string>(notifications);
    });

    this.userService.get2FASettingsForUser().subscribe((data) => {
      this.isUsing2FA = data.isUsing2FA;
    });

    this.main.lightMode$.subscribe((lightMode) => (this.lightMode = lightMode));
    this.highContrast = this.main.highContrast;
  }

  reloadUser() {
    this.loadingEditProfileForm = true;

    this.userService.get(this.authService.getUsername()).subscribe((user) => {
      this.user = user;

      this.editProfileForm.patchValue({
        firstname: user.personalData?.firstname,
        lastname: user.personalData?.lastname,
        company: user.personalData?.company,
        position: user.personalData?.position,
        addressLine: user.personalData?.addressLine,
        postalCode: user.personalData?.postalCode,
        city: user.personalData?.city,
        country: user.personalData?.country,
      });

      this.loadingEditProfileForm = false;
    });
  }

  applyEditProfileChanges() {
    this.userService
      .updatePersonalData({
        ...this.user.personalData,
        email: this.user.username, // immutable
        firstname: this.editProfileForm.get('firstname').value,
        lastname: this.editProfileForm.get('lastname').value,
        company: this.editProfileForm.get('company').value,
        position: this.editProfileForm.get('position').value,
        addressLine: this.editProfileForm.get('addressLine').value,
        postalCode: this.editProfileForm.get('postalCode').value,
        city: this.editProfileForm.get('city').value,
        country: this.editProfileForm.get('country').value,
      })
      .subscribe(() => {
        this.reloadUser();

        this.editProfileForm.markAsPristine();
      });
  }

  toggleNotifications(id: string): void {
    if (this.notifications.has(id)) {
      this.notifications.delete(id);
    } else {
      this.notifications.add(id);
    }

    this.notificationsUpdated = true;
  }

  updateNotifications(): void {
    // console.log(this.notifications);
    this.userService.updateNotificationsForUser(this.notifications).subscribe(() => {
      this.notificationsUpdated = false;
    });
  }

  sendPasswordChangeRequest() {
    if (this.passwordChangeForm.valid) {
      this.userService.changePassword(this.passwordChangeForm.get('currentPassword').value, this.passwordChangeForm.get('newPassword').value).subscribe(
        () => {
          this.passwordChangeError = null;
          this.passwordChangeSuccess = true;
          localStorage.removeItem(TOKEN);
          if (!this.authService.loggedIn()) {
            this.router.navigate(['/login']);
          }
        },
        (error) => {
          this.passwordChangeSuccess = false;
          this.passwordChangeError = error.error;
        }
      );

      this.passwordChangeForm.reset();
    }
  }

  toggleHighContrast(): void {
    this.highContrast = !this.highContrast;
    this.main.highContrast = this.highContrast;

    if (this.highContrast && this.lightMode) {
      this.toggleLightMode();
    }
  }

  toggleLightMode(): void {
    this.lightMode = !this.lightMode;

    this.userService.updateUsingLightMode(this.lightMode).subscribe(() => {
      this.main.setLightMode(this.lightMode);
    });

    if (this.lightMode && this.highContrast) {
      this.toggleHighContrast();
    }
  }

  toggle2FA(): void {
    this.userService.update2FASettingsForUser(!this.isUsing2FA).subscribe(async (data) => {
      if (data.secret) {
        this.totpSecret = data.secret;
        const dataUrl = await toDataURL(data.uri);
        this.totpQrImgSrc = dataUrl;
      } else {
        this.isUsing2FA = data.isUsing2FA;
      }
    });
  }

  isAwaiting2FAConfirmation(): boolean {
    return this.totpSecret !== null;
  }

  confirm2FA(): void {
    const totp = this.totpConfirmForm.get('totpConfirmation').value;

    this.userService.confirm2FASettingsForUser(totp).subscribe(
      (res) => {
        this.confirm2FAError = false;

        // the user no longer needs this information
        this.totpSecret = null;
        this.totpQrImgSrc = null;

        // confirmation completed successfully
        this.isUsing2FA = true;
      },
      (err) => {
        this.confirm2FAError = true;
        console.log('Error confirming 2FA', err);
      }
    );
  }

  protected readonly Countries = Countries;
}
