import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../auth/auth.service';
import {Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MainService} from "../../services/main.service";
import {COMPACT_VIEW, LIGHT_MODE, LOGOUT_REASON, TOKEN} from "../_shared/constants/local-storage-constants";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    form: UntypedFormGroup;
    error = null;
    logOutMessage: string;

    constructor(public authService: AuthService,
                private router: Router,
                private fb: UntypedFormBuilder,
                private main: MainService) {
    }

    ngOnInit(): void {
        if (this.authService.loggedIn()) {
            this.router.navigate(['']);
        }

        localStorage.removeItem(TOKEN);
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

        this.logOutMessage = localStorage.getItem(LOGOUT_REASON);
        localStorage.removeItem(LOGOUT_REASON);
    }

    login(): void {
        this.logOutMessage = null;

        if (this.form.valid) {
            localStorage.removeItem(TOKEN);
            this.authService.login(this.form.get('username').value, this.form.get('password').value)
                .subscribe(
                    response => {
                        if (!response.error) {
                            localStorage.setItem(TOKEN, response.token);

                            const usingLightMode = this.authService.getUsingLightMode();
                            const usingCompactView = this.authService.getUsingCompactView();

                            this.main.setLightMode(usingLightMode);
                            this.main.compactView = usingCompactView;

                            this.router.navigate(['']);
                        } else {
                            this.error = response.error;
                        }
                    },
                    err => {
                        console.log(err);
                        this.error = err.statusText;
                    }
                );
        } else {
            console.log('Login form is invalid');
        }
    }

    logout() {
        localStorage.removeItem(TOKEN);
        if (!this.authService.loggedIn()) {
            this.router.navigate(['/login']);
        }
    }

    isLoggedIn(): boolean {
        return this.authService.loggedIn();
    }

}
