import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {RegistrationService} from "../../services/registration.service";

@Component({
  selector: 'app-confirm-registration',
  templateUrl: './confirm-registration.component.html',
  styleUrls: ['./confirm-registration.component.css']
})
export class ConfirmRegistrationComponent implements OnInit {

  success = false;
  userLoadingError: string = null;
  loading = true;

  constructor(private route: ActivatedRoute,
              private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.registrationService.confirmRegistration(this.route.snapshot.paramMap.get('token')).subscribe(() => {
      this.success = true;
      this.loading = false;
    }, error => {
      this.userLoadingError = "Unable to confirm registration - please contact the support."; //error.message?.toString()
      this.loading = false;
    })
  }
}
