<div class="g-hero no-img">
  <div class="g-hero-text">
    <h1 class="dashboard-settings">Dashboard Settings</h1>
    <a routerLink="/overview">
      <button type="button" class="g-button back-to-dashboard-button"><mat-icon class="back-arrow-icon">keyboard_arrow_left</mat-icon> Back to Dashboard</button>
    </a>
  </div>
</div>

<mat-spinner *ngIf="loadingUser || loadingGmsContexts"></mat-spinner>
<div *ngIf="!loadingUser && !loadingGmsContexts" class="g-container">
  <div class="g-checkbox">
    <mat-checkbox label="Compact View" [checked]="compactView" (click)="$event.stopPropagation()" (change)="toggleUsingCompactDashboardView()">Compact View</mat-checkbox>
  </div>
  <div class="g-sort">
    <h5>Sorting</h5>
    <div *ngIf="gmsContextFavorites.length === 0">No GMS selected as favorites</div>
    <div *ngIf="gmsContextFavorites.length > 0" cdkDropList class="gms-favorites-list" (cdkDropListDropped)="onListItemDrop($event)">
      <div class="gms-favorites-list-item" *ngFor="let gmsContext of gmsContextFavorites; let index = index" cdkDrag>
        <div class="drag-indicator">
          <mat-icon>drag_indicator</mat-icon>
        </div>
        <div class="ship-data">
          <div class="g-icon-text-horizontal">
            <div class="icon">
              <app-icon [name]="gmsContext.iconInfo.toLowerCase()"></app-icon>
            </div>
            <div class="text">
              <h4 class="name">{{ gmsContext.name | slice : 0 : 33 }}</h4>
              <div class="call-sign">{{ gmsContext.gmsId }}</div>
            </div>
          </div>
        </div>
        <div class="favorite-star mat-focus-indicator" (click)="toggleFavorite(gmsContext.gmsId, index)">
          <mat-icon [ngClass]="{ 'active-favorite': isActiveFavorite(gmsContext.gmsId) }">star</mat-icon>
        </div>
      </div>
    </div>
  </div>
  <button class="g-button" (click)="saveChanges()">Save changes</button>
</div>
