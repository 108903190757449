import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Document } from '../../models/gms/document';
import { MainService } from '../../services/main.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GmsContext } from '../../models/gms/gms-context';
import { DocumentService } from '../../services/document.service';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../auth/auth.service';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Role } from '../../models/user/role.enum';
import { GmsInfoDirective } from '../_shared/gms-info/gms-info.directive';

@Component({
  selector: 'app-downloads',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css'],
})
export class DocumentsComponent extends GmsInfoDirective implements OnInit {
  activeDocuments: Document[] = [];
  dataSource = new MatTableDataSource(this.activeDocuments);
  columnsToDisplay = [
    'select',
    'title',
    'filename',
    'uploadDate',
    'download',
    'delete',
  ];
  expandedElement: Document | null;
  selection = new SelectionModel<Document>(true, []);

  uploadDocForm: UntypedFormGroup;
  uploadError = null;
  file: any;

  constructor(
    public main: MainService,
    private documentService: DocumentService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    public router: Router
  ) {
    super(main, router);
    this.uploadDocForm = this.fb.group({
      title: ['', [Validators.required, Validators.minLength(3)]],
      file: ['', [Validators.required, pdfValidator]],
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    const gmsId = this.route.snapshot.paramMap.get('gmsId');
    this.gmsId = gmsId;
    const docId = this.route.snapshot.paramMap.get('id');

    this.main.setActiveById(gmsId);
    this.loadAndShowDocuments(this.main.activeItem);
    if (docId) {
      this.download(gmsId, docId);
    }
  }

  loadAndShowDocuments(gmsContext: GmsContext): void {
    this.documentService.getAll(gmsContext.gmsId).subscribe((docs) => {
      this.activeDocuments = docs;
      this.updateDownloadedDocumentsCounter();
      this.dataSource = new MatTableDataSource(this.activeDocuments);
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isSomeSelected(): boolean {
    const numSelected = this.selection.selected.length;
    return numSelected > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(checkAll: boolean) {
    if (checkAll) {
      this.dataSource.data.forEach((row) => this.selection.select(row));
    } else {
      this.selection.clear();
    }
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Document): string {
    if (!row) {
      return `${this.isAllSelected ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.row + 1
    }`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  download(gmsId: string, filename: string): void {
    // console.log('clicked on download file ' + filename);

    this.updateDownloadedDocumentsCounter();
    this.documentService.download(gmsId, filename).subscribe(
      (res) => {
        // // directly downloads the file
        // const downloadLink = document.createElement('a');
        // downloadLink.href = window.URL.createObjectURL(new Blob([res], {type: 'application/pdf'}));
        // if (filename) {
        //     downloadLink.setAttribute('download', filename);
        // }
        // document.body.appendChild(downloadLink);
        // downloadLink.click();

        // open the file in a new browser tab
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      (err) => {
        console.log(err);
      }
    );
  }

  openDeleteDocumentDialog(filename: string): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message:
          'Are you sure you want to delete the document ' +
          filename +
          ' permanently?',
        buttonText: {
          ok: 'Yes, delete',
          cancel: 'Cancel',
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.activeDocuments = this.activeDocuments.filter(
          (d) => d.filename !== filename
        );
        this.dataSource = new MatTableDataSource(this.activeDocuments);
        this.documentService.delete(this.gmsId, filename).subscribe();
      }
    });
  }

  openDeleteSelectedDocumentsDialog(nrOfDocs: number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message:
          'Are you sure you want to delete all ' +
          nrOfDocs +
          ' selected documents?',
        buttonText: {
          ok: 'Yes, delete',
          cancel: 'Cancel',
        },
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.activeDocuments = this.activeDocuments.filter(
          (m) => !this.selection.selected.includes(m)
        );
        this.dataSource = new MatTableDataSource(this.activeDocuments);

        this.selection.selected.forEach((doc) => {
          this.documentService.delete(doc.gmsId, doc.filename).subscribe();
        });
      }
    });
  }

  isAdmin(): boolean {
    return this.authService.isAdmin();
  }

  isGdsPartner(): boolean {
    return this.authService.getRole() === Role.GDS_PARTNER;
  }

  private updateDownloadedDocumentsCounter(): void {
    this.main.activeItem.unreadDocumentsCount = this.activeDocuments.filter(
      (d) => !d.downloaded
    ).length;
  }

  onFileChange(event) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    this.file = event.target.files[0];
  }

  upload(): void {
    if (this.uploadDocForm.valid) {
      this.documentService
        .upload(this.gmsId, this.file, this.uploadDocForm.get('title').value)
        .subscribe(
          (docs) => {
            this.activeDocuments = docs;
            this.updateDownloadedDocumentsCounter();
            this.dataSource = new MatTableDataSource(this.activeDocuments);
          },
          (error) => {
            console.error('Error during uploading file: ' + error.error);
          }
        );
      this.uploadDocForm.reset();
    }
  }
}

export function pdfValidator(
  control: AbstractControl
): ValidationErrors | null {
  const name = control.value;
  if (name) {
    const extension = name.split('.')[1].toLowerCase();
    if ('pdf' !== extension.toLowerCase()) {
      return {
        requiredFileType: true,
      };
    }

    return null;
  }

  return null;
}
