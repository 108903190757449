import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RegistrationService } from '../../services/registration.service';
import { Countries } from './countries';
import { environment } from '../../../environments/environment';
import { checkPasswordRequirements, mustMatch } from '../../utils/password-utils';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css'],
})
export class RegistrationComponent implements AfterViewInit {
  registrationForm: UntypedFormGroup;
  countries = Countries;
  termsAndConditions = environment.termsAndConditions;
  registrationLoading = false;
  registrationSent = false;
  registrationError: string = null;
  protected readonly window = window;

  constructor(fb: UntypedFormBuilder, private registrationService: RegistrationService) {
    this.registrationForm = fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        firstname: '',
        lastname: '',
        company: '',
        position: '',
        addressLine: '',
        postalCode: '',
        city: '',
        country: '',
        password: ['', [Validators.required, checkPasswordRequirements()]],
        confirmPassword: ['', [Validators.required]],
        acceptedTerms: [false, [Validators.requiredTrue]],
        mtcaptchaToken: ['', [Validators.required]],
      },
      {
        validators: [mustMatch('password', 'confirmPassword', true)],
      }
    );
  }

  ngAfterViewInit() {
    // @ts-ignore
    if (window.mtcaptcha != null) {
      // @ts-ignore
      document.onclick = window.mtcaptcha.renderUI();
      //Force re-render the captcha, otherwise it is not shown, if the component is used more than once, but not reloaded
    }
  }

  register() {
    this.registrationLoading = true;
    this.registrationService
      .register({
        username: this.registrationForm.get('email').value,
        password: this.registrationForm.get('password').value,
        personalData: {
          email: this.registrationForm.get('email').value,
          addressLine: this.registrationForm.get('addressLine').value,
          city: this.registrationForm.get('city').value,
          company: this.registrationForm.get('company').value,
          country: this.registrationForm.get('country').value,
          firstname: this.registrationForm.get('firstname').value,
          lastname: this.registrationForm.get('lastname').value,
          position: this.registrationForm.get('position').value,
          postalCode: this.registrationForm.get('postalCode').value,
        },
        captchaToken: this.registrationForm.get('mtcaptchaToken').value,
      })
      .subscribe({
        next: (resp) => {
          this.registrationLoading = false;
          this.registrationSent = true;

          if (resp.sendMailError) {
            this.registrationError = 'The registration succeeded, but we were unable to send a confirmation-email - please contact the support';
          } else {
            this.registrationError = null;
          }
        },
        error: (error) => {
          this.registrationSent = true;
          this.registrationError = 'The registration failed - ' + error.error;
        },
      });
  }

  onTermsToggle() {
    this.registrationForm.patchValue({
      acceptedTerms: !this.registrationForm.get('acceptedTerms').value,
    });
  }

  saveMtcaptchaToken() {
    let token = (document.getElementById('mtcaptchaToken') as HTMLInputElement).value;
    this.registrationForm.patchValue({ mtcaptchaToken: token });
  }
}
