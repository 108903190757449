import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {MainService} from '../../services/main.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {GmsChartService} from '../../services/gms-chart.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ChartData} from '../../models/gms/chart-data';
import {AppStatusData} from '../../models/gms/app-status-data';
import {AuthService} from '../../auth/auth.service';
import {Timeseries} from '../../models/gms/timeseries';
import {HighchartComponent} from "../highchart/highchart.component";
import {GmsInfoDirective} from "../_shared/gms-info/gms-info.directive";

@Component({
    selector: 'app-status',
    templateUrl: './status.component.html',
    styleUrls: ['./status.component.css']
})
export class StatusComponent extends GmsInfoDirective implements OnInit, OnChanges, OnDestroy {
    chart: any;
    chartData: ChartData;
    chartDateRange: UntypedFormGroup;
    chartDateMin: Date;
    chartDataMax: Date;
    updateFlag: boolean;
    @ViewChild(HighchartComponent) highchartComponent: HighchartComponent;

    startDate: Date;
    endDate: Date;
    zoomedStartDate: Date;
    zoomedEndDate: Date;

    appStatusCharts: any[];

    engineIndex: number;
    showSpinner = false;

    currentlyUpdating = false;
    noAppStatusAvailable = false;

    rpmMode = false;

    routerEventSubscription: any;
    statusChartSubscription: any;
    appStatusChartSubscription: any;
    gmsContextSubscription: any;
    downloadInProgress = false;

    constructor(public main: MainService,
                private route: ActivatedRoute,
                public router: Router,
                private authService: AuthService,
                private gmsChartService: GmsChartService,
                private fb: UntypedFormBuilder) {
        super(main, router);
        const currentDate = new Date();
        this.chartDateMin = this.authService.getEarliestDateAllowedForRole();
        this.chartDataMax = currentDate;

        this.chartDateRange = this.fb.group({
            start: [undefined, []],
            end: [undefined, []],
        });

        this.routerEventSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart && event.url.includes('/status')) {
                // console.log('status: ' + event.url);
                const regexMatches = event.url.match('.+\\/(.)\\/status');
                if (regexMatches && regexMatches.length > 0) {
                    // console.log(regexMatches[1]);
                    this.init(Number(regexMatches[1]));
                }
                else {
                    this.init();
                }

            }
        });

    }

    ngOnInit(): void {
        super.ngOnInit();

        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes);
        if (changes.id && changes.engineIndex) {
            this.init();
        }
    }

    init(engineIndex?: number) {
        this.gmsId = this.route.snapshot.paramMap.get('id');
        // console.log('status page: init() call with gmsId ' + this.gmsId);

        if (engineIndex !== undefined) {
            this.engineIndex = engineIndex;
        } else {
            this.engineIndex = Number(this.route.snapshot.paramMap.get('engineIndex'));
        }

        // console.log('engineIndex = ' + this.engineIndex);

        this.main.setActiveById(this.gmsId, this.engineIndex);
        this.setDefaultChartBasedOn24HoursBeforeLastConnected();
        this.setAppStatusCharts();
    }

    debug($event: Event) {
        console.log($event);
    }

    dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
        if (dateRangeStart.value && dateRangeEnd.value) {

            // const currentDate = new Date();
            const startDateWithoutTime = new Date(dateRangeStart.value);
            const endDateWithoutTime = new Date(dateRangeEnd.value);
            const startDate = new Date(Date.UTC(startDateWithoutTime.getFullYear(), startDateWithoutTime.getMonth(),
                startDateWithoutTime.getDate(),
                0, 0, 0));
            const endDate = new Date(Date.UTC(endDateWithoutTime.getFullYear(), endDateWithoutTime.getMonth(), endDateWithoutTime.getDate(),
                23, 59, 59));

            this.chartDateChange(startDate, endDate, false);
        }
    }

    chartDateChange(startDate: Date, endDate: Date, chartTypeChanged: boolean) {
        // const startDate = startDateWithoutTime;
        // const endDate = endDateWithoutTime;

        // console.log('startDate: ' + startDate);
        // console.log('endDate: ' + endDate);
        // this.showSpinner = true;

        if (chartTypeChanged && this.chartData && this.startDate === startDate && this.endDate === endDate) {
            if (this.rpmMode) {
                this.highchartComponent.hideResetZoomButton();
            }
            else if (this.zoomedStartDate !== null || this.zoomedEndDate !== null) {
                this.highchartComponent.showResetZoomButton();
            }

            this.updateChartSeries(this.chartData, chartTypeChanged);
        } else {
            if (!this.currentlyUpdating) {
                this.currentlyUpdating = true;
                if (this.statusChartSubscription) {
                    this.statusChartSubscription.unsubscribe();
                }

                if (startDate === this.chartDateRange.get('start').value && endDate === this.chartDateRange.get('end').value) {
                    this.resetZoomedTimeRange();
                }
                else {
                    this.setZoomedTimeRange(startDate, endDate);
                }

                this.startDate = startDate;
                this.endDate = endDate;
                this.statusChartSubscription = this.gmsChartService.getStatusChart(this.gmsId, this.engineIndex, startDate, endDate)
                    .subscribe(chartData => {
                            // this.showSpinner = false;
                            // this.buildChart(chartData);
                            this.updateChartSeries(chartData, chartTypeChanged);
                            this.currentlyUpdating = false;
                        },
                        error => {
                            console.log(error);
                        },
                        () => {
                            this.currentlyUpdating = false;
                        });
            }
        }
    }

    setDefaultChartBasedOn24HoursBeforeLastConnected() {
        this.chart = undefined;
        // console.log('setDefaultChartBasedOn24HoursBeforeLastConnected');
        if (this.main.activeEngine && this.main.activeEngine.lastUpdated) {
            const lastConnectedDate = new Date(this.main.activeEngine.lastUpdated);
            const defaultStartDate = new Date(lastConnectedDate.getFullYear(),
                lastConnectedDate.getMonth(),
                lastConnectedDate.getDate() - 1,
                lastConnectedDate.getHours(),
                lastConnectedDate.getMinutes(),
                lastConnectedDate.getSeconds());
            // const defaultEndDate = new Date(lastConnectedDate.getFullYear(),
            //     lastConnectedDate.getMonth(),
            //     lastConnectedDate.getDate() + 1, 0, 0, 0);

            // this.startDate = defaultStartDate;
            // this.endDate = lastConnectedDate;

            if (defaultStartDate > this.authService.getEarliestDateAllowedForRole()) {
                this.chartDateRange.patchValue({start: defaultStartDate, end: lastConnectedDate});

                this.statusChartSubscription = this.gmsChartService.getStatusChart(this.gmsId, this.engineIndex,
                    defaultStartDate, lastConnectedDate)
                    .subscribe(chartData => {
                        this.buildChart(chartData);
                    });
            } else {
                const chartData = new ChartData();
                chartData.timeseries = new Timeseries();
                this.buildChart(chartData);
            }
        }
    }

    last24Hours() {
        const endDate = new Date();
        const startDate = new Date(endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate() - 1,
            endDate.getHours(),
            endDate.getMinutes(),
            endDate.getSeconds());

        this.chartDateRange.patchValue({start: startDate, end: endDate});
        this.chartDateChange(startDate, endDate, false);
    }

    lastWeek() {
        const endDate = new Date();
        const startDate = new Date(endDate.getFullYear(),
            endDate.getMonth(),
            endDate.getDate() - 7,
            endDate.getHours(),
            endDate.getMinutes(),
            endDate.getSeconds());

        this.chartDateRange.patchValue({start: startDate, end: endDate});
        this.chartDateChange(startDate, endDate, false);
    }

    lastMonth() {
        const endDate = new Date();
        const startDate = new Date(endDate.getFullYear(),
            endDate.getMonth() - 1,
            endDate.getDate(),
            endDate.getHours(),
            endDate.getMinutes(),
            endDate.getSeconds());

        this.chartDateRange.patchValue({start: startDate, end: endDate});
        this.chartDateChange(startDate, endDate, false);
    }

    updateChartSeries(chartData: ChartData, chartTypeChanged) {
        if (this.chart && !chartTypeChanged) {

            this.chartData = chartData;
            this.chart.series.forEach(s => {
                if (chartData.timeseries.series[s.name]) {
                    s.data = MainService.combineTimeData(chartData.timeseries.date, chartData.timeseries.series[s.name]);

                    if (this.rpmMode) {
                        s.data = MainService.combineData(chartData.timeseries.series['rpm'], chartData.timeseries.series[s.name]);
                        this.chart.xAxis[0].type = undefined;
                        this.chart.plotOptions.scatter.tooltip.pointFormat = 'x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>';
                    } else {
                        s.data = MainService.combineTimeData(chartData.timeseries.date, chartData.timeseries.series[s.name]);
                        this.chart.xAxis[0].type = 'datetime';
                        this.chart.plotOptions.scatter.tooltip.pointFormat = 'x: <b>{point.x:%Y-%m-%d %H:%M:%S.%L}</b><br/>y: <b>{point.y}</b><br/>';
                    }
                }
            });
            // console.log(this.chart.series);
            this.updateFlag = true;
        } else {
            this.buildChart(chartData);
        }
    }

    testEvent(chart: any, event: any) {
        console.log(event);
    }

    setAppStatusCharts() {
        const lastConnectedDate = new Date(this.main.activeEngine.lastUpdated);
        const earliestDateAllowed = this.authService.getEarliestDateAllowedForRole();

        if (lastConnectedDate > earliestDateAllowed) {
            this.noAppStatusAvailable = false;
            this.appStatusChartSubscription = this.gmsChartService
                .getAppStatus(this.gmsId, this.engineIndex, earliestDateAllowed, lastConnectedDate)
                .subscribe(appStatusDataList => {
                    this.buildStatusCharts(appStatusDataList);
                });
        } else {
            this.noAppStatusAvailable = true;
        }
    }

    buildStatusCharts(appStatusDataList: AppStatusData[]) {
        this.appStatusCharts = [];

        appStatusDataList.forEach(appStatusData => {

            const data = [
                ['good', appStatusData.distribution['good']],
                ['warning', appStatusData.distribution['warning']],
                ['alarm', appStatusData.distribution['alarm']],
            ];

            this.appStatusCharts.push({
                id: 'chartApp1',
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    height: 170,
                    width: 340
                },
                title: {
                    text: 'Status<br>' + appStatusData.title,
                    align: 'center',
                    verticalAlign: 'middle',
                    y: 70
                },
                tooltip: {
                    pointFormat: '{point.percentage:.2f}%</b>'
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: true,
                            format:  '{point.name}: {point.percentage:.2f}%',
                            distance: -30,
                            style: {
                                fontWeight: 'bold',
                                fontSize: 14,
                                color: 'white'
                            }
                        },
                        startAngle: -90,
                        endAngle: 90,
                        center: ['50%', '130%'],
                        size: '275%'
                    }
                },
                series: [{
                    type: 'pie',
                    title: 'Status',
                    innerSize: '60%',
                    data
                }]
            });
        });
    }

    buildChart(chartData: ChartData) {

        this.chartData = chartData;

        // console.log('buildChart with rpmMode=' + this.rpmMode);
        this.chart = undefined;
        const series = [];
        let xAxisType;
        let pointFormat;
        if (chartData.timeseries.series) {
            Object.keys(chartData.timeseries.series).forEach((key, value) => {
                let data;

                if (this.rpmMode) {
                    if (key === 'rpm') {
                        return;
                    }
                    data = MainService.combineData(chartData.timeseries.series['rpm'], chartData.timeseries.series[key]);
                    xAxisType = undefined;
                    pointFormat = 'x: <b>{point.x}</b><br/>y: <b>{point.y}</b><br/>';
                } else {
                    data = MainService.combineTimeData(chartData.timeseries.date, chartData.timeseries.series[key]);
                    xAxisType = 'datetime';
                    pointFormat = 'x: <b>{point.x:%Y-%m-%d %H:%M:%S.%L}</b><br/>y: <b>{point.y}</b><br/>';
                }

                series.push({
                    type: 'scatter',
                    name: key,
                    visible: key.includes('rpm') || key.includes('twist') || series.length < 1,
                    data
                });
            });
        }

        // console.log(series);

        this.chart = {
            id: 'statusApp3Chart',
            chart: {
                styledMode: true,
                zoomType: 'x',
                panning: true,
                panKey: 'shift',
                resetZoomButton: {position: {x: 0, y: -30}},
                updateFlag: false,
                oneToOne: true,
                events: {
                    selection: function(event) {
                        if (this.rpmMode) {
                            this.highchartComponent.hideResetZoomButton();

                            if (event.xAxis) {
                                // On zoom, return undefined to continue with event handling
                                return;
                            }
                            else {
                                 // return false to prevent any further event handling
                                return false;
                            }
                        }

                        if (event.xAxis) {
                            // zoom
                            // console.log('zoom');
                            // console.log(event);
                            // console.log('min: ' + event.xAxis[0].min);
                            // console.log('max: ' + event.xAxis[0].max);

                            const startDate = new Date(Math.floor(event.xAxis[0].min));
                            const endDate = new Date(Math.ceil(event.xAxis[0].max));
                            // make sure that the zoom button is always visible on zoom in time domain:
                            this.highchartComponent.showResetZoomButton();
                            this.chartDateChange(startDate, endDate, false);
                        } else {
                            // zoom reset
                            // console.log('reset');
                            // console.log(event);
                            this.chartDateChange(this.chartDateRange.get('start').value, this.chartDateRange.get('end').value, false);
                            this.highchartComponent.hideResetZoomButton();
                        }
                    }.bind(this),
                    redraw: function(event) {
                        // Make sure that there is no "Reset zoom" button whenever we are in the RPM domain:
                        if (this.rpmMode) {
                            this.highchartComponent.hideResetZoomButton();
                            return;
                        }
                    }.bind(this)
                }
            },
            plotOptions: {
                scatter: {
                    tooltip: {
                        pointFormat
                    }
                },
                series: {
                    events: {
                        hide: function(event) {
                            // console.log('hide');
                            this.updateChartSeriesVisibility(event.target.chart.series.filter(s => s.visible).map(s => s.name));
                            // console.log(event.target.chart.series.filter(s => s.visible).map(s => s.name));
                            // console.log(event);
                        }.bind(this),
                        show: function(event) {
                            this.updateChartSeriesVisibility(event.target.chart.series.filter(s => s.visible).map(s => s.name));
                        }.bind(this)
                    }
                }
            },
            tooltip: {
                shared: true,
                useHTML: true,
                positioner() {
                    return {x: 0, y: 0};
                }
            },
            credits: {enabled: false},
            legend: {enabled: true},
            title: {text: null},
            defs: {
                gradientWhiteTransparent: {
                    tagName: 'linearGradient',
                    id: 'gradientWhiteTransparent', x1: 0, y1: 0, // x2: 0, y2: 1,
                    children: [{
                        tagName: 'stop',
                        offset: 0
                    }, {
                        tagName: 'stop',
                        offset: 1
                    }]
                }
            },
            // annotations: [
            //     {
            //         labelOptions: {
            //             className: 'warning-annotation',
            //             verticalAlign: 'top',
            //             align: 'left',
            //             padding: 7,
            //             x: 10,
            //             y: -44,
            //             borderRadius: 0,
            //             useHTML: true,
            //             outside: true
            //         },
            //         labels: [{
            //             point: {
            //                 xAxis: 0,
            //                 yAxis: 0,
            //                 x: Date.UTC(2020, 10, 15, 17, 32, 5),
            //                 y: 19.054932 + 8
            //             },
            //             text: '<a href="#statusWarnApp3Chart">WARNING</a>'
            //         }]
            //     },
            //     {
            //         labelOptions: {
            //             className: 'warning-annotation',
            //             verticalAlign: 'top',
            //             align: 'right',
            //             padding: 7,
            //             x: 10,
            //             y: -44,
            //             borderRadius: 0,
            //             useHTML: true,
            //             outside: true
            //         },
            //         labels: [{
            //             point: {
            //                 xAxis: 0,
            //                 yAxis: 0,
            //                 x: Date.UTC(2020, 10, 15, 17, 58, 40),
            //                 y: 19.054932 + 3
            //             },
            //             text: '<a href="#statusWarnApp3Chart">WARNING</a>'
            //         }]
            //     },
            // ],
            // yAxis: [{
            //     max: 200
            // }],
            xAxis: [{
                type: xAxisType,
                title: {
                    enabled: true,
                    text: this.rpmMode ? 'rpm' : 'timestamp'
                }
            }],
            series
        };

        // console.log(this.chart.series);
    }

    private updateChartSeriesVisibility(visibleSeries: string[]) {
        this.chart.series.forEach(s => {
            s.visible = visibleSeries.includes(s.name);
        });
    }

    ngOnDestroy(): void {
        if (this.routerEventSubscription) {
            this.routerEventSubscription.unsubscribe();
        }
        if (this.statusChartSubscription) {
            this.statusChartSubscription.unsubscribe();
        }
        if (this.appStatusChartSubscription) {
            this.appStatusChartSubscription.unsubscribe();
        }
        if (this.gmsContextSubscription) {
            this.gmsContextSubscription.unsubscribe();
        }

        this.chart = undefined;
        this.appStatusCharts = undefined;

        super.ngOnDestroy();
    }

    toggleChartType() {
        // console.log('toggleChartType');
        this.rpmMode = !this.rpmMode;
        if (this.startDate !== undefined && this.endDate !== undefined) {
            this.chartDateChange(this.startDate, this.endDate, true);
        } else {
            this.setDefaultChartBasedOn24HoursBeforeLastConnected();
        }

    }

    downloadCsv() {
        console.log('clicked on csv download button');
        const range = this.getVisibleTimeRange();

        if (!range) {
            console.log('Cannot download csv file without range')
            return;
        }

        const [start, end] = range;
        this.downloadInProgress = true;

        this.gmsChartService.downloadStatusChartAsCsv(this.gmsId, this.engineIndex, start, end)
            .subscribe(
                res => {
                    this.downloadInProgress = false;
                    const url = window.URL.createObjectURL(res);
                    const a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display: none');
                    a.href = url;
                    a.download = this.gmsId + '-chart-data.csv';
                    a.click();
                    window.URL.revokeObjectURL(url);
                    a.remove(); // remove the element
                }, error => {
                    console.log('download error:', JSON.stringify(error));
                    console.log(error);
                }, () => {
                    console.log('Completed file download.');
                });

    }

    private getVisibleTimeRange(): Date[] {
        if (!this.highchartComponent) {
            return null;
        }

        return [ this.zoomedStartDate ?? this.chartDateRange.get('start').value, this.zoomedEndDate ?? this.chartDateRange.get('end').value];
    }

    private setZoomedTimeRange(start: Date, end: Date) {
        this.zoomedStartDate = start;
        this.zoomedEndDate = end;
        this.highchartComponent?.setSubtitle(`Zoomed to time range from ${start.toUTCString()} to ${end.toUTCString()}`);
    }

    private resetZoomedTimeRange() {
        this.zoomedStartDate = null;
        this.zoomedEndDate = null;
        this.highchartComponent?.setSubtitle();
    }
}
