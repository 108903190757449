<div class="g-head g-hero">
  <div class="g-hero-text">
    <div class="text">
      <div class="logo"><img src="assets/geislinger-logo.svg" /></div>
    </div>
  </div>
  <div class="g-hero-headline"><h1>Edit User</h1></div>
</div>
<div class="main-container">
  <form *ngIf="!(loadingUserData || loadingGms || loadingAccountManagers)" [formGroup]="userEditForm">
    <div class="g-user-data">
      <div class="g-user-data-row">
        <p class="g-user-data-col">Email:</p>
        <p class="g-user-data-col">{{ user?.username }}</p>
      </div>
      <div class="g-user-data-row">
        <p class="g-user-data-col">First Name:</p>
        <p class="g-user-data-col">{{ user?.personalData?.firstname }}</p>
      </div>
      <div class="g-user-data-row">
        <p class="g-user-data-col">Last Name:</p>
        <p class="g-user-data-col">{{ user?.personalData?.lastname }}</p>
      </div>
      <div class="g-user-data-row">
        <p class="g-user-data-col">Company:</p>
        <p class="g-user-data-col">{{ user?.personalData?.company }}</p>
      </div>
      <div class="g-user-data-row">
        <p class="g-user-data-col">Position:</p>
        <p class="g-user-data-col">{{ user?.personalData?.position }}</p>
      </div>
      <div class="g-user-data-row">
        <p class="g-user-data-col">Address:</p>
        <p class="g-user-data-col">{{ user?.personalData?.addressLine }}</p>
      </div>
      <div class="g-user-data-row">
        <p class="g-user-data-col">ZIP:</p>
        <p class="g-user-data-col">{{ user?.personalData?.postalCode }}</p>
      </div>
      <div class="g-user-data-row">
        <p class="g-user-data-col">City:</p>
        <p class="g-user-data-col">{{ user?.personalData?.city }}</p>
      </div>
      <div class="g-user-data-row">
        <p class="g-user-data-col">Country:</p>
        <p class="g-user-data-col">{{ user?.personalData?.country }}</p>
      </div>
      <div class="g-user-edit-form-field">
        <p class="g-user-data-col">Role:</p>
        <mat-form-field class="full-width-input g-user-data-col">
          <mat-select formControlName="role" [disabled]="!(isAdmin || isGdsPartner)" [panelWidth]="null">
            <mat-option *ngFor="let role of roles" [value]="role">{{ role }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="g-user-edit-form-field">
        <p class="g-user-data-col">GMS:</p>
        <mat-form-field class="full-width-input g-user-data-col">
          <mat-select multiple formControlName="gmsIds" [panelWidth]="null">
            <mat-option *ngFor="let gms of gmsListNonClassified" [value]="gms.id">{{ gms.longTitle }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div *ngIf="possibleAccountManagers.length > 0" class="g-user-edit-form-field">
        <p class="g-user-data-col">Account Manager:</p>
        <mat-form-field class="full-width-input g-user-data-col">
          <mat-select formControlName="accountManager" [disabled]="!isAdmin" [panelWidth]="null">
            <mat-option [value]="null"></mat-option>
            <mat-option *ngFor="let accountManager of possibleAccountManagers" [value]="accountManager">{{ accountManager }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <button class="g-button g-form-main-button" type="submit" (click)="applyChanges()" [disabled]="userEditForm.pristine" [ngClass]="userEditForm.pristine ? 'g-disabled' : ''">
      Apply
    </button>
    <div class="g-gms-request-list">
      <h3>Pending GMS Requests</h3>
      <mat-spinner *ngIf="loadingGmsRequests"></mat-spinner>
      <mat-table *ngIf="!loadingGmsRequests && gmsRequestDataSource?.data?.length > 0" [dataSource]="gmsRequestDataSource" matSort #gmsRequestTbSort="matSort">
        <ng-container matColumnDef="gms">
          <mat-header-cell *matHeaderCellDef mat-sort-header>GMS</mat-header-cell>
          <mat-cell *matCellDef="let gmsRequest">
            <button mat-button (click)="onClickGmsRequest(gmsRequest.token)">
              <span>{{ gmsRequest.gmsTitle }}</span>
              <mat-icon class="g-warning-icon" *ngIf="!gmsRequest.gmsExists">warning</mat-icon>
            </button>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="creationDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Request Date</mat-header-cell>
          <mat-cell *matCellDef="let gmsRequest">{{ gmsRequest.creationDate | date : 'yyyy-MM-dd HH:mm' }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedGmsRequestColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedGmsRequestColumns"></mat-row>
      </mat-table>
      <h6 *ngIf="!gmsRequestDataSource?.data">No Requests found</h6>
    </div>
    <div *ngIf="userIsCustomerManager" class="g-sub-user-list">
      <h3>Sub-Users</h3>
      <mat-spinner *ngIf="loadingSubUsers"></mat-spinner>
      <mat-table *ngIf="!loadingSubUsers && subUserDataSource?.data.length > 0" [dataSource]="subUserDataSource" matSort #subUserTbSort="matSort">
        <ng-container matColumnDef="username">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.username }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="firstname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>First Name</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.personalData?.firstname }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="lastname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.personalData?.lastname }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="company">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Company</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.personalData?.company }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="position">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Position</mat-header-cell>
          <mat-cell *matCellDef="let user">{{ user.personalData?.position }} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedSubUserColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedSubUserColumns"></mat-row>
      </mat-table>
      <mat-paginator *ngIf="subUserDataSource?.data.length > 0" #subUserTbPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
      <h6 *ngIf="subUserDataSource?.data.length === 0">No Sub-Users found</h6>
    </div>
  </form>
</div>
<mat-spinner *ngIf="loadingUserData || loadingGms || loadingAccountManagers"></mat-spinner>
