import { Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, Output, SimpleChanges, EventEmitter } from "@angular/core";
import * as Highcharts from "highcharts";
import Boost from "highcharts/modules/boost";
import Annotations from "highcharts/modules/annotations";
import noData from "highcharts/modules/no-data-to-display";
import More from "highcharts/highcharts-more";

declare var require: any;

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);
Annotations(Highcharts);

@Component({
  selector: "app-highchart",
  templateUrl: "./highchart.component.html",
  styleUrls: ["./highchart.component.css"],
})
export class HighchartComponent implements OnDestroy, OnChanges {
  Highcharts: typeof Highcharts = Highcharts;
  @Input() constructorType: string;
  @Input() callbackFunction: Highcharts.ChartCallbackFunction;
  @Input() oneToOne: boolean; // #20
  @Input() runOutsideAngular: boolean; // #75
  @Input() options: Highcharts.Options;
  @Input() update: boolean;

  @Output() updateChange = new EventEmitter<boolean>(true);
  @Output() chartInstance = new EventEmitter<Highcharts.Chart>(); // #26

  private chart: Highcharts.Chart;

  constructor(
    private el: ElementRef,
    private _zone: NgZone // #75
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const update = changes.update && changes.update.currentValue;
    if (changes.options || update) {
      this.wrappedUpdateOrCreateChart();
      if (update) {
        this.updateChange.emit(false); // clear the flag after update
      }
    }
  }

  wrappedUpdateOrCreateChart() {
    // #75
    if (this.runOutsideAngular) {
      this._zone.runOutsideAngular(() => {
        this.updateOrCreateChart();
      });
    } else {
      this.updateOrCreateChart();
    }
  }

  updateOrCreateChart() {
    if (this.chart && this.chart.update) {
      this.chart.update(this.options, false, this.oneToOne || false);
      this.chart.xAxis?.[0].setExtremes();
    } else {
      this.chart = (this.Highcharts as any)[this.constructorType || "chart"](this.el.nativeElement, this.options, this.callbackFunction || null);

      // emit chart instance on init
      this.chartInstance.emit(this.chart);
    }
  }

  showResetZoomButton() {
    const resetZoomButton = (this.chart as any).resetZoomButton;

    const buttonIsMissing = !resetZoomButton?.added;
    const buttonIsHidden = resetZoomButton?.visibility === "hidden";

    if (buttonIsMissing || buttonIsHidden) {
      this.chart.showResetZoom();
    }
  }

  hideResetZoomButton() {
    (this.chart as any).resetZoomButton?.hide();
  }

  setSubtitle(text?: string) {
    if (text) {
      this.chart.setSubtitle({ text });
    } else {
      this.chart.setSubtitle({ text: "" });
    }
  }

  ngOnDestroy() {
    // #44
    if (this.chart) {
      // #56
      this.chart.destroy();
      this.chart = null;
    }
  }
}
