<mat-spinner *ngIf="!main.activeItem"></mat-spinner>
<div *ngIf="main.activeItem">
    <div class="g-head g-hero" *ngIf="main.activeItem" [ngClass]="main.activeItem.status">
        <div class="g-hero-text">
            <div class="text">
                <div class="logo"><img src="assets/geislinger-logo.svg"></div>
                <h3>{{main.activeItem.designation}}</h3>
                <h5 class="damper-id"><b>{{main.activeItem.projectInfo}}</b></h5>
            </div>
        </div>
        <div class="g-hero-headline"><h1>Documents</h1></div>
    </div>

    <div class="g-head g-form" *ngIf="isAdmin() || isGdsPartner()">
        <h3 class="g-headline">Upload a new document (PDF)</h3>
        <form [formGroup]="uploadDocForm">
            <mat-form-field>
                <mat-label>Title</mat-label>
                <input matInput
                       type="text"
                       placeholder="Title"
                       formControlName="title"
                       required>
                <mat-error>
                    Title is required (Min length: 3 characters)
                </mat-error>
            </mat-form-field>

            <input id="file" formControlName="file" required type="file" (click)="$event.stopPropagation()"
                   (change)="onFileChange($event)" accept=".pdf">

            <button class="g-button g-form-main-button" [ngClass]="!uploadDocForm.valid ? 'g-disabled':''"
                    [disabled]="!uploadDocForm.valid" (click)="upload()">
                Submit
            </button>
            <div class="g-status warn error" *ngIf="uploadError">ERROR: {{uploadError}}</div>
        </form>
    </div>
    <div class="g-padding-content" *ngIf="isAdmin() || isGdsPartner()"></div>

    <div class="columns">
        <!--SELECT ALL CHECKBOX-->
        <div class="select-all-checkbox">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="masterToggle($event.checked)"
                          [checked]="isAllSelected()"
                          [indeterminate]="isSomeSelected() && !isAllSelected()"
                          [aria-label]="checkboxLabel()">
            </mat-checkbox>
        </div>

        <!--DELETE ALL SELECTED-->
        <span *ngIf="isAdmin() && this.selection.selected.length > 0">
            <button mat-icon-button (click)="openDeleteSelectedDocumentsDialog(selection.selected.length)">
                <mat-icon>delete</mat-icon>
            </button>
        </span>

        <!--SEARCH INPUT-->
        <div>
            <mat-form-field>
                <mat-label>Search</mat-label>
                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
            </mat-form-field>
            <mat-icon>search</mat-icon>
        </div>
    </div>

    <!--TABLE-->
    <table mat-table *ngIf="main.activeItem"
           [dataSource]="dataSource" multiTemplateDataRows
           class="mat-elevation-z8">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()"
                              (change)="$event ? selection.toggle(row) : null"
                              [checked]="selection.isSelected(row)"
                              [aria-label]="checkboxLabel(row)">
                </mat-checkbox>
            </td>
        </ng-container>

        <!-- Title Column -->
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>Title</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.downloaded}">{{row.title}}</td>
        </ng-container>

        <!-- Filename Column -->
        <ng-container matColumnDef="filename">
            <th mat-header-cell *matHeaderCellDef>Filename</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.downloaded}">{{row.filename}}</td>
        </ng-container>

        <!-- Time Column -->
        <ng-container matColumnDef="uploadDate">
            <th mat-header-cell *matHeaderCellDef>Upload date</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.downloaded}">{{row.uploadDate | date : 'y-MM-dd hh:mm:ss a zzzz'}}</td>
        </ng-container>

        <!-- Download Column -->
        <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>Download</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.downloaded}">
                <button mat-icon-button (click)="row.downloaded = true; download(row.gmsId, row.filename)">
                    <mat-icon>download</mat-icon>
                </button>
            </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef>Delete</th>
            <td mat-cell *matCellDef="let row"
                [ngClass]="{'message-unread': !row.downloaded}">
                <button mat-icon-button *ngIf="isAdmin()" (click)="openDeleteDocumentDialog(row.filename)">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>

        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"></tr>

        <!-- Row shown when there is no matching data. -->
        <ng-container *ngIf="input.value">
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No document matching your filter "{{input.value}}"</td>
            </tr>
        </ng-container>
        <ng-container *ngIf="!input?.value">
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">None</td>
            </tr>
        </ng-container>
    </table>

</div>
