<div class="g-hero no-img">
    <div class="g-hero-text">
        <h1 class="fleet-overview">Fleet Overview</h1>
        <a routerLink="/dashboard-settings">
            <button *ngIf="main.gmsList.length > 2" type="button" class="g-button dashboard-settings-button">
                <mat-icon class="settings-icon">settings</mat-icon>
                Dashboard Settings
            </button>
        </a>
    </div>
</div>
<div class="g-container">
    <mat-spinner *ngIf="showGmsLoadingSpinner()"></mat-spinner>
    <div class="ship-stack" *ngIf="!showGmsLoadingSpinner()">
        <div class="ship" *ngFor="let ship of (main.favoriteGmsList.length > 0 ? main.favoriteGmsList : main.gmsList)"
             [ngClass]="ship.status"
             [class.end-of-life]="ship.maintenanceBar>=0.75">
            <app-ship-classic *ngIf="!main.compactView || main.gmsList.length < 3" [ship]="ship" [isUsingLightMode]="main.lightMode$ | async"></app-ship-classic>
            <app-ship-compact *ngIf="main.compactView && main.gmsList.length > 2" [ship]="ship"></app-ship-compact>
        </div>
    </div>
</div>
