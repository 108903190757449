import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-connection-icon',
  template: `
    <ng-container [ngSwitch]="input">
      <ng-container *ngSwitchCase="'no-signal'"><app-icon [name]="'no_connection'"></app-icon></ng-container>
      <ng-container *ngSwitchCase="'upload'"><app-icon [name]="'upload'"></app-icon></ng-container>
      <ng-container *ngSwitchDefault><app-icon [ngClass]="input" [name]="'connection'"></app-icon></ng-container>
    </ng-container>
  `,
  styles: [],
})
export class ConnectionIconComponent {
  @Input() input;
}
