export class FileMeta {
    gmsId: string;
    filename: string;
    status: Status;
    message: string;
    size: number;
    uploadDate: Date;

    // only used in frontend:
    isUploading: boolean;
    uploadProgress: number;
    uploadError: boolean;
}

export enum Status {
    UNKNOWN = 'UNKNOWN',
    UPLOADING = 'UPLOADING',
    ALREADY_UPLOADED = 'ALREADY_UPLOADED',
    DONE = 'DONE',
    ERROR = 'ERROR',
    ERR_FILENAME = 'ERR_FILENAME',
    ERR_CONTENT = 'ERR_CONTENT',
    ERR_PERMISSIONS = 'ERR_PERMISSIONS',
    ERR_FILESIZE = 'ERR_FILESIZE'
}

export interface IUploadedFile {
    file?: File;
    fileMeta?: FileMeta;
    error?: string;
}

