<div class="g-hero">
    <div class="g-hero-text">
        <h1>Geislinger Analytics Platform</h1>
    </div>
    <div class="g-hero-img sujet"></div>
</div>
<div class="g-head">
    <h3 class="g-headline">Something went wrong</h3>
    <h5>The resource you tried to access either does not exist or you do not have the required permissions</h5>
    <div class="g-login-link">
        <a routerLink="/login">back to login</a>
    </div>
</div>
