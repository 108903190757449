import {Component, Input} from '@angular/core';
import {GmsStatus} from "../models/gms/gms-status.enum";

@Component({
    selector: 'app-status-icon',
    template: `
        <ng-container [ngSwitch]="input">
            <ng-container *ngSwitchCase="GmsStatus.GOOD">
                <app-icon [name]="GmsStatus.GOOD"></app-icon>
            </ng-container>
            <ng-container *ngSwitchCase="GmsStatus.WARNING">
                <app-icon [name]="GmsStatus.WARNING"></app-icon>
            </ng-container>
            <ng-container *ngSwitchCase="GmsStatus.ALARM">
                <app-icon [name]="GmsStatus.ALARM"></app-icon>
            </ng-container>
        </ng-container>
    `,
    styles: []
})
export class StatusIconComponent {
    @Input() input: string;

    constructor() {
    }

    protected readonly GmsStatus = GmsStatus;
}
