export enum Role {
    LITE = 'LITE',
    BASIS = 'BASIS',
    PLUS = 'PLUS',
    ADMIN = 'ADMIN',
    DELIVERY = 'DELIVERY',
    AWAITING_2FA = 'AWAITING_2FA',
    GDS_PARTNER = 'GDS_PARTNER',
    CUSTOMER_MANAGER = 'CUSTOMER_MANAGER'
}
