import {environment} from "../../environments/environment";
import {NgModule} from '@angular/core';
import {AuthModule, LogLevel} from 'angular-auth-oidc-client';

@NgModule({
    imports: [
        AuthModule.forRoot({
            config: {
                triggerAuthorizationResultEvent: true,
                postLoginRoute: '/overview',
                forbiddenRoute: '/forbidden',
                unauthorizedRoute: '/forbidden',
                logLevel: LogLevel.Debug,
                historyCleanupOff: true,
                authority: 'https://account.navis.com',
                redirectUrl: window.location.origin + '/#/navis-login',
                postLogoutRedirectUri: window.location.origin + '/',
                clientId: environment.navisClientId,
                scope: 'openid email profile',
                responseType: 'code',
                silentRenew: true,
                useRefreshToken: true,
            },
        }),
    ],
    exports: [AuthModule],
})
export class AuthConfigModule {
}
