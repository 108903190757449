import {Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../auth/auth.service';
import {MainService} from '../../services/main.service';
import {TOKEN} from "../_shared/constants/local-storage-constants";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent  {

    constructor(public router: Router, private authService: AuthService, public main: MainService) {
    }

    logout() {
        localStorage.removeItem(TOKEN);
        // console.log('logout clicked');
        if (!this.authService.loggedIn()) {
          this.router.navigate(['/login']);
        }
    }

    isAdmin(): boolean {
        return this.authService.isAdmin();
    }

}
