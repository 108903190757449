import {Injectable} from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import {AuthService} from './auth.service';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {LOGOUT_REASON, TOKEN} from "../components/_shared/constants/local-storage-constants";

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(private authService: AuthService, private router: Router) {
    }

    // Add auth to every API call and catch 4xx errors
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // console.log('*An intercepted httpRequest*', req, this.authService.authHeader);
        const authReq = this.authService.authenticateRequest(req);
        // console.log('*Updated httpRequest*', authReq);
        return next.handle(authReq).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 403 || error.status === 401) {
                        localStorage.removeItem(TOKEN);
                        this.router.navigateByUrl('/error');
                    }
                    if (error.status === 417) {
                        localStorage.removeItem(TOKEN);
                        localStorage.setItem(LOGOUT_REASON, error.error.message)
                        this.router.navigateByUrl('/logout');
                    }
                }
                return throwError(error);
            })
        );
    }
}
