import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {MainService} from '../../services/main.service';
import {ActivatedRoute, NavigationStart, Router} from '@angular/router';
import {GmsChartService} from '../../services/gms-chart.service';
import {ChartData} from '../../models/gms/chart-data';
import {ConnectionStatusData} from '../../models/gms/connection-status-data';
import {AuthService} from '../../auth/auth.service';
import {Engine} from '../../models/gms/engine';
import {GmsInfoDirective} from "../_shared/gms-info/gms-info.directive";

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.css']
})
export class DetailComponent extends GmsInfoDirective implements OnChanges, OnInit, OnDestroy {
    statusChart: any[] = [undefined, undefined, undefined, undefined];
    connectionChart: any;
    anomalyChart: any[] = [undefined, undefined, undefined, undefined];

    connectionChartIsLoading: boolean;
    statusChartIsLoading: boolean[] = [true, true, true, true];

    routerEventSubscription: any;
    statusChartSubscription: any[] = [undefined, undefined, undefined, undefined];
    connectionChartSubscription: any;

    constructor(public main: MainService,
                private route: ActivatedRoute,
                public router: Router,
                private gmsChartService: GmsChartService,
                private authService: AuthService) {
        super(main, router);
        this.routerEventSubscription = router.events.subscribe((event) => {
            if (event instanceof NavigationStart && event.url.includes('/detail')) {
                // console.log('detail: ' + event);
                if (this.main.activeItem?.gmsId) {
                    // console.log(this.main.activeItem.gmsId);
                    this.gmsId = this.main.activeItem.gmsId;
                    this.setStatusAndAnomalyChartBasedOn24HoursBeforeLastConnected();
                    this.setConnectionChartBasedOnLast2Weeks();
                } else {
                    this.statusChart = undefined;
                    this.connectionChart = undefined;
                }
            }
        });
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.init();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.id) {
            this.init();
        }
    }

    init() {
        this.gmsId = this.route.snapshot.paramMap.get('id');

        this.main.setActiveById(this.gmsId, 0);
        this.setStatusAndAnomalyChartBasedOn24HoursBeforeLastConnected();
        this.setConnectionChartBasedOnLast2Weeks();
    }

    setStatusAndAnomalyChartBasedOn24HoursBeforeLastConnected() {
        for (const engine of this.main.activeItem.statusPerEngine) {
            if (engine && engine.lastUpdated) {
                this.statusChartIsLoading[engine.engineIndex] = true;
                this.statusChart[engine.engineIndex] = undefined;
                this.anomalyChart[engine.engineIndex] = undefined;
                const lastConnectedDate = new Date(engine.lastUpdated);
                const defaultStartDate = new Date(lastConnectedDate.getFullYear(),
                    lastConnectedDate.getMonth(),
                    lastConnectedDate.getDate() - 1,
                    lastConnectedDate.getHours(),
                    lastConnectedDate.getMinutes(),
                    lastConnectedDate.getSeconds());
                // const defaultEndDate = new Date(lastConnectedDate.getFullYear(),
                //     lastConnectedDate.getMonth(),
                //     lastConnectedDate.getDate() + 1, 0, 0, 0);

                if (defaultStartDate > this.authService.getEarliestDateAllowedForRole()) {
                    this.statusChartSubscription[engine.engineIndex] =
                        this.gmsChartService.getStatusChart(this.gmsId, engine.engineIndex, defaultStartDate, lastConnectedDate)
                            .subscribe(chartData => {
                                // console.log('building charts');
                                this.buildStatusChart(chartData, engine);
                                this.buildAnomalyChart(chartData, engine);
                                this.statusChartIsLoading[engine.engineIndex] = false;
                            });
                } else {
                    this.statusChart[engine.engineIndex] = undefined;
                    this.anomalyChart[engine.engineIndex] = undefined;
                    this.statusChartIsLoading[engine.engineIndex] = false;
                }
            } else {
                this.statusChart[engine.engineIndex] = undefined;
                this.anomalyChart[engine.engineIndex] = undefined;
                this.statusChartIsLoading[engine.engineIndex] = false;
            }
        }
    }

    setConnectionChartBasedOnLast2Weeks() {
        if (this.main.activeItem?.connectionQuality) {
            this.connectionChartIsLoading = true;
            this.connectionChart = undefined;
            const now = new Date();
            const defaultStartDate = new Date(now.getFullYear(),
                now.getMonth(),
                now.getDate() - 14,
                now.getHours(),
                now.getMinutes(),
                now.getSeconds());

            this.connectionChartSubscription = this.gmsChartService.getConnectionStatus(this.gmsId, defaultStartDate, now)
                .subscribe(connectionData => {
                    this.buildConnectionChart(connectionData);
                    this.connectionChartIsLoading = false;
                });
        } else {
            this.connectionChart = undefined;
            this.connectionChartIsLoading = false;
        }
    }

    navigate(gmsId: string, page: string, engineIndex?: number) {
        this.main.setActiveById(gmsId, engineIndex);
        if (engineIndex !== undefined && engineIndex >= 0 && engineIndex <= 4) {
            this.router.navigate(['/' + gmsId + '/' + engineIndex + '/' + page]);
        } else {
            this.router.navigate(['/' + gmsId + '/' + page]);
        }
    }

    buildStatusChart(chartData: ChartData, engine: Engine) {
        const series = [];
        if (chartData.timeseries?.series) {
            Object.keys(chartData.timeseries.series).forEach((key, value) => {
                if (key.includes('rpm') || key.includes('twist')) {
                    series.push({
                        type: 'line',
                        name: key,
                        visible: key.includes('rpm') || key.includes('twist'),
                        data: MainService.combineTimeData(chartData.timeseries.date, chartData.timeseries.series[key])
                    });
                }
            });

            this.statusChart[engine.engineIndex] = {
                id: 'detailApp3Chart',
                chart: {
                    styledMode: true,
                    zoomType: 'x',
                    panning: true,
                    panKey: 'shift',
                    resetZoomButton: {position: {x: 0, y: -30}}
                },
                tooltip: {
                    xDateFormat: '%Y-%m-%d %H:%M:%S',
                    shared: true,
                    useHTML: true,
                    positioner() {
                        return {x: 0, y: 0};
                    }
                },
                credits: {enabled: false},
                legend: {enabled: false},
                title: {text: null},
                xAxis: {
                    visible: false,
                    type: 'datetime'
                },
                yAxis: {
                    visible: false,
                    // max: 1000 //engine.engineIndex === 0 ? 130 : 1000
                },
                defs: {
                    gradientWhiteTransparent: {
                        tagName: 'linearGradient',
                        id: 'gradientWhiteTransparent', x1: 0, y1: 0, // x2: 0, y2: 1,
                        children: [{
                            tagName: 'stop',
                            offset: 0
                        }, {
                            tagName: 'stop',
                            offset: 1
                        }]
                    }
                },
                series
                // annotations: [{
                //     labelOptions: {
                //         className: 'warning-annotation',
                //         verticalAlign: 'top',
                //         align: 'left',
                //         padding: 7,
                //         x: 10,
                //         y: -44,
                //         borderRadius: 0,
                //         useHTML: true,
                //         outside: true
                //     },
                //     labels: [{
                //         point: {
                //             xAxis: 0,
                //             yAxis: 0,
                //             x: Date.UTC(2020, 10, 15, 17, 32, 5),
                //             y: 19.054932 + 8
                //         },
                //         text: 'WARNING'
                //     }]
                // },
                //     {
                //         labelOptions: {
                //             className: 'warning-annotation',
                //             verticalAlign: 'top',
                //             align: 'right',
                //             padding: 7,
                //             x: 10,
                //             y: -44,
                //             borderRadius: 0,
                //             useHTML: true,
                //             outside: true
                //         },
                //         labels: [{
                //             point: {
                //                 xAxis: 0,
                //                 yAxis: 0,
                //                 x: Date.UTC(2020, 10, 15, 17, 58, 40),
                //                 y: 19.054932 + 3
                //             },
                //             text: 'WARNING'
                //         }]
                //     }, ],
            };
        } else {
            this.statusChart = undefined;
        }


    }

    buildAnomalyChart(chartData: ChartData, engine: Engine) {
        const series = [];
        if (chartData.timeseries?.series) {
            Object.keys(chartData.timeseries.series).forEach((key, value) => {
                if (key.startsWith('ae_')) {
                    series.push({
                        type: 'line',
                        name: key,
                        data: MainService.combineTimeData(chartData.timeseries.date, chartData.timeseries.series[key])
                    });
                }
            });

            this.anomalyChart[engine.engineIndex] = {
                id: 'anomalyChart',
                chart: {
                    styledMode: true,
                    zoomType: 'x',
                    panning: true,
                    panKey: 'shift',
                    resetZoomButton: {position: {x: 0, y: -30}}
                },
                tooltip: {
                    shared: true,
                    useHTML: true,
                    positioner() {
                        return {x: 0, y: 0};
                    }
                },
                credits: {enabled: false},
                // legend: {enabled: false},
                title: {text: null},
                xAxis: {
                    // visible: false,
                    type: 'datetime'
                },
                yAxis: {
                    // plotLines: [{
                    //     color: '#FF0000',
                    //     width: 2,
                    //     value: 90
                    // }]
                    // visible: false,
                    // min: 11,
                    // max: 13
                },
                defs: {
                    gradientWhiteTransparent: {
                        tagName: 'linearGradient',
                        id: 'gradientWhiteTransparent', x1: 0, y1: 0, // x2: 0, y2: 1,
                        children: [{
                            tagName: 'stop',
                            offset: 0
                        }, {
                            tagName: 'stop',
                            offset: 1
                        }]
                    }
                },
                series
            };
        } else {
            this.anomalyChart = undefined;
        }
    }

    buildConnectionChart(connectionData: ConnectionStatusData) {
        const series = [];
        if (connectionData && connectionData.dailyAliveLogCounts) {
            const dates: Date[] = [];
            const qualities: number[] = [];

            for (const [key, value] of new Map(Object.entries(connectionData.dailyAliveLogCounts))) {
                dates.push(new Date(key));
                qualities.push(Math.round(value.actual / value.expected * 100));
            }

            series.push({
                type: 'column',
                name: 'quality',
                data: MainService
                    .combineTimeData(dates, qualities)
            });

            this.connectionChart = {
                id: 'connectionChart',
                chart: {styledMode: true},
                tooltip: {
                    xDateFormat: '%Y-%m-%d',
                    headerFormat: '<span>{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.0f} %</b></td></tr>',
                    shared: true,
                    useHTML: true
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: true,
                            crop: false,
                            overflow: 'none',
                            format: '{y} %'
                        }
                    }
                },
                credits: {enabled: false},
                legend: {enabled: false},
                title: {text: null},
                xAxis: {
                    type: 'datetime',
                    visible: false
                },
                yAxis: {
                    visible: false
                },
                defs: {
                    gradient0: {
                        tagName: 'linearGradient',
                        id: 'gradient-0', x1: 0, y1: 0, // x2: 0, y2: 1,
                        children: [{
                            tagName: 'stop',
                            offset: 0
                        }, {
                            tagName: 'stop',
                            offset: 1
                        }]
                    },
                    gradient1: {
                        tagName: 'linearGradient',
                        id: 'gradient-1',
                        x1: 0,
                        y1: 0,
                        // x2: 0,
                        // y2: 1,
                        children: [{
                            tagName: 'stop',
                            offset: 0
                        }, {
                            tagName: 'stop',
                            offset: 1
                        }]
                    }
                },
                series
            };
        }
    }

    ngOnDestroy(): void {
        if (this.routerEventSubscription) {
            this.routerEventSubscription.unsubscribe();
        }
        if (this.statusChartSubscription) {
            this.statusChartSubscription.map(s => {
                if (s !== undefined) {
                    s.unsubscribe();
                }
            });
        }
        if (this.connectionChartSubscription) {
            this.connectionChartSubscription.unsubscribe();
        }

        super.ngOnDestroy();
    }
}
