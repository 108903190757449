import {Component, ElementRef, Input, OnInit, Renderer2, ViewChild} from '@angular/core';
import postscribe from 'postscribe';

@Component({
  selector: 'app-vessel-finder',
  templateUrl: './vessel-finder.component.html',
  styleUrls: ['./vessel-finder.component.css']
})
export class VesselFinderComponent implements OnInit {

  @Input('imo') imo: string;
  constructor(private renderer: Renderer2) { }
  @ViewChild('vesselFinderMap') private mapElement: ElementRef;

  ngOnInit(): void {
    console.log(this.imo);
    this.showVesselFinder(this.imo);
  }
  private showVesselFinder(imo: string){
    if (!imo) {
      return;
    }
    // remove old scripts if there are any (otherwise there are more vesselFinders shown after navigation events)
    if (this.mapElement && this.mapElement.nativeElement.childNodes) {
      const children = this.mapElement.nativeElement.childNodes;
      for (const child of children) {
        this.renderer.removeChild(this.mapElement.nativeElement, child);
      }
    }
    let height = document.getElementById('vesselFinderMap').parentNode.parentElement.getBoundingClientRect().height;
    if (!height || height < 100){ height = 560; }
    // const height = this.mapElement.nativeElement.parentNode.parentNode.getBoundingClientRect().height;
    document.getElementById('vesselFinderMap').style.height = height + 'px';

    const scriptProps =
        '<script type="text/javascript">' +
        'var imo="' + imo + '";' +
        'var width="100%";' +
        'var height="' + (height + 45) + 'px";' +
        'var names=true;' +
        'var show_track=true;' +
        '</script>';
    // console.log(scriptProps);
    postscribe('#vesselFinderProps', scriptProps);

    const scriptMap =
        '<script id="scriptMap" type="text/javascript" src="https://www.vesselfinder.com/aismap.js"></script>';
    // console.log(scriptMap);
    postscribe('#vesselFinderMap', scriptMap);
  }

}
