import { Component, OnInit } from '@angular/core';
import { GmsInfoDirective } from '../_shared/gms-info/gms-info.directive';
import { ActivatedRoute, Router } from '@angular/router';
import { MainService } from '../../services/main.service';
import { AuthService } from '../../auth/auth.service';
import { ReportService } from '../../services/report.service';
import { EngineReport } from '../../models/gms/engine-report';
import { ReportDetails } from '../../models/gms/report-details';
import { GmsStatus } from '../../models/gms/gms-status.enum';
import { DateUtils } from '../../utils/date-utils';
import { NumberUtils } from '../../utils/number-utils';

@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.css'],
})
export class ReportDetailsComponent extends GmsInfoDirective implements OnInit {
  reportId: string;
  reportDetails: ReportDetails | null = null;
  isLoadingData: boolean;
  error: 'NotFound' | 'Unknown' | null = null;

  dateUtils = DateUtils;
  numberUtils = NumberUtils;

  constructor(private route: ActivatedRoute, public main: MainService, public router: Router, public authService: AuthService, public reportService: ReportService) {
    super(main, router);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.gmsId = this.route.snapshot.paramMap?.get('gmsId');
    this.reportId = this.route.snapshot.paramMap?.get('reportId');

    this.main.setActiveById(this.gmsId);

    this.isLoadingData = true;
    this.reportService.getDetails(this.gmsId, this.reportId).subscribe({
      next: (reportDetails) => {
        this.isLoadingData = false;
        this.reportDetails = reportDetails;
      },
      error: (error) => {
        this.isLoadingData = false;
        this.error = error.status == 404 ? 'NotFound' : 'Unknown';
        console.error(error);
      },
    });
  }

  getStatusByPercentageValue(value: number): GmsStatus {
    if (value <= 50) {
      return GmsStatus.ALARM;
    } else if (value <= 75) {
      return GmsStatus.WARNING;
    } else {
      return GmsStatus.GOOD;
    }
  }

  download(engineReport: EngineReport) {
    this.reportService.downloadDetails(this.gmsId, engineReport.filename).subscribe({
      next: (res) => {
        // open the file in a new browser tab
        const blob = new Blob([res], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      },
      error: (err) => console.error(err),
    });
  }

  protected readonly GmsStatus = GmsStatus;
}
