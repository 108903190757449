import {Injectable} from "@angular/core";
import {GmsAccessGrant} from "../models/gms/access/gms-access-grant";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import {GmsAccessRequest} from "../models/gms/access/gms-access-request";
import {GmsAccessResponse} from "../models/gms/access/gms-access-response";
import {GmsAccessRequestTableItem} from "../models/gms/access/gms-access-request-table-item";

@Injectable({
    providedIn: 'root'
})
export class GmsAccessService {
    private host = environment.apiUrl + '/v1/gms-access/';

    constructor(private httpClient: HttpClient) { }

    grantAccess(gmsAccessGrant: GmsAccessGrant): Observable<GmsAccessResponse> {
        const url = this.host + `grant`;
        return this.httpClient.post<GmsAccessResponse>(url, gmsAccessGrant);
    }

    requestAccess(gmsAccessGrant: GmsAccessRequest): Observable<GmsAccessResponse> {
        const url = this.host + `requests/create`;
        return this.httpClient.post<GmsAccessResponse>(url, gmsAccessGrant);
    }

    getRequest(token: string): Observable<GmsAccessRequest> {
        const url = this.host + `requests/get/` + token;
        return this.httpClient.get<GmsAccessRequest>(url);
    }

    getAllRequestsByUser(username: string): Observable<GmsAccessRequestTableItem[]> {
        const url = this.host + `requests/table-items/get-all-by-user/` + username;
        return this.httpClient.get<GmsAccessRequestTableItem[]>(url);
    }

    getAllAuthorizedAsTableItems(): Observable<GmsAccessRequestTableItem[]> {
        const url = this.host + `requests/table-items/get-all-authorized`;
        return this.httpClient.get<GmsAccessRequestTableItem[]>(url);
    }

    handleRequest(request: GmsAccessRequest): Observable<GmsAccessResponse> {
        const url = this.host + `requests/handle`;
        return this.httpClient.post<GmsAccessResponse>(url, request)
    }
}