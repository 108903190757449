import {Component, OnInit} from "@angular/core";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {GmsAccessRequest} from "../../models/gms/access/gms-access-request";
import {GmsAccessRequestStatusEnum} from "./gmsAccessRequestStatus.enum";
import {ActivatedRoute} from "@angular/router";
import {GmsService} from "../../services/gms.service";
import {GmsAccessService} from "../../services/gms-access.service";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-handle-gms-access-request',
    templateUrl: './handle-gms-access-request.component.html',
    styleUrls: ['./handle-gms-access-request.component.css'],
})
export class HandleGmsAccessRequestComponent implements OnInit {
    gmsAccessRequestStatus = GmsAccessRequestStatusEnum;

    gmsAccessRequest : GmsAccessRequest;
    gmsTitle : String;
    gmsExists : boolean = false;

    loadingGmsAccessRequest = false;
    loadingGms = false;

    errorMessage: string | null = null;

    handleGmsAccessRequestForm = new UntypedFormGroup({
        emailComment: new UntypedFormControl(""),
    })

    constructor(private route: ActivatedRoute,
                private gmsService: GmsService,
                private gmsAccessService: GmsAccessService,
                private dialog: MatDialog) {
    }

    ngOnInit() {
        this.reloadRequestAndGmsDetails();
    }

    reloadRequestAndGmsDetails() {
        this.loadingGmsAccessRequest = true;
        this.gmsAccessService.getRequest(this.route.snapshot.paramMap.get('token')).subscribe(request => {
            this.gmsAccessRequest = request;
            this.loadingGmsAccessRequest = false;

            this.loadingGms = true;
            this.gmsService.getNonClassified(this.gmsAccessRequest.gmsId).subscribe(gms => {
                if (gms) {
                    this.gmsTitle = gms.longTitle;
                    this.gmsExists = true;
                } else {
                    this.gmsTitle = this.gmsAccessRequest.gmsId;
                    this.gmsExists = false;
                }
                this.loadingGms = false;
            }, () => {
                this.gmsTitle = this.gmsAccessRequest.gmsId;
                this.gmsExists = false;
                this.loadingGms = false;
            });
        }, () => {
            this.loadingGmsAccessRequest = false;
            this.loadingGms = false;
            this.errorMessage = "Something went wrong";
        });
    }

    openAcceptOrDeclineDialog(status: GmsAccessRequestStatusEnum): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                message: 'Are you sure you want to ' +
                    (status === this.gmsAccessRequestStatus.Approved ? 'approve' : 'decline') +
                    ' the access-request for \"' + this.gmsTitle +
                    '\" by \"' + this.gmsAccessRequest.username + '\"?',
                buttonText: {
                    ok: 'Yes',
                    cancel: 'Cancel'
                }
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.handleRequest(status);
            }
        });
    }

    handleRequest(status: GmsAccessRequestStatusEnum) {
        this.gmsAccessService.handleRequest({
            ...this.gmsAccessRequest,
            emailComment: this.handleGmsAccessRequestForm.get('emailComment').value,
            status: status
        }).subscribe(response => {
            this.reloadRequestAndGmsDetails();
            if (response.sendMailError) {
               this.errorMessage = "There was an error informing the user about the result." //response.sendMailErrorText
            } else {
                this.errorMessage = null;
            }
        }, error => {
            this.errorMessage = "Something went wrong, please contact the support." //error.message?.toString()
        });
    }
}