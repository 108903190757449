import {Component, OnInit} from "@angular/core";
import {User} from "../../models/user/user";
import {UserService} from "../../services/user.service";
import {AuthService} from "../../auth/auth.service";
import {GmsFavorite} from "../../models/gms/gms-favorite";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {GmsContext} from "../../models/gms/gms-context";
import {MainService} from "../../services/main.service";
import {GmsContextService} from "../../services/gms-context.service";
import {COMPACT_VIEW} from "../_shared/constants/local-storage-constants";

@Component({
    selector: 'app-all-devices',
    templateUrl: './dashboard-settings.component.html',
    styleUrls: ['./dashboard-settings.component.css']
})
export class DashboardSettingsComponent implements OnInit {
    user: User;
    gmsContextFavorites: GmsContext[] = [];
    gmsFavorites: GmsFavorite[] = [];

    loadingUser: boolean;
    loadingGmsContexts: boolean;

    compactView: boolean;

    constructor(private userService: UserService,
                private gmsContextService: GmsContextService,
                public main: MainService,
                private authService: AuthService) {
    }

    ngOnInit() {
        this.init();
    }

    init() {
        this.loadingUser = true;
        this.userService.get(this.authService.getUsername()).subscribe(user => {
            this.user = user;
            this.compactView = user.usingCompactDashboardView;
            this.gmsFavorites = user.gmsFavorites;
            this.loadingUser = false;
        });

        this.loadingGmsContexts = true;
        this.gmsContextService.getFavorites().subscribe((favorites => {
            this.gmsContextFavorites = favorites.sort((a, b) => a.sortOrder - b.sortOrder);
            this.loadingGmsContexts = false;
        }));
    }

    onListItemDrop(event: CdkDragDrop<GmsFavorite>) {
        moveItemInArray(this.gmsContextFavorites, event.previousIndex, event.currentIndex)
    }

    saveChanges() {
        this.updateSortOrdersOfGmsFavorites();

        this.userService.updateUsingCompactDashboardView(this.compactView).subscribe(() => {
            this.main.compactView = this.compactView;
        });

        this.userService.updateGmsFavorites(this.gmsFavorites).subscribe(() => {
            this.main.initGmsList();
            this.init();
        });
    }

    toggleUsingCompactDashboardView() {
        this.compactView = !this.compactView;
    }

    isActiveFavorite(gmsId: string): boolean {
        return this.gmsFavorites.map(x => x.gmsId).includes(gmsId);
    }

    toggleFavorite(gmsId: string, index: number) {
        let gmsInFavoritesList = this.gmsFavorites.find(x => x.gmsId === gmsId);
        gmsInFavoritesList
            ? this.gmsFavorites.splice(this.gmsFavorites.indexOf(gmsInFavoritesList), 1)
            : this.gmsFavorites.push(new GmsFavorite(gmsId, index));
    }

    updateSortOrdersOfGmsFavorites() {
        this.gmsFavorites = this.gmsContextFavorites
            .filter(gms => this.gmsFavorites.map(gms => gms.gmsId).includes(gms.gmsId)) // do not include un-favorited items
            .map((gms, index) => {
                return {
                    gmsId: gms.gmsId,
                    sortOrder: index
                };
            });
    }
}
