<!--<div class="g-padding-content"></div>-->
<div class="g-head g-hero">
  <div class="g-hero-text">
    <div class="text">
      <div class="logo"><img src="assets/geislinger-logo.svg" /></div>
    </div>
  </div>
  <div class="g-hero-headline"><h1>Administration</h1></div>
</div>
<div class="main-container">
  <mat-tab-group mat-stretch-tabs mat-align-tabs="start" (selectedTabChange)="onSelectedTabChange($event)">
    <mat-tab [label]="gmsTabLabel" *ngIf="isAdmin">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">directions_boat</mat-icon>
        GMS
      </ng-template>

      <mat-spinner *ngIf="isLoadingGmsData"></mat-spinner>

      <div *ngIf="!isLoadingGmsData && gmsList && gmsList.length > 0">
        <h3 class="g-sub-headline">Existing gms</h3>
        <mat-form-field class="g-block">
          <mat-label>GMS search</mat-label>
          <input matInput (keyup)="applyFilterOnGmsList($event)" #gmsInput />
        </mat-form-field>
        <mat-table [dataSource]="gmsDataSource">
          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef>Id</mat-header-cell>
            <mat-cell *matCellDef="let gms">{{ gms.id }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef>Name</mat-header-cell>
            <mat-cell *matCellDef="let gms">{{ gms.properties.contextInfo.name }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="update">
            <mat-header-cell *matHeaderCellDef>Properties</mat-header-cell>
            <mat-cell *matCellDef="let gms">
              <button mat-raised-button color="accent" (click)="showGmsProperties(gms.id)">View/Update</button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="creationDate">
            <mat-header-cell *matHeaderCellDef>Created On</mat-header-cell>
            <mat-cell *matCellDef="let gms"> {{ gms.creationDate | date : 'yyyy-MM-dd HH:mm' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="creationUsername">
            <mat-header-cell *matHeaderCellDef>Created By</mat-header-cell>
            <mat-cell *matCellDef="let gms"> {{ gms.creationUsername }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastUpdateDate">
            <mat-header-cell *matHeaderCellDef>Updated On</mat-header-cell>
            <mat-cell *matCellDef="let gms"> {{ gms.lastUpdateDate | date : 'yyyy-MM-dd HH:mm' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastUpdateUsername">
            <mat-header-cell *matHeaderCellDef>Updated By</mat-header-cell>
            <mat-cell *matCellDef="let gms"> {{ gms.lastUpdateUsername }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="delete">
            <mat-header-cell *matHeaderCellDef>Delete</mat-header-cell>
            <mat-cell *matCellDef="let gms">
              <button mat-icon-button (click)="openDeleteGmsDialog(gms)">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{ gmsInput.value }}"</td>
          </tr>

          <mat-header-row *matHeaderRowDef="displayedGmsListColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedGmsListColumns"></mat-row>
        </mat-table>
        <div class="g-padding-content"></div>
      </div>

      <div *ngIf="selectedGmsToUpdate">
        <h3>Properties of {{ this.selectedGmsToUpdate.properties.contextInfo.name }} ({{ this.selectedGmsToUpdate.id }})</h3>
        <form [formGroup]="updateGmsPropertiesForm">
          <mat-form-field class="g-block">
            <mat-label for="updateGmsJsonText"></mat-label>
            <textarea style="background-color: var(--co-11)" rows="30" matInput id="updateGmsJsonText" formControlName="gmsPropertiesAsJson"></textarea>
            <mat-error>Please provide a valid json.</mat-error>
          </mat-form-field>

          <button
            class="g-button g-form-main-button"
            [ngClass]="!updateGmsPropertiesForm.valid ? 'g-disabled' : ''"
            [disabled]="!updateGmsPropertiesForm.valid"
            (click)="updateGmsProperties()"
          >
            Save
          </button>
          <div class="g-status warn error" *ngIf="updateGmsJsonError !== null">ERROR: {{ updateGmsJsonError }}</div>
        </form>
      </div>

      <div *ngIf="addNewGms">
        <h3>Adding a new GMS...</h3>
        <form [formGroup]="addGmsPropertiesForm">
          <mat-form-field class="g-block">
            <mat-label for="addGmsJsonText"></mat-label>
            <textarea rows="5" matInput style="background-color: var(--co-11)" id="addGmsJsonText" placeholder="Insert JSON here" formControlName="gmsPropertiesAsJson"></textarea>
            <mat-error>Please provide a valid json.</mat-error>
          </mat-form-field>

          <button
            class="g-button g-form-main-button"
            [ngClass]="!addGmsPropertiesForm.valid ? 'g-disabled' : ''"
            [disabled]="!addGmsPropertiesForm.valid"
            (click)="addNewGmsProperties()"
          >
            Save
          </button>
          <div class="g-status warn error" *ngIf="updateGmsJsonError !== null">ERROR: {{ updateGmsJsonError }}</div>
        </form>
      </div>

      <button class="g-button g-form-main-button" *ngIf="!addNewGms" (click)="addNewGms = true">Add new GMS</button>
    </mat-tab>

    <mat-tab [label]="usersTabLabel" *ngIf="isAdmin || isGdsPartner || isCustomerManager">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">people_alt</mat-icon>
        USERS
      </ng-template>

      <mat-spinner *ngIf="isLoadingUsers || isLoadingGmsData"></mat-spinner>

      <div *ngIf="users && users.length > 0">
        <h3 class="g-sub-headline">Existing users</h3>
        <div class="g-user-list-header">
          <mat-form-field>
            <mat-label>User search</mat-label>
            <input matInput (keyup)="applyFilterOnUserList($event)" #userInput />
          </mat-form-field>
          <div class="g-no-account-manager-check" *ngIf="isAdmin">
            <mat-checkbox label="No account manager" [checked]="noAccountManager" (click)="$event.stopPropagation()" (change)="toggleNoAccountManager(false)">
              No account manager
            </mat-checkbox>
          </div>
          <div class="g-no-show-inactive-check" *ngIf="isAdmin || isGdsPartner">
            <mat-checkbox label="Show inactive users" [checked]="showInactive" (click)="$event.stopPropagation()" (change)="toggleShowInactive(false)">
              Show inactive users
            </mat-checkbox>
          </div>
        </div>
        <mat-table [dataSource]="userDataSource" matSort #userTbSort="matSort">
          <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ user.username }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
            <mat-cell *matCellDef="let user">
              {{ user.role }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="gmsId">
            <mat-header-cell *matHeaderCellDef>GMS</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <mat-select multiple class="g-width-80" [panelWidth]="null" [(value)]="user.gmsIds">
                <mat-option *ngFor="let gms of getGmsOptionsForUser(user.gmsIds)" [disabled]="true" [value]="gms.id">
                  {{ gms.longTitle }}
                </mat-option>
              </mat-select>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="registrationDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Registration Date</mat-header-cell>
            <mat-cell *matCellDef="let user"> {{ user.registrationDate | date : 'yyyy-MM-dd HH:mm' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="accountManagerUsername">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Account Manager</mat-header-cell>
            <mat-cell *matCellDef="let user">
              {{ user.accountManagerUsername ?? '' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <button mat-icon-button [routerLink]="'/user/' + user.username + '/edit'">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="deactivate">
            <mat-header-cell *matHeaderCellDef>Deactivate</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <button mat-icon-button (click)="openChangeUserActiveDialog(user.username, 'deactivate')">
                <mat-icon>not_interested</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="activate">
            <mat-header-cell *matHeaderCellDef>Activate</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <button mat-icon-button (click)="openChangeUserActiveDialog(user.username, 'activate')">
                <mat-icon>restore</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{ userInput.value }}"</td>
          </tr>

          <mat-header-row *matHeaderRowDef="displayedUserColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedUserColumns"></mat-row>
        </mat-table>
        <mat-paginator #userTbPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        <div class="g-padding-content"></div>
      </div>
      <div class="g-head g-form" *ngIf="isAdmin || isCustomerManager">
        <h3 class="g-headline">Grant user access to GMS</h3>
        <form [formGroup]="grantAccessForm">
          <mat-form-field class="g-block">
            <mat-label>Email address</mat-label>
            <input matInput type="text" formControlName="username" required />
            <mat-error>Please provide a valid email address</mat-error>
          </mat-form-field>

          <mat-form-field class="g-block" appearance="fill">
            <mat-label>GMS</mat-label>
            <mat-select formControlName="gmsList" [panelWidth]="null" multiple required>
              <mat-option *ngFor="let gms of gmsListNonClassified" [value]="gms.id">
                {{ gms.longTitle }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div>
            <mat-checkbox (click)="$event.stopPropagation()" formControlName="sendMail"> Send email </mat-checkbox>
          </div>

          <button class="g-button g-form-main-button" [ngClass]="!grantAccessForm.valid ? 'g-disabled' : ''" [disabled]="!grantAccessForm.valid" (click)="grantAccessToGms()">
            Submit
          </button>
          <mat-spinner *ngIf="isSendingGmsAccessGrant"></mat-spinner>
          <div class="g-status warn error" *ngIf="!isSendingGmsAccessGrant && grantAccessError !== null">ERROR: {{ grantAccessError }}</div>
        </form>
      </div>
    </mat-tab>

    <mat-tab *ngIf="isAdmin" [label]="adminsAndPartnersTabLabel">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">people_alt</mat-icon>
        ADMINS & PARTNERS
      </ng-template>
      <mat-spinner *ngIf="isLoadingGmsData || isLoadingUsers"></mat-spinner>

      <div *ngIf="!isLoadingGmsData && !isLoadingUsers">
        <h3 class="g-sub-headline">Existing Admins & Partners</h3>
        <div class="g-user-list-header">
          <mat-form-field>
            <mat-label>User search</mat-label>
            <input matInput (keyup)="applyFilterOnUserList($event)" #adminSearchInput />
          </mat-form-field>
          <div class="g-no-account-manager-check">
            <mat-checkbox label="No account manager" [checked]="noAccountManager" (click)="toggleNoAccountManager(true)">No account manager </mat-checkbox>
          </div>
          <div class="g-no-show-inactive-check" *ngIf="isAdmin || isGdsPartner">
            <mat-checkbox label="Show inactive users" [checked]="showInactive" (click)="toggleShowInactive(true)">Show inactive users </mat-checkbox>
          </div>
        </div>
        <mat-table [dataSource]="userDataSource" matSort #adminTbSort="matSort">
          <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
            <mat-cell *matCellDef="let user">{{ user.username }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
            <mat-cell *matCellDef="let user">
              {{ user.role }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="gmsId">
            <mat-header-cell *matHeaderCellDef>GMS</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <mat-select multiple class="g-width-80" [panelWidth]="null" [(value)]="user.gmsIds">
                <mat-option *ngFor="let gms of getGmsOptionsForUser(user.gmsIds)" [disabled]="true" [value]="gms.id">
                  <span>{{ gms.longTitle }}</span>
                </mat-option>
              </mat-select>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="registrationDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Registration Date</mat-header-cell>
            <mat-cell *matCellDef="let user"> {{ user.registrationDate | date : 'yyyy-MM-dd HH:mm' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="accountManagerUsername">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Account Manager</mat-header-cell>
            <mat-cell *matCellDef="let user">
              {{ user.accountManagerUsername ?? '' }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef>Edit</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <button mat-icon-button [routerLink]="'/user/' + user.username + '/edit'">
                <mat-icon>edit</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="deactivate">
            <mat-header-cell *matHeaderCellDef>Deactivate</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <button mat-icon-button (click)="openChangeUserActiveDialog(user.username, 'deactivate')">
                <mat-icon>not_interested</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="activate">
            <mat-header-cell *matHeaderCellDef>Activate</mat-header-cell>
            <mat-cell *matCellDef="let user">
              <button mat-icon-button (click)="openChangeUserActiveDialog(user.username, 'activate')">
                <mat-icon>restore</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{ adminSearchInput.value }}"</td>
          </tr>

          <mat-header-row *matHeaderRowDef="displayedUserColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedUserColumns"></mat-row>
        </mat-table>
        <mat-paginator #adminTbPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
        <div class="g-padding-content"></div>
      </div>
    </mat-tab>

    <mat-tab [label]="gmsRequestsTabLabel" *ngIf="isAdmin || isGdsPartner">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">people_alt</mat-icon>
        GMS REQUESTS
      </ng-template>
      <mat-spinner *ngIf="isLoadingGmsAccessRequests || isLoadingUsers || isLoadingGmsData"></mat-spinner>

      <div [hidden]="!(!isLoadingGmsAccessRequests && !isLoadingUsers && !isLoadingGmsData && users && users.length > 0)">
        <h3 class="g-sub-headline">GMS Requests</h3>
        <div class="g-user-list-header">
          <mat-form-field>
            <mat-label>User search</mat-label>
            <input matInput (keyup)="applyFilterOnGmsRequestList($event)" #userInput />
          </mat-form-field>
          <div class="g-show-approved-declined-check">
            <mat-checkbox
              label="Show approved/declined requests"
              [checked]="showApprovedDeclinedRequests"
              (click)="$event.stopPropagation()"
              (change)="toggleShowApprovedDeclinedRequests()"
            >
              Show approved/declined requests
            </mat-checkbox>
          </div>
        </div>
        <mat-table [dataSource]="gmsRequestDataSource" matSort #gmsRequestTbSort="matSort">
          <ng-container matColumnDef="username">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Email</mat-header-cell>
            <mat-cell *matCellDef="let gmsRequest">
              <button mat-button (click)="onClickGmsRequestUsername(gmsRequest.username)">
                {{ gmsRequest.username }}
              </button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Role</mat-header-cell>
            <mat-cell *matCellDef="let gmsRequest">{{ gmsRequest.userRole }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="accountManagerUsername">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Account Manager</mat-header-cell>
            <mat-cell *matCellDef="let gmsRequest">{{ gmsRequest.accountManagerUsername }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="gms">
            <mat-header-cell *matHeaderCellDef>GMS</mat-header-cell>
            <mat-cell *matCellDef="let gmsRequest">
              <span>{{ gmsRequest.gmsTitle }}</span>
              <mat-icon class="g-warning-icon" *ngIf="!gmsRequest.gmsExists">warning</mat-icon>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="creationDate">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Request Date</mat-header-cell>
            <mat-cell *matCellDef="let gmsRequest"> {{ gmsRequest.creationDate | date : 'yyyy-MM-dd HH:mm' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Status</mat-header-cell>
            <mat-cell *matCellDef="let gmsRequest">
              {{ gmsRequest.status }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="edit">
            <mat-header-cell *matHeaderCellDef>{{ showApprovedDeclinedRequests ? 'View' : 'Edit' }}</mat-header-cell>
            <mat-cell *matCellDef="let gmsRequest">
              <button mat-icon-button [routerLink]="'../gms-access/handle/' + gmsRequest.token">
                <mat-icon>{{ showApprovedDeclinedRequests ? 'remove_red_eye' : 'edit' }}</mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <!-- Row shown when there is no matching data. -->
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" colspan="4">No data matching the filter "{{ userInput.value }}"</td>
          </tr>

          <mat-header-row *matHeaderRowDef="displayedGmsRequestListColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedGmsRequestListColumns"></mat-row>
        </mat-table>
        <mat-paginator #gmsRequestTbPaginator="matPaginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
      </div>
    </mat-tab>

    <mat-tab [label]="messageTemplatesTabLabel" *ngIf="isAdmin">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">message</mat-icon>
        MESSAGE TEMPLATES
      </ng-template>
      <mat-spinner *ngIf="isLoadingMessageTemplates"></mat-spinner>

      <div *ngIf="!isLoadingMessageTemplates && messageTemplates && messageTemplates.length > 0">
        <h3 class="g-sub-headline">Message Templates</h3>
        <mat-table [dataSource]="messageTemplates">
          <ng-container matColumnDef="app">
            <mat-header-cell *matHeaderCellDef>App</mat-header-cell>
            <mat-cell *matCellDef="let mt" [ngClass]="{ 'no-content': !mt.description || mt.description.length === 0 }">
              <app-icon class="small-icon" name="WARNING" *ngIf="!mt.description || mt.description.length === 0"></app-icon>
              {{ mt.app }}
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="type">
            <mat-header-cell *matHeaderCellDef>Type</mat-header-cell>
            <mat-cell *matCellDef="let mt" [ngClass]="{ 'no-content': !mt.description || mt.description.length === 0 }">{{ mt.alarmType }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="alarm">
            <mat-header-cell *matHeaderCellDef>Alarm</mat-header-cell>
            <mat-cell *matCellDef="let mt" [ngClass]="{ 'no-content': !mt.description || mt.description.length === 0 }">{{ mt.message }} </mat-cell>
          </ng-container>

          <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef>Description</mat-header-cell>
            <mat-cell *matCellDef="let mt" [ngClass]="{ 'no-content': !mt.description || mt.description.length === 0 }">
              <button mat-raised-button color="accent" (click)="showDescription(mt)">View/Update</button>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="lastUpdateDate">
            <mat-header-cell *matHeaderCellDef>Updated On</mat-header-cell>
            <mat-cell *matCellDef="let mt" [ngClass]="{ 'no-content': !mt.description || mt.description.length === 0 }">
              {{ mt.lastUpdateDate | date : 'yyyy-MM-dd HH:mm' }}</mat-cell
            >
          </ng-container>

          <ng-container matColumnDef="lastUpdateUsername">
            <mat-header-cell *matHeaderCellDef>Updated By</mat-header-cell>
            <mat-cell *matCellDef="let mt" [ngClass]="{ 'no-content': !mt.description || mt.description.length === 0 }"> {{ mt.lastUpdateUsername }}</mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="messageTemplateColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: messageTemplateColumns"></mat-row>
        </mat-table>
        <div class="g-padding-content"></div>
      </div>

      <div *ngIf="selectedMessageTemplate">
        <h3>Template for {{ this.selectedMessageTemplate.message }}</h3>
        <form [formGroup]="messageTemplateForm">
          <mat-form-field class="g-block">
            <mat-label for="description"></mat-label>
            <textarea style="background-color: var(--co-11)" rows="30" matInput id="description" formControlName="description"> </textarea>
            <mat-error> Please provide a valid html. </mat-error>
          </mat-form-field>

          <button
            class="g-button g-form-main-button"
            [ngClass]="!messageTemplateForm.valid ? 'g-disabled' : ''"
            [disabled]="!messageTemplateForm.valid"
            (click)="updateMessageTemplate()"
          >
            Save
          </button>
          <button
            class="g-button g-form-main-button"
            [ngClass]="!messageTemplateForm.valid ? 'g-disabled' : ''"
            [disabled]="!messageTemplateForm.valid"
            (click)="showMessagePreview = !showMessagePreview"
          >
            Preview
          </button>
          <div class="g-status warn error" *ngIf="messageTemplateError !== null">ERROR: {{ messageTemplateError }}</div>
        </form>

        <div *ngIf="showMessagePreview && messageTemplateForm.get('description').value.length > 0">
          <h3>Preview</h3>
          <div class="message-body" style="background-color: var(--co-11)">
            <div class="message-text">
              <h3>{{ selectedMessageTemplate.message }}</h3>
              <p [innerHTML]="messageTemplateForm.get('description').value"></p>
              <button class="g-button">CONTACT SUPPORT</button>
            </div>
            <!--                        <div class="g-hero-img">-->
            <!--                            <app-highchart [id]="main.messageDetailApp2Chart.id" class=""-->
            <!--                                           [options]="main.messageDetailApp2Chart"-->
            <!--                                           style="min-width: 550px; min-height: 550px"></app-highchart>-->
            <!--                        </div>-->
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab [label]="sendMessagesTabLabel" *ngIf="isAdmin || isGdsPartner">
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon">message</mat-icon>
        SEND MESSAGES
      </ng-template>
      <mat-spinner *ngIf="isLoadingGmsData"></mat-spinner>

      <div class="g-head" *ngIf="!isLoadingGmsData">
        <h3 class="g-headline">Submit a new message</h3>

        <mat-form-field class="g-block">
          <mat-label>GMS search (filters the GMS dropdown below)</mat-label>
          <input matInput (keyup)="applyFilterOnGmsListWhileSendMessage($event)" />
        </mat-form-field>

        <form [formGroup]="sendMessageForm">
          <mat-form-field class="g-block" appearance="fill">
            <mat-label>GMS</mat-label>
            <mat-select formControlName="gmsList" multiple required>
              <mat-option *ngFor="let gms of gmsListNonClassifiedForMessages" [value]="gms.id">
                {{ gms.longTitle }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="g-block" appearance="fill">
            <mat-label>Message Type</mat-label>
            <mat-select formControlName="messageType" required>
              <mat-option *ngFor="let name of messageTypeOptions" [value]="name">
                {{ name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="g-block" appearance="fill">
            <mat-label>Severity</mat-label>
            <mat-select formControlName="severity" required>
              <mat-option *ngFor="let name of severityOptions" [value]="name">
                {{ name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="g-block">
            <mat-label>Sender</mat-label>
            <input matInput type="text" formControlName="sender" required />
            <mat-error>Please provide a valid sender</mat-error>
          </mat-form-field>

          <mat-form-field class="g-block">
            <mat-label>Title</mat-label>
            <input matInput type="text" formControlName="title" required />
            <mat-error>Please provide a valid title</mat-error>
          </mat-form-field>

          <mat-form-field class="g-block">
            <mat-label for="description">Description</mat-label>
            <textarea style="background-color: var(--co-11)" rows="10" matInput id="description" formControlName="description"> </textarea>
            <mat-error>Please provide a valid description.</mat-error>
          </mat-form-field>

          <button class="g-button g-form-main-button" [ngClass]="!sendMessageForm.valid ? 'g-disabled' : ''" [disabled]="!sendMessageForm.valid" (click)="sendMessage()">
            Submit
          </button>
          <div class="g-status" *ngIf="sendMessageSuccess">SUCCESS</div>
          <div class="g-status warn error" *ngIf="sendMessageError !== null">ERROR: {{ sendMessageError }}</div>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
