import {Component, OnInit} from '@angular/core';
import {GmsCert} from '../../models/gms/gms-cert';
import {MatTableDataSource} from '@angular/material/table';
import {GmsCertService} from '../../services/gms-cert.service';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {GmsCertStatus} from '../../models/gms/gms-cert-status.enum';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'app-certificates',
    templateUrl: './certificates.component.html',
    styleUrls: ['./certificates.component.css']
})
export class CertificatesComponent {

    certs: GmsCert[] = [];
    certColumns = ['certId', 'gmsId', 'name', 'status', 'download', 'topics', 'updatedOn', 'updatedBy', 'disableOrDelete'];
    certsDataSource;

    createCertForm: UntypedFormGroup;
    createCertError = null;

    constructor(private gmsCertService: GmsCertService,
                private fb: UntypedFormBuilder,
                private dialog: MatDialog,
                public auth: AuthService) {
        this.reloadCerts();
        this.createCertForm = this.fb.group({
            gmsId: ['', [Validators.required, Validators.pattern('[0-9]{8}')]],
        });
    }

    reloadCerts() {
        this.gmsCertService.getAll().subscribe(certs => {
            this.certs = certs;
            this.certsDataSource = new MatTableDataSource(this.certs);
        });
    }

    applyFilterOnGmsCertList(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.certsDataSource.filter = filterValue.trim().toLowerCase();
    }

    createCert() {
        const gmsId = this.createCertForm.get('gmsId').value;

        console.log('create cert for gmsId ' + gmsId);

        if (this.certs.filter(c => c.gmsId === gmsId && c.status === GmsCertStatus.ACTIVE).length > 0) {
            const errorMsg = 'The GMS with CP=' + gmsId + ' has an active certificate. Only one active certificate is allowed.';
            this.createCertError = errorMsg;
            console.error(errorMsg);
        } else {
            if (this.createCertForm.valid) {
                this.gmsCertService.generateCertificate(gmsId).subscribe(certs => {
                        this.certs = certs;
                        this.certsDataSource = new MatTableDataSource(certs);
                        this.createCertError = null;
                    },
                    error => {
                        this.createCertError = error.error;
                    });

                this.createCertForm.reset();
            }
        }
    }

    disableCert(cert: GmsCert) {
        this.gmsCertService.revokeCertificate(cert.gmsId, cert.counter).subscribe(certs => {
            this.certs = certs;
            this.certsDataSource = new MatTableDataSource(certs);
        });
    }

    deleteCert(cert: GmsCert) {
        if (cert.status === GmsCertStatus.ACTIVE) {
            console.error('Certificates of Status.ACTIVE cannot be deleted! Disable it first.');
        } else {
            this.gmsCertService.deleteCertificate(cert.gmsId, cert.counter).subscribe(certs => {
                this.certs = certs;
                this.certsDataSource = new MatTableDataSource(certs);
            });
        }
    }

    openDisableCertDialog(cert: GmsCert): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                message: 'Are you sure you want to disable the certificate \"' + cert.gmsId + '-' + this.zeroPad(cert.counter, 2) + '\"? After this operation, the GMS cannot push data to the cloud using that certificate.',
                buttonText: {
                    ok: 'Yes, delete',
                    cancel: 'Cancel'
                }
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.disableCert(cert);
            }
        });
    }

    openDeleteCertDialog(cert: GmsCert): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                message: 'Are you sure you want to delete the certificate \"' + this.createCertId(cert) + '\"?',
                buttonText: {
                    ok: 'Yes, delete',
                    cancel: 'Cancel'
                }
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.deleteCert(cert);
            }
        });
    }

    createCertId(cert: GmsCert) : string {
        return cert.gmsId + '-' + this.zeroPad(cert.counter, 2);
    }

    zeroPad(num:number, size:number): string {
        let s = num+"";
        while (s.length < size) s = "0" + s;
        return s;
    }

    downloadCertFiles(cert: GmsCert, type: string) {
        this.gmsCertService.download(cert.gmsId, cert.counter, type).subscribe(res => {
            // directly downloads the file
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([res], {type: 'application/json'}));
            if (res) {
                if (type === 'key') {
                    downloadLink.setAttribute('download', cert.keyFilePath.split('/').pop());
                } else {
                    downloadLink.setAttribute('download', cert.certFilePath.split('/').pop());
                }
            }
            document.body.appendChild(downloadLink);
            downloadLink.click();

            if (type === 'key') {
                // directly after successful download of the key file, download the pem file
                this.downloadCertFiles(cert, 'pem');
            }

        }, err => {
            console.log(err);
        });
    }
}
