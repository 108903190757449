<mat-card appearance="outlined" class="password-box">
  <mat-card-content>
    <h2 style="margin-bottom: var(--padding)">Edit Profile</h2>
    <form *ngIf="!loadingEditProfileForm" [formGroup]="editProfileForm">
      <div class="form-field">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input
            [value]="user.personalData?.email ?? user.username"
            matInput
            placeholder="Email"
            [disabled]="true"
          />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field>
          <mat-label>First Name</mat-label>
          <input formControlName="firstname" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field>
          <mat-label>Last Name</mat-label>
          <input formControlName="lastname" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field>
          <mat-label>Company</mat-label>
          <input formControlName="company" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field>
          <mat-label>Position</mat-label>
          <input formControlName="position" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field>
          <mat-label>Address</mat-label>
          <input formControlName="addressLine" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field>
          <mat-label>ZIP</mat-label>
          <input formControlName="postalCode" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field>
          <mat-label>City</mat-label>
          <input formControlName="city" matInput type="text" />
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field class="full-width-input">
          <mat-label>Country</mat-label>
          <mat-select formControlName="country" [panelWidth]="null">
            <mat-option *ngFor="let country of countries" [value]="country">
              {{ country }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div style="text-align: right">
        <button
          (click)="applyEditProfileChanges()"
          [disabled]="editProfileForm.pristine"
          [ngClass]="editProfileForm.pristine ? 'g-disabled' : ''"
          class="g-button g-form-main-button"
        >
          Apply
        </button>
      </div>
    </form>
    <mat-spinner *ngIf="loadingEditProfileForm">Loading...</mat-spinner>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="password-box">
  <mat-card-content>
    <form [formGroup]="passwordChangeForm">
      <h2 style="margin-bottom: var(--padding)">Password change</h2>

      <div class="form-field">
        <mat-form-field>
          <mat-label>Current password</mat-label>
          <input
            autocomplete="current-password"
            formControlName="currentPassword"
            matInput
            required
            type="password"
          />
          <mat-error>Please enter your current password</mat-error>
        </mat-form-field>
      </div>

      <div class="form-field">
        <mat-form-field>
          <mat-label>New password</mat-label>
          <input
            autocomplete="new-password"
            formControlName="newPassword"
            matInput
            required
            type="password"
          />
          <mat-error *ngIf="passwordChangeForm.get('newPassword')?.hasError('mustMatch') && !passwordChangeForm.get('newPassword')?.hasError('required')">
            New password must be different to the current one
          </mat-error>
          <mat-error
            *ngIf="
              passwordChangeForm
                .get('newPassword')
                ?.hasError('requirementsNotFulfilled') &&
              !passwordChangeForm.get('newPassword')?.hasError('required')
            "
          >
            <span
              title="
- at least 8 characters long,
- contains at least 1 number,
- contains at least 1 upper-case character,
- contains at least 1 lower-case character,
- contains at least 1 special character,
- contains no whitespaces"
            >
              The password does not meet the <b>requirements*</b>
            </span>
          </mat-error>
        </mat-form-field>
      </div>

      <div class="form-field">
        <mat-form-field>
          <mat-label>Confirm new password</mat-label>
          <input
            autocomplete="new-password"
            formControlName="confirmNewPassword"
            matInput
            required
            type="password"
          />
          <mat-error *ngIf="passwordChangeForm.get('confirmNewPassword')?.hasError('required')">
            Please confirm password
          </mat-error>
          <mat-error *ngIf="passwordChangeForm.get('confirmNewPassword')?.hasError('mustMatch') && !passwordChangeForm.get('confirmNewPassword')?.hasError('required')">
            Passwords do not match
          </mat-error>
        </mat-form-field>
      </div>

      <div style="text-align: right">
        <button
          (click)="sendPasswordChangeRequest()"
          [disabled]="!passwordChangeForm.valid"
          [ngClass]="!passwordChangeForm.valid ? 'g-disabled' : ''"
          class="g-button g-form-main-button"
        >
          Submit
        </button>
      </div>
      <div *ngIf="passwordChangeSuccess">
        {{ passwordChangeSuccessMessage }}
      </div>
      <div *ngIf="passwordChangeError !== null" class="error">
        ERROR: {{ passwordChangeError }}
      </div>
    </form>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="password-box">
  <mat-card-content *ngIf="notifications">
    <h2 style="margin-bottom: var(--padding)">Email notifications</h2>

    <div>
      <p>Receive notifications for</p>
      <div class="form-field">
        <mat-slide-toggle
          (click)="toggleNotifications('message-info')"
          [checked]="notifications.has('message-info')"
        >
          Info
        </mat-slide-toggle>
      </div>
      <div class="form-field">
        <mat-slide-toggle
          (click)="toggleNotifications('message-warning')"
          [checked]="notifications.has('message-warning')"
        >
          Warning
        </mat-slide-toggle>
      </div>
      <div class="form-field">
        <mat-slide-toggle
          (click)="toggleNotifications('message-alarm')"
          [checked]="notifications.has('message-alarm')"
        >
          Alarm
        </mat-slide-toggle>
      </div>
      <div class="form-field">
        <mat-slide-toggle
          (click)="toggleNotifications('message-service')"
          [checked]="notifications.has('message-service')"
        >
          Service
        </mat-slide-toggle>
      </div>
      <div class="form-field">
        <mat-slide-toggle
          (click)="toggleNotifications('document')"
          [checked]="notifications.has('document')"
        >
          Document
        </mat-slide-toggle>
      </div>
      <div class="form-field">
        <mat-slide-toggle
          (click)="toggleNotifications('gms-access-request')"
          [checked]="notifications.has('gms-access-request')"
        >
          GMS Access Request
        </mat-slide-toggle>
      </div>
      <div class="form-field">
        <mat-slide-toggle
          (click)="toggleNotifications('reports')"
          [checked]="notifications.has('reports')"
        >
          Reports
        </mat-slide-toggle>
      </div>
    </div>

    <div style="text-align: right">
      <button
        (click)="updateNotifications()"
        [disabled]="!notificationsUpdated"
        [ngClass]="!notificationsUpdated ? 'g-disabled' : ''"
        class="g-button g-form-main-button"
      >
        Update
      </button>
    </div>
    <div *ngIf="passwordChangeSuccess">{{ passwordChangeSuccessMessage }}</div>
    <div *ngIf="passwordChangeError !== null" class="error">
      ERROR: {{ passwordChangeError }}
    </div>
  </mat-card-content>
</mat-card>
<mat-card appearance="outlined" class="password-box">
  <mat-card-content>
    <form [formGroup]="passwordChangeForm">
      <h2 style="margin-bottom: var(--padding)">Appearance</h2>
      <div class="form-field">
        <mat-slide-toggle
          (click)="toggleHighContrast()"
          [checked]="highContrast"
        >
          High Contrast
        </mat-slide-toggle>
      </div>
      <div class="form-field">
        <mat-slide-toggle (click)="toggleLightMode()" [checked]="lightMode">
          Light Mode
        </mat-slide-toggle>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<mat-card appearance="outlined" class="password-box">
  <form [formGroup]="totpConfirmForm" style="margin-bottom: var(--padding)">
    <h2>2-Factor Authentication</h2>
    <div *ngIf="!isAwaiting2FAConfirmation()">
      <p>
        2-Factor authentication is
        {{ this.isUsing2FA ? "enabled" : "disabled" }} for this account.
      </p>
      <button (click)="toggle2FA()" class="g-button g-form-main-button">
        {{ this.isUsing2FA ? "Disable" : "Enable" }} 2-Factor authentication
      </button>
    </div>

    <div *ngIf="isAwaiting2FAConfirmation()">
      <p>
        Scan this QR code with your authenticator app. If the scan fails, you
        can add the account manually by entering the code {{ totpSecret }}.
        Then, confirm the 2-factor authentication by entering the one-time
        password generated by your authenticator app.
      </p>

      <img
        *ngIf="totpQrImgSrc"
        alt=""
        id="totp-qr-img"
        src="{{ totpQrImgSrc }}"
      />

      <div class="form-field">
        <mat-form-field>
          <mat-label>One-time password</mat-label>
          <input
            autocomplete="off"
            formControlName="totpConfirmation"
            matInput
            name="totpConfirmation"
            type="text"
          />
        </mat-form-field>
      </div>
      <div *ngIf="confirm2FAError" class="g-status warn">
        <b>Confirmation failed. Please try again.</b>
      </div>

      <button (click)="confirm2FA()" class="g-button g-form-main-button">
        Confirm 2-factor authentication
      </button>
    </div>
  </form>
</mat-card>
