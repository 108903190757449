import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MainService } from './services/main.service';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(public router: Router, public main: MainService, public authService: AuthService) {}

  ngOnInit(): void {
    this.main.lightMode$.subscribe((isLightModeActive) => {
      if (isLightModeActive) {
        document.body.classList.add('g-light-mode');
      } else {
        document.body.classList.remove('g-light-mode');
      }
    });
  }
}
