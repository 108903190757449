export class DateUtils {
    public static formatAsDateOnlyFromDate(value: Date, separator: string) : string {
        let day = ("0" + value.getDate()).slice(-2);
        let month = ("0" + (value.getMonth()+1)).slice(-2); //getMonth returns 0-11, so we add 1 to get a month between 1 and 12
        let year = value.getFullYear().toString();
        return day + separator + month + separator + year;
    }

    public static formatAsDateOnly(valueAsString: string, separator: string) : string {
        // When parsing from string that contains only date (not time or offset), format is always assumed as UTC (yyyy-MM-dd)
        const value = new Date(valueAsString);
        return this.formatAsDateOnlyFromDate(value, separator);
    }
}