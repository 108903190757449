import {Directive, OnDestroy, OnInit} from "@angular/core";
import {MainService} from "../../../services/main.service";
import {Router} from "@angular/router";
import {GmsPageRouteEnum} from "./gms-page-route.enum";

@Directive({
    selector: '[appGmsInfo]',
})
export class GmsInfoDirective implements OnInit, OnDestroy {

    gmsId: string;

    constructor(public main: MainService,
                public router: Router) {
    }

    ngOnInit(): void {
        if (!this.main.temporaryFavorite && !this.main.favoriteGmsList.map(gms => gms.gmsId).includes(this.main.activeItem?.gmsId)) {
            this.main.temporaryFavorite = this.main.activeItem;
        }
    }

    ngOnDestroy(): void {
        if (!Object.values(GmsPageRouteEnum).some(pageRoute => this.router.routerState.snapshot.url.includes(pageRoute))) {
            this.main.temporaryFavorite = null;
        }
    }
}