import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UploadService {

    private host = environment.apiUrl + '/v1/upload/';

    constructor(private http: HttpClient) {
    }

    uploadFile(gmsId: string, file: File): Observable<any> {
        const endpoint = this.host + gmsId;

        const fd = new FormData();
        fd.append('file', file, file.name);

        return this.http.post(endpoint, fd,
            {observe: 'events', reportProgress: true});
    }

}
