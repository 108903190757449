import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {MatTableDataSource} from '@angular/material/table';
import {SelectionModel} from '@angular/cdk/collections';
import {MainService, MessageType} from '../../services/main.service';
import {ActivatedRoute, Router} from '@angular/router';
import {GmsContext} from '../../models/gms/gms-context';
import {MessageService} from '../../services/message.service';
import {Message} from '../../models/gms/message';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatTabGroup} from '@angular/material/tabs';
import {environment} from '../../../environments/environment';
import {AlarmManualCurves} from '../../models/gms/alarm-manual-curves';
import {PlotBand} from '../../models/gms/plot-band';
import {Series} from '../../models/gms/series';
import {GmsInfoDirective} from "../_shared/gms-info/gms-info.directive";

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class MessagesComponent extends GmsInfoDirective implements OnInit {
    activeMessages: Message[] = [];
    dataSource = new MatTableDataSource(this.activeMessages);
    columnsToDisplay = ['select', 'engine', 'sender', 'shortTitle', 'severity', 'eventDate', 'delete'];
    expandedElement: Message | null;
    selection = new SelectionModel<Message>(true, []);
    shownMessageType = undefined; // used for tabs
    index: number;

    @ViewChild('tabs') tabGroup: MatTabGroup;

    constructor(public main: MainService,
                private route: ActivatedRoute,
                public messageService: MessageService,
                private dialog: MatDialog,
                public router: Router) {
        super(main, router);
    }

    private static parsePlotBands(plotBands: PlotBand[]): any[] {
        if (!plotBands) {
            return [];
        }

        const axisPlotBands = [];
        for (const pb of plotBands) {
            axisPlotBands.push({
                color: pb.color,
                from: pb.from,
                to: pb.to,
                label: {
                    text: pb.labelText,
                    align: 'center',
                    color: pb.labelColor
                }
            });
        }
        return axisPlotBands;
    }

    private static parsePlotLines(plotLineValues: number[]): any[] {
        if (!plotLineValues) {
            return [];
        }

        const axisPlotLines = [];
        for (const value of plotLineValues) {
            axisPlotLines.push({value});
        }
        return axisPlotLines;
    }

    private static parseSeries(ser: Series[]): any[] {
        if (!ser) {
            return [];
        }

        const series = [];
        for (const s of ser) {
            series.push({
                type: s.type,
                name: s.name,
                data: MainService.combineData(s.dataX, s.dataY),
                color: s.color,
                dashStyle: s.dashStyle
            });
        }
        return series;
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.gmsId = this.route.snapshot.paramMap.get('gmsId');
        const messageType = this.route.snapshot.paramMap.get('type');
        const messageId = this.route.snapshot.paramMap.get('id');

        this.main.setActiveById(this.gmsId);
        this.loadAndShowMessages(this.main.activeItem, messageType, messageId);
    }

    loadAndShowMessages(gmsContext: GmsContext, messageType: string, messageId: string): void {
        this.messageService.getAll(gmsContext.gmsId).subscribe(messages => {

            // set charts for every message
            messages.forEach(m => m.chart = this.generateHighchart(m.alarmManualCurves));

            this.activeMessages = messages;
            this.updateUnReadMessageCounter();

            // directly open the tab if route param is set
            messageType = messageType?.toUpperCase();
            if (messageType && (messageType === 'ALARM' || messageType === 'WARNING' || messageType === 'INFO')) {
                this.shownMessageType = messageType;
                this.dataSource = new MatTableDataSource(this.activeMessages.filter(m => m.messageType === this.shownMessageType));

                if (messageType === MessageType.ALARM) {
                    this.tabGroup.selectedIndex = 1;
                } else if (messageType === MessageType.WARNING) {
                    this.tabGroup.selectedIndex = 2;
                } else if (messageType === MessageType.INFO) {
                    this.tabGroup.selectedIndex = 3;
                }

                // directly open the message if route param is set
                if (messageId) {
                    this.expandedElement = this.activeMessages.find(m => m.id === +messageId);
                    if (this.expandedElement) {
                        this.markMessageAsRead(this.expandedElement);
                    }
                }

            } else {
                this.dataSource = new MatTableDataSource(this.activeMessages);
            }

        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle($event: any) {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Message): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.row + 1}`;
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    onTabChange(e) {
        if (e.tab.textLabel === 'ALARMS') {
            this.shownMessageType = MessageType.ALARM;
        } else if (e.tab.textLabel === 'WARNINGS') {
            this.shownMessageType = MessageType.WARNING;
        } else if (e.tab.textLabel === 'INFOS') {
            this.shownMessageType = MessageType.INFO;
        } else {
            this.shownMessageType = undefined;
        }

        if (this.shownMessageType) {
            this.dataSource = new MatTableDataSource(this.activeMessages.filter(m => m.messageType === this.shownMessageType));
        } else {
            this.dataSource = new MatTableDataSource(this.activeMessages);
        }

    }

    countUnreadMessagesForType(messageType: string): number {
        if (!messageType) {
            return this.activeMessages.filter(m => !m.read).length;
        }
        return this.activeMessages.filter(m => m.messageType === messageType).filter(m => !m.read).length;
    }

    markMessageAsRead(message: Message): void {
        this.updateUnReadMessageCounter();
        this.messageService.markAsRead(message.id).subscribe();
    }

    delete(id: number): void {
        this.activeMessages = this.activeMessages.filter(m => m.id !== id);
        if (this.shownMessageType) {
            this.dataSource = new MatTableDataSource(this.activeMessages.filter(m => m.messageType === this.shownMessageType));
        } else {
            this.dataSource = new MatTableDataSource(this.activeMessages);
        }
        this.messageService.delete(id).subscribe();
    }

    openDeleteSelectedMessagesDialog(nrOfMessages: number): void {
        const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
                message: 'Are you sure you want to delete all ' + nrOfMessages + ' selected messages?',
                buttonText: {
                    ok: 'Yes, delete',
                    cancel: 'Cancel'
                }
            }
        });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
            if (confirmed) {
                this.activeMessages = this.activeMessages.filter(m => !this.selection.selected.includes(m));
                if (this.shownMessageType) {
                    this.dataSource = new MatTableDataSource(this.activeMessages.filter(m => m.messageType === this.shownMessageType));
                } else {
                    this.dataSource = new MatTableDataSource(this.activeMessages);
                }

                this.selection.selected.forEach(message => {
                    this.messageService.delete(message.id).subscribe();
                });
            }
        });
    }

    private updateUnReadMessageCounter(): void {
        this.main.activeItem.unreadMessagesCount = this.activeMessages.filter(m => !m.read).length;
    }

    createMailToLink(message: Message): string {
        return 'mailto:' + environment.supportMail +
            '?Subject=Support Request for GMS ' + message.gmsId + ': ' + message.longTitle +
            '&body=Hi,%0D%0A%0D%0AI have a question concerning the following ' + message.messageType + ' message:' + '%0D%0A' +
            window.location.href + '/' + message.messageType + '/' + message.id;
    }

    debug(message: Message): void {
        console.log(message);
    }

    generateHighchart(alarmManualCurves: AlarmManualCurves): any {
        if (!alarmManualCurves) {
            return;
        }

        const xPlotBands = MessagesComponent.parsePlotBands(alarmManualCurves.xAxis?.plotBands);
        const yPlotBands = MessagesComponent.parsePlotBands(alarmManualCurves.yAxis?.plotBands);
        const xPlotLines = MessagesComponent.parsePlotLines(alarmManualCurves.xAxis?.plotLineValues);
        const yPlotLines = MessagesComponent.parsePlotLines(alarmManualCurves.yAxis?.plotLineValues);
        const series = MessagesComponent.parseSeries(alarmManualCurves.series);

        // console.log(chart);

        return {
            id: alarmManualCurves.title,
            chart: {
                // styledMode: true,
                zoomType: 'x',
                panning: true,
                panKey: 'shift',
                resetZoomButton: {position: {x: 0, y: -30}}
            },
            tooltip: {
                shared: true,
                useHTML: true,
                positioner() {
                    return {x: 0, y: 0};
                }
            },
            credits: {enabled: false},
            legend: {enabled: alarmManualCurves.legendEnabled},
            title: {text: alarmManualCurves.title},
            defs: {
                gradientWhiteTransparent: {
                    tagName: 'linearGradient',
                    id: 'gradientWhiteTransparent', x1: 0, y1: 0,
                    children: [{
                        tagName: 'stop',
                        offset: 0
                    }, {
                        tagName: 'stop',
                        offset: 1
                    }]
                }
            },
            xAxis: [{
                title: {
                    text: alarmManualCurves.xAxis?.title
                },
                plotBands: xPlotBands,
                plotLines: xPlotLines
            }],
            yAxis: [{
                title: {
                    text: alarmManualCurves.yAxis?.title
                },
                plotBands: yPlotBands,
                plotLines: yPlotLines,
            }],
            series
        };
    }
}
