import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../auth/auth.service";
import {Router} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {TOKEN} from "../_shared/constants/local-storage-constants";

@Component({
    selector: 'app-navis-login',
    templateUrl: './navis-login.component.html',
    styleUrls: ['./navis-login.component.css']
})
export class NavisLoginComponent implements OnInit {
    error = null;

    constructor(private authService: AuthService,
                private router: Router,
                private oidcSecurityService: OidcSecurityService) {
    }

    ngOnInit(): void {
        if (this.authService.loggedIn()) {
            // User is authenticated with GAP, continuing to application:
            this.router.navigate(['']);
            return;
        }

        this.oidcSecurityService.checkAuth().subscribe(loginResponse => {
            if (loginResponse.isAuthenticated) {
                // User is authenticated with Navis. Using token to authenticate with GAP:
                this.oidcSecurityService.getIdToken().subscribe(token => {
                    this.loginViaNavis(token)
                });
            } else {
                // User is not authenticated with Navis. Starting authentication:
                this.oidcSecurityService.authorize();
            }
        });
    }

    loginViaNavis(navisToken: string): void {
        this.authService.loginWithNavisToken(navisToken)
            .subscribe(
                gapToken => {
                    localStorage.setItem(TOKEN, gapToken);
                    this.router.navigate(['']);
                },
                err => {
                    console.log(err);
                    this.error = err;
                });
    }
}
