<div class="g-hero" *ngIf="main.activeItem">
    <div class="g-hero-text">
        <div class="g-hero-text-detail">
            <div class="g-icon-text-horizontal">
                <div class="icon"><app-icon [name]="main.activeItem.iconInfo.toLowerCase()"></app-icon></div>
                <div class="text">
                    <h4 class="name">{{main.activeItem.name | slice:0:33}}</h4>
                    <div class="call-sign">{{main.activeItem.gmsId}}</div>
                </div>
            </div>
            <div class="g-hero-text-headline---">
                <div class="text">
                    <div class="logo"><img class="logo" src="assets/geislinger-logo.svg"></div>
<!--                    <h3>{{main.activeItem.designation}}</h3>-->
                    <h4>{{main.activeEngine.installedProducts}}</h4>
<!--                    <h1>Status</h1>-->
                    <br><br><br>
                    <h5 class="damper-id"><b>{{main.activeItem.projectInfo}}</b></h5>

                    <div class="datepicker-box" *ngIf="gmsId">
                        <mat-form-field appearance="fill" class="slide-toggle-text">
                            <mat-label>Enter a date range</mat-label>
                            <mat-date-range-input [rangePicker]="picker" [formGroup]="chartDateRange"  [min]="chartDateMin" [max]="chartDataMax">
                                <input matStartDate #dateRangeStart placeholder="Start date" formControlName="start">
                                <input matEndDate #dateRangeEnd placeholder="End date" formControlName="end" (dateChange)="dateRangeChange(dateRangeStart, dateRangeEnd)">
                            </mat-date-range-input>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-date-range-picker #picker></mat-date-range-picker>

                            <mat-error *ngIf="chartDateRange.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
                            <mat-error *ngIf="chartDateRange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
                        </mat-form-field>
                        <div class="date-ranges">
                            <span class="datepicker-button" (click)="last24Hours()">last<br>24 hours</span>
                            <span class="datepicker-button" (click)="lastWeek()">last<br>week</span>
                            <span class="datepicker-button" (click)="lastMonth()">last<br>month</span>
                        </div>
                        <div class="data-toggle form-field slide-toggle-text">
                            <mat-slide-toggle (click)="toggleChartType()"
                                              [checked]="rpmMode">
                                show data over rpm
                            </mat-slide-toggle>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="g-hero-img">
        <div class="download">
            <app-icon class="download-button small-icon" [name]="'upload'" (click)="downloadCsv()"></app-icon>
            <div class="popup-text">Download CSV</div>
        </div>
        <mat-spinner *ngIf="showSpinner"></mat-spinner>
        <app-highchart *ngIf="chart && gmsId" [options]="chart" [oneToOne]="true" [(update)]="updateFlag"></app-highchart>
    </div>
</div>
<div class="g-head status" *ngIf="main.activeEngine" [ngClass]="main.activeEngine.status">
    <div class="g-icon-text-horizontal big">
        <app-status-icon class="icon" [input]="main.activeEngine.status"></app-status-icon>
        <div class="text">
            <h2 class="name">{{main.activeEngine.status}}</h2>
            <h4 class="text">{{main.activeEngine.statusDetail}}</h4>
        </div>
    </div>
</div>

<div class="g-columns app-charts" *ngIf="main.activeEngine">
    <h2>App Status</h2>
    <div class="g-card chart" *ngFor="let appStatusChart of appStatusCharts">
        <app-highchart class="app-chart-status" [options]="appStatusChart" style="width: 340px; max-height: 170px"></app-highchart>
    </div>
    <div class="g-number g-number-text g-number-description" *ngIf="noAppStatusAvailable">
        No data to display
    </div>
</div>
