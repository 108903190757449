<div class="g-hero">
  <div class="g-hero-text">
    <h1>Geislinger Analytics Platform</h1>
  </div>
  <div class="g-hero-img sujet"></div>
</div>
<div class="g-head g-form" *ngIf="!registrationSent">
  <div>
    <div class="g-form-login-link"><a routerLink="/login">back to login</a></div>
    <h3 class="g-headline">Register</h3>
  </div>
  <form class="registration-form" [formGroup]="registrationForm" id="formWithCaptcha">
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>Email</mat-label>
        <input formControlName="email" matInput type="email" />
        <mat-error> Please provide a valid username </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>First name</mat-label>
        <input formControlName="firstname" matInput type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>Last name</mat-label>
        <input formControlName="lastname" matInput type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>Company</mat-label>
        <input formControlName="company" matInput type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>Position</mat-label>
        <input formControlName="position" matInput type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>Address</mat-label>
        <input formControlName="addressLine" matInput type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>ZIP</mat-label>
        <input formControlName="postalCode" matInput type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>City</mat-label>
        <input formControlName="city" matInput type="text" />
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>Country</mat-label>
        <mat-select formControlName="country" [panelWidth]="null">
          <mat-option *ngFor="let country of countries" [value]="country">{{ country }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>Password</mat-label>
        <input formControlName="password" matInput type="password" />
        <mat-error *ngIf="registrationForm.get('password')?.hasError('requirementsNotFulfilled') && !registrationForm.get('password')?.hasError('required')">
          <span
            title="
- at least 8 characters long,
- contains at least 1 number,
- contains at least 1 upper-case character,
- contains at least 1 lower-case character,
- contains at least 1 special character,
- contains no whitespaces"
          >
            The password does not meet the <b>requirements*</b>
          </span>
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="full-width-input">
        <mat-label>Confirm password</mat-label>
        <input formControlName="confirmPassword" matInput type="password" />
        <mat-error *ngIf="registrationForm.get('confirmPassword')?.hasError('required')"> Please confirm password </mat-error>
        <mat-error *ngIf="registrationForm.get('confirmPassword')?.hasError('mustMatch') && !registrationForm.get('confirmPassword')?.hasError('required')">
          Passwords do not match
        </mat-error>
      </mat-form-field>
    </div>
    <div>
      <mat-slide-toggle formControlName="acceptedTerms"></mat-slide-toggle>
      I accept the &nbsp;<a (click)="window.open('../../../assets/terms/' + termsAndConditions)" [href]="'../../../assets/terms/' + termsAndConditions" target="_blank">
        terms and conditions
      </a>
    </div>
    <div class="mtcaptcha" id="registration-captcha"></div>
    <div>
      <input hidden formControlName="mtcaptchaToken" id="mtcaptchaToken" (change)="saveMtcaptchaToken()" />
    </div>
    <button
      *ngIf="!registrationLoading"
      (click)="register()"
      [disabled]="!registrationForm.valid"
      [ngClass]="!registrationForm.valid ? 'g-disabled' : ''"
      class="g-button g-form-main-button"
      type="submit"
    >
      Register
    </button>
    <mat-spinner *ngIf="registrationLoading"></mat-spinner>
  </form>
</div>
<div class="g-head" *ngIf="registrationSent">
  <div *ngIf="registrationError === null">
    <h3>Registration Successful</h3>
    <p>Thank you for your registration. You received an email with a confirmation link. After opening the confirmation link you can log-in with your account.</p>
  </div>
  <div *ngIf="registrationError !== null">
    <h3>An error has occurred:</h3>
    <p>{{ registrationError }}</p>
  </div>
  <div class="g-form-login-link"><a routerLink="/login">back to login</a></div>
</div>
